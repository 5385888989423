import { axios } from "../../../lib/axios";
import { API_ROUTES } from "../../../utils/api_routes";
import { APP_ROUTES } from "../../../utils/routes";
import { Pagination } from "../../common/interface";
import { RemoveInstructorPayload, RemoveInstructorRespose } from "../../instructor/api/interface";
import { AddCommonDepartmentPayload, AddDepartmentPayload, CommonDepartmentPayload, DepartmentPayload, DepartmentResponse, GetDepartment, StoryResponse, UniversityResponse } from "./interface";

export const universityList = (query: any): Promise<UniversityResponse> => {
	return axios.get(API_ROUTES.COMMON.GETALLUNIVERSITIES, { params: query });
};

export const departmentList= (query:Pagination, param:DepartmentPayload): Promise<DepartmentResponse>=>{
	return axios.get(API_ROUTES.UNIVERSITIES.DEPARTMENTS, {params:{...query, ...param}})
}
export const commonDepartmentList= (query:any, param?:CommonDepartmentPayload): Promise<DepartmentResponse>=>{
	return axios.get(API_ROUTES.UNIVERSITIES.CATEGORY, {params:{...query}})
}
export const addDepartment=(university_id:number, payload: AddDepartmentPayload): Promise<RemoveInstructorRespose>=>{
	return axios.post(API_ROUTES.UNIVERSITIES.ADMINDEPARTMENTS, {university_id:university_id, ...payload, type:1})
}
export const addCategory=( formData: FormData): Promise<RemoveInstructorRespose>=>{
	return axios.post(API_ROUTES.UNIVERSITIES.CATEGORY,formData, {
		headers:{"Content-Type":"multipart/form-data",
		},
	});
};

export const editCategory=(universityId:Number, formData:FormData):Promise<RemoveInstructorRespose>=>{
	return axios.post(`${API_ROUTES.UNIVERSITIES.CATEGORY}/${universityId}`, formData, {
		headers:{"Content-Type":"multipart/form-data",
		},
	});
};

export const removeDepartment=(query:RemoveInstructorPayload):Promise<RemoveInstructorRespose>=>{
	return axios.delete(`${API_ROUTES.UNIVERSITIES.ADMINDEPARTMENTS}/${query.id}`)
}
export const removeCategory=(query:RemoveInstructorPayload):Promise<RemoveInstructorRespose>=>{
	return axios.delete(`${API_ROUTES.UNIVERSITIES.CATEGORY}/${query.id}`)
}


export const getDepartment=(departmentId:number):Promise<GetDepartment>=>{
	return axios.get(`${API_ROUTES.UNIVERSITIES.ADMINDEPARTMENTS}/${departmentId}`)
}
export const getCategory=(departmentId:number):Promise<GetDepartment>=>{
	return axios.get(`${API_ROUTES.UNIVERSITIES.CATEGORY}/${departmentId}`)
}

export const editDepartment=(universityId:number, departmentId:Number, payload:AddDepartmentPayload):Promise<RemoveInstructorRespose>=>{
	return axios.put(`${API_ROUTES.UNIVERSITIES.ADMINDEPARTMENTS}/${departmentId}`, {university_id:universityId, type:1, ...payload})
}



export const addUniversity = (formData: FormData): Promise<RemoveInstructorRespose> => {
  return axios.post(API_ROUTES.UNIVERSITIES.ADMINUNIVERSITIES, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const editUniversity = (id: Number, formData: FormData): Promise<RemoveInstructorRespose> => {
  return axios.post(`${API_ROUTES.UNIVERSITIES.ADMINUNIVERSITIES}/${id}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const removeUniversity=(query:RemoveInstructorPayload):Promise<RemoveInstructorRespose>=>{
	return axios.delete(`${API_ROUTES.UNIVERSITIES.ADMINUNIVERSITIES}/${query.id}`)
}
export const removeStory=(query:RemoveInstructorPayload):Promise<RemoveInstructorRespose>=>{
	return axios.delete(`${"admin/delete-story"}/${query.id}`)
}
export const removeBanner=(query:RemoveInstructorPayload):Promise<RemoveInstructorRespose>=>{
	return axios.delete(`${"/banners"}/${query.id}`)
}
export const removeSemester=(query:RemoveInstructorPayload):Promise<RemoveInstructorRespose>=>{
	return axios.delete(`${"/admin/semester"}/${query.id}`)
}
export const removeInstalment=(query:RemoveInstructorPayload):Promise<RemoveInstructorRespose>=>{
	return axios.get(`/admin/delete-installment-plan/${query.id}`)
}

export const getUniversity=(id:number):Promise<GetDepartment>=>{
	return axios.get(`${API_ROUTES.UNIVERSITIES.ADMINUNIVERSITIES}/${id}`)
}

export const getStory=(query: Pagination,university_id:number):Promise<StoryResponse>=>{
	return axios.get(`${API_ROUTES.UNIVERSITIES.STORY}`,{params:{university_id,...query}})
}
export const getBanner=(query: Pagination,university_id:number):Promise<any>=>{
	return axios.get(`${"/banners"}`,{params:{university_id,...query}})
}
export const getSemesters=(query: any,university_id?:number):Promise<any>=>{
	return axios.get(`${"/admin/semester"}`,{params:{...(university_id&&{university_id}),...query}})
}
export const getSpecificSemesters=(university_id:number,id:Number):Promise<any>=>{
	return axios.get(`${"/admin/semester"}/${id}`,{params:{university_id}})
}
export const updateSpecificSemesters=(id:Number,payload:any):Promise<any>=>{
	return axios.put(`${"/admin/semester"}/${id}`,payload)
}
export const getSpecificStory=(story_id:number):Promise<any>=>{
	return axios.get(`${API_ROUTES.UNIVERSITIES.STORY}/${story_id}`)
}
export const getSpecificBanner=(story_id:number):Promise<any>=>{
	return axios.get(`${"/banners"}/${story_id}`)
}
export const addStory=(formData: FormData)=>{
	return axios.post(`${API_ROUTES.UNIVERSITIES.ADMIN_STORY}`,formData, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
}
export const editStory=(story_id:number, formData: FormData)=>{
	return axios.post(`${API_ROUTES.UNIVERSITIES.ADMIN_STORY}/${story_id}`,formData, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
}

export const addBanner=( formData: FormData): Promise<RemoveInstructorRespose>=>{
	return axios.post(`${"/banners"}`,formData, {
		headers:{"Content-Type":"multipart/form-data",
		},
	});
};

export const editBanner=( banner_id: Number,formData: FormData): Promise<RemoveInstructorRespose>=>{
	return axios.post(`${"/banners"}/${banner_id}`,formData, {
		headers:{"Content-Type":"multipart/form-data",
		},
	});
};


export const addSemester=(payload:any)=>{
	return axios.post(`${"/admin/semester"}`,payload)
}


export const changeCategoryOrder=(idList:number[])=>{
	return axios.post(`${"/admin/update-sorting"}`,{sort:idList})
}
export const toggleSemester=(state_id:number, id:number):Promise<RemoveInstructorRespose>=>{
	return axios.post(`${"/admin/semester/change-status"}/${id}`, {is_enable:state_id});
}
export const createInstallment=(installment:number, university_id:number,data:any,title:any,down_payment:any):Promise<any>=>{
	return axios.post(`${"/admin/create-installment-plan"}`, {installment,university_id,data,title,down_payment});
}
export const updateInstallment=(installment:number, university_id:number,data:any,id:any,title:any,down_payment:any):Promise<any>=>{
	return axios.post(`${"/admin/update-installment-plan"}/${id}`, {installment,university_id,data,title,down_payment});
}

export const getInstallment = (query: any): Promise<any> => {
	return axios.get("/admin/installment-plan", { params: query });
};
export const getSpecificInstallment = (id: any): Promise<any> => {
	return axios.get(`/admin/get-installment-plan/${id}`);
};

