import * as Yup from "yup";
// import commonSchema from "../../common/validations";
// import { YUP } from "../../../utils/validations";

// const InstructorDetailsSchema = commonSchema.concat(Yup.object().shape({
// 	university: Yup.number().required(YUP.REQUIRED).min(1, YUP.REQUIRED),
// 	// department: Yup.number().required(YUP.REQUIRED).min(1, YUP.REQUIRED),
// }))

// export default InstructorDetailsSchema;
const AddDateSchema=Yup.object().shape({
	date:Yup.date().required("Required"),
	amount:Yup.number().required("Required").positive("Amount Should be greater than 0"),
})
export {AddDateSchema}