import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Modal, Form, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../../redux/hooks";
import { editBanner, getSpecificBanner } from "../api";
import { AddUniversityProp } from "./interface";

const EditBanner = ({ showModal, handleClose }: AddUniversityProp) => {
  const selectedSegment = useAppSelector(
    (state) => state.segmentationReducer.id
  );
  const [uploadedImage, setuploadedImage] = useState<File | null>(null);

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files && event.currentTarget.files[0]) {
      const file = event.currentTarget.files[0];
      setuploadedImage(file);
      if (file.type.startsWith("image/")) {
        departmentDetailsFormik.setFieldValue("file", file);
      } else {
        toast.error("Please upload a valid image file.");
      }
    }
  };

  useEffect(() => {
    if (Number(selectedSegment) !== 0) {
      getSpecificBanner(selectedSegment as number).then((res) => {
        departmentDetailsFormik.setFieldValue("title", res.data.title);
      });
    }

    departmentDetailsFormik.resetForm();
  }, [showModal, selectedSegment]);

  const { id } = useParams();

  const departmentDetailsFormik = useFormik({
    initialValues: {
      title: "",
      file: null,
      _method: "PUT",
    },
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("title", values.title);
      formData.append("_method", "PUT");
      formData.append("university_id", Number(id).toString());
      if (uploadedImage) {
        formData.append("file", uploadedImage);
      }
      toast.promise(editBanner(Number(selectedSegment), formData), {
        pending: {
          render() {
            return "Editing Banner";
          },
        },
        success: {
          render() {
            departmentDetailsFormik.resetForm();
            handleClose();

            return "Successfully Edited Banner";
          },
        },
        error: {
          render() {
            return "Failed to Edit Banner";
          },
        },
      });
    },
  });
  const handleAddDepartment = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    departmentDetailsFormik.submitForm();
  };

  return (
    <Modal
      show={showModal}
      onHide={() => handleClose()}
      centered
      className="custom-modal"
    >
      <div>
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold">Edit Banner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleAddDepartment}>
            <div className="mb-4">
              <Form.Group controlId="formBannerTitle">
                <Form.Label className="fw-semibold">Title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Banner Title"
                  value={departmentDetailsFormik.values.title}
                  name="title"
                  onChange={departmentDetailsFormik.handleChange}
                  onBlur={departmentDetailsFormik.handleBlur}
                  className="form-control shadow-sm"
                />
                {departmentDetailsFormik.errors.title &&
                departmentDetailsFormik.touched.title ? (
                  <small className="text-danger">
                    {departmentDetailsFormik.errors.title}
                  </small>
                ) : null}
              </Form.Group>
            </div>
            <div className="mb-4">
              <Form.Group controlId="formBannerImage">
                <Form.Label className="fw-semibold">
                  Upload Banner Image
                </Form.Label>
                <Form.Control
                  type="file"
                  accept="image/*"
                  name="image"
                  onChange={handleImageUpload}
                  className="form-control shadow-sm"
                />
                {departmentDetailsFormik.errors.file &&
                departmentDetailsFormik.touched.file ? (
                  <small className="text-danger">
                    {departmentDetailsFormik.errors.file}
                  </small>
                ) : null}
              </Form.Group>
            </div>
            <div className="text-center">
              <Button
                type="submit"
                className="btn btn-primary w-50 shadow-sm fw-bold"
              >
                Edit
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default EditBanner;
