import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { Button, Form, Modal } from "react-bootstrap";
import styles from "../../instructor/styles/instructor.module.css";
import commonstyles from "../../instructor/styles/common.module.css";
import { AddInstructorProp } from "../../course/modals/interface";
import { getTimer, setTimer } from "../api";
import { COUPONS } from "../../../utils/coupons";
import { MESSAGES } from "../../../utils/messages";

const ChangeTimer = ({ showModal, handleClose }: AddInstructorProp) => {
  useEffect(() => {
    getTimer().then((res) => {
      if (res.data) {
        const { duration, type } = res.data;
        timerDetailsFormik.setFieldValue(
          COUPONS.FORMIK.TIMER.DURATION,
          duration
        );
        timerDetailsFormik.setFieldValue(COUPONS.FORMIK.TIMER.TYPE, type);
      }
    });
  }, [showModal]);

  const timerDetailsFormik = useFormik({
    initialValues: {
      duration: 0,
      type: 1,
    },

    enableReinitialize: true,
    onSubmit: (values) => {
      toast.promise(setTimer(values.duration, values.type), {
        pending: {
          render() {
            return MESSAGES.COUPON.TIMER.EDIT;
          },
        },
        success: {
          render() {
            timerDetailsFormik.resetForm();
            handleClose();
            return MESSAGES.COUPON.TIMER.EDIT_SUCCESS;
          },
        },
        error: {
          render() {
            return MESSAGES.COUPON.TIMER.EDIT_INVALID;
          },
        },
      });
    },
  });

  return (
    <Modal
      show={showModal}
      onHide={() => {
        handleClose();
      }}
    >
      <div className={styles["add-lead-from"]}>
        <Modal.Header closeButton>
          <Modal.Title>{"Change Timer"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={() => {}}>
            {
              <>
                {
                  <Form.Group className="from-group" controlId="formFirstName">
                    <Form.Label>Duration</Form.Label>
                    <Form.Control
                      type="number"
                      value={timerDetailsFormik.values.duration}
                      placeholder="Duration"
                      name={COUPONS.FORMIK.TIMER.DURATION}
                      onChange={timerDetailsFormik.handleChange}
                    />
                  </Form.Group>
                }
                {timerDetailsFormik.errors.duration &&
                timerDetailsFormik.touched.duration ? (
                  <span className={commonstyles.error}>
                    {timerDetailsFormik.errors.duration}
                  </span>
                ) : null}
              </>
            }

            {
              <>
                {
                  <Form.Group className="from-group" controlId="formFirstName">
                    <Form.Label>Unit</Form.Label>
                    <Form.Control
                      name={COUPONS.FORMIK.TIMER.TYPE}
                      value={timerDetailsFormik.values.type}
                      as="select"
                      onChange={timerDetailsFormik.handleChange}
                    >
                      <option value={1} key={0}>
                        Minute
                      </option>
                      <option value={2} key={2}>
                        Hour
                      </option>
                      <option value={3} key={5}>
                        Day
                      </option>
                    </Form.Control>
                  </Form.Group>
                }
                {timerDetailsFormik.errors.type &&
                timerDetailsFormik.touched.type ? (
                  <span className={commonstyles.error}>
                    {timerDetailsFormik.errors.type}
                  </span>
                ) : null}
              </>
            }
          </Form>
          <Button
            onClick={timerDetailsFormik.submitForm}
            className={`${styles.buttonss} mt-3`}
            type="submit"
          >
            {"Change"}
          </Button>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default ChangeTimer;
