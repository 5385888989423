import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ErrorMessage, FieldArray, useFormik } from "formik";
import { Button, Form, Modal } from "react-bootstrap";
import users from "../../../assets/images/users.png";
import offer_book from "../../../assets/images/offer_book.png";
import BookVector from "../../../assets/images/BookVector.png";
import JoinVector from "../../../assets/images/JoinVector.png";
import CreateVector from "../../../assets/images/CreateVector.png";

import styles from "../../instructor/styles/instructor.module.css";

import commonstyles from "../../instructor/styles/common.module.css";
import { AddInstructorProp } from "../../course/modals/interface";
import { addCoupon, coupon, editCoupon } from "../api";
import { useAppSelector } from "../../../redux/hooks";
import { INSTRUCTOR } from "../../../utils/instructor";
import { COUPONS } from "../../../utils/coupons";
import { MESSAGES } from "../../../utils/messages";
import { CouponDetailsSchema } from "../validations";
import { UniversityInterface } from "../../instructor/api/interface";
import { getAllUniversities } from "../../instructor/api";
import { CourseDeatails } from "../../course/api/interface";
import { CouponFormik, SelectedUni } from "./interface";
import { uploadImage } from "../../common/utils";
import { courseList } from "../../course/api";
import { IMAGE_BASE_URL } from "../../../config";

const AddorEdit = ({ showModal, handleClose }: AddInstructorProp) => {
  const selectedCoupon = useAppSelector((state) => state.couponReducer);
  const [universities, setUniversities] = useState<UniversityInterface[]>([]);
  const [course, setCourse] = useState<CourseDeatails[]>([]);
  const [selectedCourses, setSelectedCourses] = useState<number[]>([]);
  const [bannerImage, setBannerImage] = useState<any>(null);
  const [loadingCourse, setLoadingCourse] = useState(false);
  const [customised, setCustomised] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState<number>(0);
  const [uploadedImage, setuploadedImage] = useState<File | null>(null);
  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files && event.currentTarget.files[0]) {
      const file = event.currentTarget.files[0];
      setuploadedImage(file);
      if (file.type.startsWith("image/")) {
        couponsDetailsFormik.setFieldValue("bannerImage", file);
      } else {
        toast.error("Please upload a valid image file.");
      }
    }
  };

  const [loading, setLoading] = useState<boolean>(false);
  const [selectedUniversities, setSelectedUniversities] = useState({});

  const handleCourseCheckboxClick = (courseId: number) => {
    couponsDetailsFormik.setFieldValue(
      COUPONS.FORMIK.SELECTED_COURSE,
      courseId
    );

    setSelectedCourse(courseId);
  };

  const [selectedDepartments, setSelectedDepartments] = useState<number[]>([]);

  useEffect(() => {
    getAllUniversities().then((res) => {
      setUniversities(res?.data);
    });
  }, [showModal]);
  const calculateUniIndex = (uni?: any, id?: any) => {
    // console.log(uni,id,"hjjjj")
    let a = 0;
    couponsDetailsFormik.values.selectedUniversities.map(
      (obj: any, ind: any) => {
        if (Number(obj.university_id) === Number(id)) {
          a = ind;
        }
      }
    );
    return a;
  };

  const handleUniversityChange = (e: any, universityId: any) => {
    console.log(e.value, "valueee");

    if (e.target.checked) {
      couponsDetailsFormik.setFieldValue("segments", {
        ...couponsDetailsFormik.values.segments,
        [universityId]: [
          { min: "", max: "", discount: "", discount_type: "1" },
        ],
      });
    } else {
      const segment = couponsDetailsFormik.values.segments;
      //@ts-ignore
      delete segment[universityId];
      couponsDetailsFormik.setFieldValue("segments", segment);
    }
  };

  const addConfis = (universityId: any) => {
    couponsDetailsFormik.setFieldValue("segments", {
      ...couponsDetailsFormik.values.segments,
      [universityId]: [
        //@ts-ignore
        ...couponsDetailsFormik.values.segments[universityId],
        { min: "", max: "", discount: "", discount_type: "2" },
      ],
    });
  };

  const handleUniversityCheckboxClick = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    const array: SelectedUni[] =
      couponsDetailsFormik.values.selectedUniversities;
    console.log(array, id);

    if (e.target.checked) {
      setLoadingCourse(true);
      courseList(undefined, id, undefined, true).then((res) => {
        setLoadingCourse(false);
        couponsDetailsFormik.setFieldValue(
          COUPONS.FORMIK.SELECTED_UNIVERSITIES,
          [
            ...array,
            {
              university_id: id,
              discount: "",
              discount_type: "2",
              allCourses: res.data,
              courses: [],
            },
          ]
        );
      });
    } else {
      let arr = array.filter((u) => {
        return u.university_id !== id;
      });
      couponsDetailsFormik.setFieldValue(
        COUPONS.FORMIK.SELECTED_UNIVERSITIES,
        arr
      );
    }
  };
  const addMore = (id: number) => {
    const array: SelectedUni[] =
      couponsDetailsFormik.values.selectedUniversities;
    console.log(array, id);

    couponsDetailsFormik.values.selectedUniversities.map((obj) => {
      if (obj.university_id === id) {
        couponsDetailsFormik.setFieldValue(
          COUPONS.FORMIK.SELECTED_UNIVERSITIES,
          [
            ...array,
            {
              university_id: id,
              discount: "",
              discount_type: "2",
              allCourses: obj.allCourses,
              courses: [],
            },
          ]
        );
        return;
      }
    });
  };
  const handleCourseCheckbox = (
    e: any,
    university_id: any,
    course_id: any,
    ind: number
  ) => {
    const array = searchUniversity(university_id, "courses", ind);
    const obj = couponsDetailsFormik.values.selectedUniversities[ind];

    if (e.target.checked) {
      const na: any = [];
      couponsDetailsFormik.values.selectedUniversities.map((uni, index) => {
        if (index === ind) {
          obj.courses = [...array, course_id];
          na.push(obj);
        } else {
          na.push(uni);
        }
      });

      couponsDetailsFormik.setFieldValue(
        COUPONS.FORMIK.SELECTED_UNIVERSITIES,
        na
      );
    } else {
      const na: any = [];
      couponsDetailsFormik.values.selectedUniversities.map((uni, index) => {
        if (index === ind) {
          const index = array.indexOf(course_id);
          if (index > -1) {
            array.splice(index, 1);
          }
          obj.courses = [...array];
          na.push(obj);
        } else {
          na.push(uni);
        }
      });

      couponsDetailsFormik.setFieldValue(
        COUPONS.FORMIK.SELECTED_UNIVERSITIES,
        na
      );
    }
  };

  type SelectedUniKeys = keyof SelectedUni;
  const searchUniversity = (
    id: number,
    param: SelectedUniKeys,
    ind: number
  ): any => {
    return couponsDetailsFormik.values.selectedUniversities[ind][param];
  };

  const fetchCourses = async (university_id: any) => {
    const response = await courseList(undefined, university_id, undefined);

    return await response.data;
  };

  const fetchAllCourses = async (universities: any) => {
    const promises = universities.map((uni: any) =>
      fetchCourses(uni.university_id)
    );
    return await Promise.all(promises);
  };

  useEffect(() => {
    if (selectedCoupon.editOrAdd === INSTRUCTOR.REDUX.EDIT) {
      setCustomised(true);
      coupon(String(selectedCoupon.id), { for_detail: true }).then((res) => {
        const {
          data: {
            backgroud_color,
            text,
            text_color,
            type,
            button_color,
            code,
            courses_count,
            image,
            expiry_date,
            max_members,
            departments,
            courses,
            universities,
            segments,
            duration,
            duration_type,
            discount_type,
            discount_amount,
            gradient_to,
            gradient_from,
            gradient_top,
            gradient_bottom,
            gradient_type,
            text_size,
            limit,
            description,
            description_color,
          },
        } = res;
        console.log(type.toLowerCase());
        if (type.toLowerCase() === COUPONS.TYPES.GROUP_COUPON) {
          couponsDetailsFormik.setFieldValue(COUPONS.FORMIK.COUPON_TYPE, 1);
          couponsDetailsFormik.setFieldValue("segments", segments);
          couponsDetailsFormik.setFieldValue("duration", duration);
          couponsDetailsFormik.setFieldValue("duration_type", duration_type);
        } else if (type.toLowerCase() === COUPONS.TYPES.COURSE_DISCOUNT) {
          couponsDetailsFormik.setFieldValue(COUPONS.FORMIK.COUPON_TYPE, 2);

          let cl = [...universities];
          cl = cl.map((cou: any) => {
            const modifiedCourse = cou.course.map((it: any) => it.id);
            return { ...cou, courses: modifiedCourse };
          });
          fetchAllCourses(cl).then((res) => {
            res.forEach((val, ind) => {
              cl[ind].allCourses = val;
            });
            couponsDetailsFormik.setFieldValue(
              COUPONS.FORMIK.SELECTED_UNIVERSITIES,
              cl
            );
            console.log(cl, "sdds");
          });
        } else if (type.toLowerCase() === COUPONS.TYPES.SPECIAL_PACKAGE) {
          couponsDetailsFormik.setFieldValue(COUPONS.FORMIK.COUPON_TYPE, 3);
          couponsDetailsFormik.setFieldValue("description", description);
          couponsDetailsFormik.setFieldValue(
            "description_color",
            description_color
          );
          couponsDetailsFormik.setFieldValue("text", text);
          couponsDetailsFormik.setFieldValue(
            "bannerImage",
            IMAGE_BASE_URL + image
          );
          let cl = universities;
          universities.map((cou: any, ind: any) => {
            const modifiedCourse = cou.course.map((it: any) => {
              return it.id;
            });
            cl[ind].courses = modifiedCourse;
          });
          fetchAllCourses(universities).then((res) => {
            res.map((val, ind) => {
              cl[ind].allCourses = val;
              couponsDetailsFormik.setFieldValue(
                COUPONS.FORMIK.SELECTED_UNIVERSITIES,
                cl
              );
            });
          });
        } else {
          couponsDetailsFormik.setFieldValue(COUPONS.FORMIK.COUPON_TYPE, 6);
          couponsDetailsFormik.setFieldValue(
            "discount_type",
            discount_type === "Percentage" ? 2 : 1
          );
          couponsDetailsFormik.setFieldValue(
            "discount_amount",
            discount_amount
          );
          couponsDetailsFormik.setFieldValue("slimit", limit ? limit : "");
        }
        couponsDetailsFormik.setFieldValue("text_size", text_size);
        couponsDetailsFormik.setFieldValue("gradient_type", gradient_type);

        couponsDetailsFormik.setFieldValue("gradient_to", gradient_to);
        couponsDetailsFormik.setFieldValue("gradient_from", gradient_from);

        couponsDetailsFormik.setFieldValue("gradient_top", gradient_top);

        couponsDetailsFormik.setFieldValue("gradient_bottom", gradient_bottom);

        couponsDetailsFormik.setFieldValue(
          COUPONS.FORMIK.BACKGROUND_COLOR,
          backgroud_color
        );

        couponsDetailsFormik.setFieldValue(
          COUPONS.FORMIK.TEXT_COLOR,
          text_color
        );

        couponsDetailsFormik.setFieldValue(
          COUPONS.FORMIK.BUTTON_COLOR,
          button_color
        );

        couponsDetailsFormik.setFieldValue(COUPONS.FORMIK.CODE, code);
        if (type !== "Simple Coupon") {
          couponsDetailsFormik.setFieldValue(COUPONS.FORMIK.DATE, expiry_date);
        }

        couponsDetailsFormik.setFieldValue(
          COUPONS.FORMIK.LIMIT,
          courses_count ? courses_count : 0
        );

        couponsDetailsFormik.setFieldValue(
          COUPONS.FORMIK.TEXT,
          text ? text : ""
        );

        couponsDetailsFormik.setFieldValue(COUPONS.FORMIK.MEMBERS, max_members);
      });
    } else {
      couponsDetailsFormik.resetForm();
    }
  }, [selectedCoupon.editOrAdd, showModal]);

  const couponsDetailsFormik = useFormik<CouponFormik>({
    initialValues: {
      couponType: 6,
      backgroundColor: "#000000",
      textColor: "#000000",
      buttonColor: "#000000",
      code: "",
      date: "",
      text: "",
      limit: 0,
      selectedUniversities: [],
      courses: [],
      selectedCourse: 0,
      bannerImage: "",
      gradient_from: "#094E85",
      gradient_to: "#094E85",
      gradient_top: "#000000",
      gradient_bottom: "#000000",
      discount_amount: "",
      discount_type: "2",
      gradient_type: "horizontal",
      duration: "",
      segments: {},
      duration_type: 2,
      text_size: "s",
      description_size: "s",
      slimit: "",
      description: "",
      description_color: "#ffffff",
    },
    validationSchema: CouponDetailsSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      console.log(values);
      const payload = new FormData();
      payload.append("type", values.couponType.toString());
      payload.append("code", values.code);
      if (values.text_size) {
        payload.append("text_size", values.text_size);
      }

      if (values.description_size) {
        payload.append("description_size", values.description_size);
      }

      if (values.gradient_type) {
        payload.append("description_size", values.gradient_type);
      }
      payload.append("backgroud_color", values.backgroundColor);
      payload.append("text_color", values.textColor);
      payload.append("button_color", values.buttonColor);
      payload.append("expiry_date", values.date);
      if (values.slimit) {
        payload.append("description_size", values.slimit);
      }
      payload.append("text", values.text);
      if (values.description) {
        payload.append("description_size", values.description);
      }

      if (values.description_color) {
        payload.append("description_size", values.description_color);
      }
      payload.append("gradient_from", values.gradient_from);
      payload.append("gradient_to", values.gradient_to);
      payload.append("gradient_top", values.gradient_top);
      payload.append("gradient_bottom", values.gradient_bottom);

      if (Number(values.couponType) !== 6) {
        payload.append(
          "universities",
          JSON.stringify(values.selectedUniversities)
        );
      }

      if (uploadedImage) {
        payload.append("image", uploadedImage);
      }

      if (Number(values.couponType) === 5 || Number(values.couponType) === 2) {
        payload.append("text", values.text);
        payload.append("courses_count", values.limit.toString());
        payload.append("courses", JSON.stringify(values.courses));
      }

      if (Number(values.couponType) === 4) {
        payload.append("text", values.text);
        payload.append("courses", JSON.stringify([selectedCourse]));
      }

      if (Number(values.couponType) === 3) {
        payload.append("image", values.bannerImage);
      }

      if (Number(values.couponType) === 6) {
        if (values.discount_amount) {
          payload.append("discount_amount", values.discount_amount);
        }
        if (values.discount_type) {
          payload.append("discount_type", values.discount_type);
        }
      }

      if (Number(values.couponType) === 1) {
        payload.append("segments", JSON.stringify(values.segments));
        payload.append("duration", values.duration);
        payload.append("duration_type", (values.duration_type ?? 0).toString());
      }
      // if (selectedCoupon.editOrAdd !== INSTRUCTOR.REDUX.ADD) {
      //   payload.append("_method", "PUT");
      // }

      toast.promise(
        selectedCoupon.editOrAdd === INSTRUCTOR.REDUX.ADD
          ? addCoupon(payload)
          : editCoupon(payload, selectedCoupon.id as number),
        {
          pending: {
            render() {
              return selectedCoupon.editOrAdd === INSTRUCTOR.REDUX.ADD
                ? MESSAGES.COUPON.ADD
                : MESSAGES.COUPON.EDIT;
            },
          },
          success: {
            render() {
              couponsDetailsFormik.resetForm();
              handleClose();
              return selectedCoupon.editOrAdd === INSTRUCTOR.REDUX.ADD
                ? MESSAGES.COUPON.SUCCESS
                : MESSAGES.COUPON.EDITED;
            },
          },
          error: {
            render({ data }: { data: { data: { message: string } } }) {
              return data.data.message;
            },
          },
        }
      );
    },
  });

  const handleRemovePackage = (universityId: number) => {
    couponsDetailsFormik.setFieldValue(
      "selectedUniversities",
      couponsDetailsFormik.values.selectedUniversities.filter(
        (university) => university.university_id !== universityId
      )
    );
  };

  useEffect(() => {
    const gradientLeft =
      Number(couponsDetailsFormik.values.couponType) === 2
        ? "#FFE602"
        : "#4a80ab";
    const gradientRight =
      Number(couponsDetailsFormik.values.couponType) === 2
        ? "#FFFBD5"
        : "#122b51";

    couponsDetailsFormik.setFieldValue("gradient_from", gradientLeft);
    couponsDetailsFormik.setFieldValue("gradient_to", gradientRight);
    if (Number(couponsDetailsFormik.values.couponType) === 3) {
      couponsDetailsFormik.setFieldValue("textColor", "#ffba00");
    }
  }, [couponsDetailsFormik.values.couponType]);

  return (
    <Modal
      show={showModal}
      onHide={() => {
        handleClose();
      }}
    >
      <div className={styles["add-lead-from"]}>
        <Modal.Header closeButton>
          <Modal.Title>{"Coupons"}</Modal.Title>
        </Modal.Header>
        <Modal.Body
          onClick={() => {
            console.log(couponsDetailsFormik.errors);
            console.log(couponsDetailsFormik.values);
          }}
        >
          <Form onSubmit={() => {}}>
            <div className={styles.fieldStyle}>
              <Form.Group className="from-group" controlId="formUniversity">
                <Form.Label>Type</Form.Label>
                <Form.Control
                  disabled={loading}
                  name={COUPONS.FORMIK.COUPON_TYPE}
                  as="select"
                  value={couponsDetailsFormik.values.couponType}
                  onChange={couponsDetailsFormik.handleChange}
                >
                  {/* <option value={1}>
										{"Group"}
									</option> */}
                  <option value={2}>{"Course Discount"}</option>

                  <option value={3}>{"Special Package"}</option>

                  <option value={6}>{"Simple Coupon"}</option>
                </Form.Control>
              </Form.Group>
            </div>

            {
              <>
                {Number(couponsDetailsFormik.values.couponType) === 1 && (
                  <Form.Group className="from-group" controlId="formFirstName">
                    <Form.Label>Duration</Form.Label>
                    <Form.Control
                      disabled={loading}
                      type="number"
                      value={couponsDetailsFormik.values.duration}
                      onChange={couponsDetailsFormik.handleChange}
                      placeholder="Duration"
                      name={"duration"}
                    />
                  </Form.Group>
                )}
                {couponsDetailsFormik.errors.duration &&
                couponsDetailsFormik.touched.duration ? (
                  <span className={commonstyles.error}>
                    {couponsDetailsFormik.errors.duration}
                  </span>
                ) : null}
              </>
            }
            {Number(couponsDetailsFormik.values.couponType) === 1 && (
              <>
                <Form.Label
                  onClick={() => {
                    console.log(
                      couponsDetailsFormik.errors.selectedUniversities
                    );
                  }}
                >
                  Unit
                </Form.Label>
                <Form.Select
                  disabled={loading}
                  as="select"
                  onChange={couponsDetailsFormik.handleChange}
                  name={"duration_type"}
                >
                  <option value={1} key={0}>
                    Minute
                  </option>
                  <option value={2} key={2}>
                    Hour
                  </option>
                  <option value={3} key={5}>
                    Day
                  </option>
                </Form.Select>
              </>
            )}

            {
              <>
                {Number(couponsDetailsFormik.values.couponType) === 3 && (
                  <Form.Group className="from-group" controlId="formFirstName">
                    <Form.Label>Text</Form.Label>
                    <Form.Control
                      disabled={loading}
                      type="text"
                      value={couponsDetailsFormik.values.text}
                      placeholder="Text"
                      name={COUPONS.FORMIK.TEXT}
                      onChange={couponsDetailsFormik.handleChange}
                    />
                  </Form.Group>
                )}
                {couponsDetailsFormik.errors.text &&
                couponsDetailsFormik.touched.text ? (
                  <span className={commonstyles.error}>
                    {couponsDetailsFormik.errors.text}
                  </span>
                ) : null}
              </>
            }
            {Number(couponsDetailsFormik.values.couponType) === 3 && (
              <Form.Group className="from-group" controlId="formFirstName">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  disabled={loading}
                  type="text"
                  value={couponsDetailsFormik.values.description}
                  placeholder="Description"
                  name={"description"}
                  onChange={couponsDetailsFormik.handleChange}
                />
              </Form.Group>
            )}
            {
              <>
                {(Number(couponsDetailsFormik.values.couponType) === 5 ||
                  Number(couponsDetailsFormik.values.couponType) === 2) && (
                  <Form.Group className="from-group" controlId="formFirstName">
                    <Form.Label>Course Limit</Form.Label>
                    <Form.Control
                      type="number"
                      disabled={loading}
                      placeholder="number"
                      onChange={couponsDetailsFormik.handleChange}
                      value={couponsDetailsFormik.values.limit}
                      name={COUPONS.FORMIK.LIMIT}
                    />
                  </Form.Group>
                )}
                {couponsDetailsFormik.errors.limit &&
                couponsDetailsFormik.touched.limit ? (
                  <span className={commonstyles.error}>
                    {couponsDetailsFormik.errors.limit}
                  </span>
                ) : null}
              </>
            }
            <div className="">
              {Number(couponsDetailsFormik.values.couponType) !== 6 &&
                Number(couponsDetailsFormik.values.couponType) !== 1 && (
                  <Form.Group className="form-group" controlId="formFirstName">
                    <Form.Label>University</Form.Label>

                    {universities?.map((university, id) => (
                      <div
                        key={id}
                        className="university-container mb-4 p-3 border rounded bg-light position-relative"
                      >
                        <Form.Check
                          disabled={loadingCourse}
                          className="from-check"
                          value={university.id as number}
                          type="checkbox"
                          checked={couponsDetailsFormik?.values?.selectedUniversities?.some(
                            (u) => u.university_id === university.id
                          )}
                          onChange={(e) => {
                            console.log(
                              couponsDetailsFormik.values.selectedUniversities,
                              "rrr"
                            );
                            handleUniversityCheckboxClick(e, university.id);
                          }}
                          label={
                            <>
                              {university.name}
                              {couponsDetailsFormik?.values?.selectedUniversities?.some(
                                (u) => u.university_id === university.id
                              ) && (
                                <div className="d-flex justify-content-center mt-2">
                                  <Button
                                    variant="primary"
                                    size="sm"
                                    className="app-primary-btn me-1"
                                    onClick={() => addMore(university.id)}
                                  >
                                    Add More Package
                                  </Button>
                                  <Button
                                    variant="danger"
                                    size="sm"
                                    className="ms-1"
                                    onClick={() =>
                                      handleRemovePackage(university.id)
                                    }
                                  >
                                    Remove Packages
                                  </Button>
                                </div>
                              )}
                            </>
                          }
                        />

                        <div className=" mt-3">
                          {Number(couponsDetailsFormik.values.couponType) !==
                            0 &&
                            couponsDetailsFormik.values.selectedUniversities?.map(
                              (u, ind) => {
                                if (u.university_id === university.id) {
                                  return (
                                    <div
                                      key={ind}
                                      className="package-container"
                                    >
                                      {/* Discount Field */}
                                      <Form.Group
                                        className="form-group mb-3"
                                        controlId="formDiscount"
                                      >
                                        <Form.Label>Discount</Form.Label>
                                        <Form.Control
                                          disabled={loading}
                                          value={searchUniversity(
                                            university.id,
                                            "discount",
                                            ind
                                          )}
                                          name={`selectedUniversities.${ind}.discount`}
                                          type="number"
                                          placeholder="Discount"
                                          onChange={
                                            couponsDetailsFormik.handleChange
                                          }
                                        />
                                        {couponsDetailsFormik.errors
                                          .selectedUniversities &&
                                          couponsDetailsFormik.touched
                                            .selectedUniversities &&
                                          Array.isArray(
                                            couponsDetailsFormik.errors
                                              .selectedUniversities
                                          ) && (
                                            <span
                                              className={commonstyles.error}
                                            >
                                              {
                                                //@ts-ignore
                                                couponsDetailsFormik.errors?.selectedUniversities[calculateUniIndex(couponsDetailsFormik.values.selectedUniversities,university.id)]?.discount
                                              }
                                            </span>
                                          )}
                                      </Form.Group>

                                      {/* Unit Field */}
                                      <Form.Group
                                        className="form-group mb-3"
                                        controlId="formUnit"
                                      >
                                        <Form.Label>Unit</Form.Label>
                                        <Form.Control
                                          disabled={loading}
                                          as="select"
                                          value={searchUniversity(
                                            university.id,
                                            "discount_type",
                                            ind
                                          )}
                                          onChange={
                                            couponsDetailsFormik.handleChange
                                          }
                                          name={`selectedUniversities.${ind}.discount_type`}
                                        >
                                          <option value={2}>%</option>
                                          <option value={1}>KD</option>
                                        </Form.Control>
                                      </Form.Group>

                                      {/* Courses Field */}
                                      <Form.Group
                                        style={{
                                          height: "200px",
                                          marginBottom: "20px",
                                        }}
                                        className="overflow-auto form-group"
                                        controlId="formCourses"
                                      >
                                        <Form.Label>Courses:</Form.Label>
                                        {couponsDetailsFormik.values.selectedUniversities[
                                          ind
                                        ]?.allCourses?.map(
                                          (course: {
                                            id: number;
                                            name: string;
                                          }) => (
																						<div>
                                            <Form.Check
                                              key={course.id}
                                              type="checkbox"
                                              id={`checkbox-${course.name}`}
                                              label={course.name}
                                              value={course.id}
                                              checked={searchUniversity(
                                                university.id,
                                                "courses",
                                                ind
                                              ).includes(course.id)}
                                              onChange={(e) =>
                                                handleCourseCheckbox(
                                                  e,
                                                  university.id,
                                                  course.id,
                                                  ind
                                                )
                                              }
                                            />
																						</div>

                                          )
                                        )}
                                      </Form.Group>
                                      {couponsDetailsFormik.errors
                                        .selectedUniversities &&
                                        couponsDetailsFormik.touched
                                          .selectedUniversities &&
                                        Array.isArray(
                                          couponsDetailsFormik.errors
                                            .selectedUniversities
                                        ) && (
                                          <span className={commonstyles.error}>
                                            {
                                              //@ts-ignore
                                              couponsDetailsFormik.errors?.selectedUniversities[calculateUniIndex(couponsDetailsFormik.values.selectedUniversities,university.id)]?.courses
                                            }
                                          </span>
                                        )}
                                    </div>
                                  );
                                }
                              }
                            )}
                        </div>
                      </div>
                    ))}

                    {couponsDetailsFormik.errors.selectedUniversities &&
                      couponsDetailsFormik.touched.selectedUniversities &&
                      couponsDetailsFormik.errors.selectedUniversities ===
                        "Required" && (
                        <span className={commonstyles.error}>
                          {
                            //@ts-ignore
                            couponsDetailsFormik.errors
                              .selectedUniversities as string
                          }
                        </span>
                      )}
                  </Form.Group>
                )}
            </div>

            {
              <>
                {Number(couponsDetailsFormik.values.couponType) === 1 && (
                  <>
                    <Form.Label>University</Form.Label>

                    {universities?.map((university, id) => (
                      <>
                        <Form.Check
                          key={id}
                          disabled={loading}
                          className="from-check"
                          //@ts-ignore

                          type="checkbox"
                          checked={
                            couponsDetailsFormik?.values?.segments &&
                            couponsDetailsFormik?.values?.segments.hasOwnProperty(
                              university.id
                            )
                          }
                          onChange={(e) => {
                            console.log(
                              couponsDetailsFormik.values.selectedUniversities,
                              "rrr"
                            );
                            handleUniversityChange(e, university.id);
                          }}
                          label={university.name}
                        ></Form.Check>
                        {
                          //@ts-ignore
                          couponsDetailsFormik.values.segments[
                            university.id
                          ] && (
                            <Button onClick={() => addConfis(university.id)}>
                              Add More Segment
                            </Button>
                          )
                        }
                        {
                          //@ts-ignore
                          couponsDetailsFormik.values.segments[university.id ]?.map((obj, ind) => {
                            return (
                              <>
                                <Form.Group>
                                  <Form.Label>Min Members</Form.Label>
                                  <Form.Control
                                    disabled={loading}
                                    type="number"
                                    //@ts-ignore
                                    value={couponsDetailsFormik.values?.segments[university.id][ind]?.min}
                                    onChange={couponsDetailsFormik.handleChange}
                                    placeholder="No. of members"
                                    name={`segments[${university.id}][${ind}].min`}
                                  />
                                  {couponsDetailsFormik.errors.segments &&
                                  couponsDetailsFormik.touched.segments &&
                                  couponsDetailsFormik.errors?.segments[
                                    university.id
                                  ] ? (
                                    <span className={commonstyles.error}>
                                      {
                                        //@ts-ignore
                                        couponsDetailsFormik.errors?.segments[university.id][ind]?.min
                                      }
                                    </span>
                                  ) : null}
                                </Form.Group>

                                <Form.Group>
                                  <Form.Label>Max Members</Form.Label>
                                  <Form.Control
                                    disabled={loading}
                                    type="number"
                                    //@ts-ignore
                                    value={couponsDetailsFormik.values?.segments[university.id][ind]?.max
                                    }
                                    onChange={couponsDetailsFormik.handleChange}
                                    placeholder="No. of members"
                                    name={`segments[${university.id}][${ind}].max`}
                                  />
                                  {couponsDetailsFormik.errors.segments &&
                                  couponsDetailsFormik.touched.segments &&
                                  couponsDetailsFormik.errors?.segments[
                                    university.id
                                  ] ? (
                                    <span className={commonstyles.error}>
                                      {
                                        //@ts-ignore
                                        couponsDetailsFormik.errors?.segments[university.id][ind]?.max
                                      }
                                    </span>
                                  ) : null}
                                </Form.Group>
                                <Form.Group>
                                  <Form.Label>Discount</Form.Label>
                                  <Form.Control
                                    disabled={loading}
                                    type="number"
                                    //@ts-ignore
                                    value={couponsDetailsFormik.values?.segments[university.id][ind]?.discount
                                    }
                                    onChange={couponsDetailsFormik.handleChange}
                                    placeholder="Discount"
                                    name={`segments[${university.id}][${ind}].discount`}
                                  />

                                  {couponsDetailsFormik.errors.segments &&
                                  couponsDetailsFormik.touched.segments &&
                                  couponsDetailsFormik.errors?.segments[
                                    university.id
                                  ] ? (
                                    <span className={commonstyles.error}>
                                      {
                                        //@ts-ignore
                                        couponsDetailsFormik?.errors?.segments[university.id][ind]?.discount
                                      }
                                    </span>
                                  ) : null}
                                </Form.Group>
                                <Form.Group
                                  className="from-group"
                                  controlId="formUniversity"
                                >
                                  <Form.Label>Unit</Form.Label>
                                  <Form.Select
                                    disabled={true}
                                    contentEditable={false}
                                    //@ts-ignore
                                    value={couponsDetailsFormik.values.segments[university.id][ind].discount_type
                                    }
                                    onChange={couponsDetailsFormik.handleChange}
                                    name={`segments[${university.id}][${ind}].discount_type`}
                                  >
                                    <option value={2}>{"%"}</option>
                                    <option value={1}>{"KD"}</option>
                                  </Form.Select>
                                </Form.Group>
                              </>
                            );
                          })
                        }
                      </>
                    ))}
                  </>
                )}
                {couponsDetailsFormik.errors?.segments &&
                couponsDetailsFormik?.touched?.segments &&
                couponsDetailsFormik?.errors?.segments ? (
                  <span className={commonstyles.error}>
                    {
                      //@ts-ignore
                      typeof couponsDetailsFormik?.errors?.segments ===
                        "string" && couponsDetailsFormik?.errors?.segments
                    }
                  </span>
                ) : null}
              </>
            }

            {Number(couponsDetailsFormik.values.couponType) === 3 && (
              <Form.Group className="from-group" controlId="formFirstName">
                <Form.Label>Banner Image</Form.Label>
                <Form.Control
                  type="file"
                  name={"bannerImage"}
                  disabled={loading}
                  onChange={handleImageUpload}
                  placeholder="No. of members"
                />
              </Form.Group>
            )}
            <Form.Group>
              {Number(couponsDetailsFormik.values.couponType) !== 6 && (
                <Form.Check
                  style={{ marginTop: "30px" }}
                  key={1}
                  className="from-check"
                  type="checkbox"
                  checked={customised}
                  onChange={(e) => {
                    setCustomised(e.target.checked);
                  }}
                  label={"Customize"}
                ></Form.Check>
              )}
            </Form.Group>
            {Number(couponsDetailsFormik.values.couponType) !== 6 &&
              customised && (
                <>
                  {Number(couponsDetailsFormik.values.couponType) !== 2 && (
                    <Form.Group
                      className="from-group"
                      controlId="formUniversity"
                    >
                      <Form.Label
                        onClick={() => {
                          console.log(
                            couponsDetailsFormik.errors.selectedUniversities
                          );
                        }}
                      >
                        Text Size
                      </Form.Label>
                      <Form.Control
                        disabled={loading}
                        as="select"
                        value={couponsDetailsFormik.values.text_size}
                        onChange={couponsDetailsFormik.handleChange}
                        name={"text_size"}
                      >
                        <option value={"s"}>{"Small"}</option>
                        <option value={"m"}>{"Medium"}</option>
                        <option value={"l"}>{"Large"}</option>
                      </Form.Control>
                    </Form.Group>
                  )}

                  {Number(couponsDetailsFormik.values.couponType) === 3 && (
                    <Form.Group
                      className="from-group"
                      controlId="formUniversity"
                    >
                      <Form.Label
                        onClick={() => {
                          console.log(
                            couponsDetailsFormik.errors.selectedUniversities
                          );
                        }}
                      >
                        Description Size
                      </Form.Label>
                      <Form.Control
                        disabled={loading}
                        as="select"
                        value={couponsDetailsFormik.values.description_size}
                        onChange={couponsDetailsFormik.handleChange}
                        name={"description_size"}
                      >
                        <option value={"s"}>{"Small"}</option>
                        <option value={"m"}>{"Medium"}</option>
                        <option value={"l"}>{"Large"}</option>
                      </Form.Control>
                    </Form.Group>
                  )}
                  {Number(couponsDetailsFormik.values.couponType) !== 1 && (
                    <Form.Group
                      className="from-group"
                      controlId="formUniversity"
                    >
                      <Form.Label
                        onClick={() => {
                          console.log(
                            couponsDetailsFormik.errors.selectedUniversities
                          );
                        }}
                      >
                        Gradient Direction
                      </Form.Label>
                      <Form.Control
                        disabled={loading}
                        as="select"
                        value={couponsDetailsFormik.values.gradient_type}
                        onChange={couponsDetailsFormik.handleChange}
                        name={"gradient_type"}
                      >
                        <option value={"horizontal"}>{"Horizontal"}</option>
                        <option value={"vertical"}>{"Vertical"}</option>
                      </Form.Control>
                    </Form.Group>
                  )}
                  {couponsDetailsFormik.values.gradient_type === "horizontal"
                    ? Number(couponsDetailsFormik.values.couponType) !== 1 && (
                        <>
                          <Form.Label>Gradient Left</Form.Label>

                          <span className={styles.colorspan}>
                            <input
                              disabled={loading}
                              value={couponsDetailsFormik.values.gradient_from}
                              onChange={couponsDetailsFormik.handleChange}
                              name={"gradient_from"}
                              type="color"
                            />
                            <input
                              disabled={loading}
                              value={couponsDetailsFormik.values.gradient_from}
                              readOnly
                              name={"gradient_from"}
                              className="colortext"
                              type="text"
                            />
                          </span>
                          <Form.Label>Gradient Right</Form.Label>

                          <span className={styles.colorspan}>
                            <input
                              disabled={loading}
                              value={couponsDetailsFormik.values.gradient_to}
                              onChange={couponsDetailsFormik.handleChange}
                              name={"gradient_to"}
                              type="color"
                            />
                            <input
                              disabled={loading}
                              value={couponsDetailsFormik.values.gradient_to}
                              readOnly
                              name={"gradient_to"}
                              className="colortext"
                              type="text"
                            />
                          </span>
                        </>
                      )
                    : Number(couponsDetailsFormik.values.couponType) !== 1 && (
                        <>
                          <Form.Label>Gradient Top</Form.Label>

                          <span className={styles.colorspan}>
                            <input
                              disabled={loading}
                              value={couponsDetailsFormik.values.gradient_top}
                              onChange={couponsDetailsFormik.handleChange}
                              name={"gradient_top"}
                              type="color"
                            />
                            <input
                              disabled={loading}
                              value={couponsDetailsFormik.values.gradient_top}
                              readOnly
                              name={"gradient_top"}
                              className="colortext"
                              type="text"
                            />
                          </span>
                          <Form.Label>Gradient Bottom</Form.Label>

                          <span className={styles.colorspan}>
                            <input
                              disabled={loading}
                              value={
                                couponsDetailsFormik.values.gradient_bottom
                              }
                              onChange={couponsDetailsFormik.handleChange}
                              name={"gradient_bottom"}
                              type="color"
                            />
                            <input
                              disabled={loading}
                              value={
                                couponsDetailsFormik.values.gradient_bottom
                              }
                              readOnly
                              name={"gradient_bottom"}
                              className="colortext"
                              type="text"
                            />
                          </span>
                        </>
                      )}

                  {Number(couponsDetailsFormik.values.couponType) !== 6 &&
                    Number(couponsDetailsFormik.values.couponType) !== 1 && (
                      <>
                        <Form.Label>Text Color</Form.Label>

                        <span className={styles.colorspan}>
                          <input
                            disabled={loading}
                            value={couponsDetailsFormik.values.textColor}
                            name={COUPONS.FORMIK.TEXT_COLOR}
                            onChange={couponsDetailsFormik.handleChange}
                            type="color"
                          />
                          <input
                            readOnly
                            value={couponsDetailsFormik.values.textColor}
                            className="colortext"
                            type="text"
                          />
                        </span>
                        <Form.Label>Button Color</Form.Label>

                        <span className={styles.colorspan}>
                          <input
                            value={couponsDetailsFormik.values.buttonColor}
                            onChange={couponsDetailsFormik.handleChange}
                            name="buttonColor"
                            type="color"
                          />
                          <input
                            readOnly
                            value={couponsDetailsFormik.values.buttonColor}
                            name={COUPONS.FORMIK.BUTTON_COLOR}
                            className="colortext"
                            type="text"
                          />
                        </span>
                      </>
                    )}
                  {Number(couponsDetailsFormik.values.couponType) === 3 && (
                    <>
                      <Form.Label>Description Color</Form.Label>

                      <span className={styles.colorspan}>
                        <input
                          disabled={loading}
                          value={couponsDetailsFormik.values.description_color}
                          name={"description_color"}
                          onChange={couponsDetailsFormik.handleChange}
                          type="color"
                        />
                        <input
                          readOnly
                          value={couponsDetailsFormik.values.description_color}
                          className="colortext"
                          type="text"
                        />
                      </span>
                      {Number(couponsDetailsFormik.values.couponType) !== 1 && (
                        <>
                          <Form.Label>Button Color</Form.Label>

                          <span className={styles.colorspan}>
                            <input
                              value={couponsDetailsFormik.values.buttonColor}
                              onChange={couponsDetailsFormik.handleChange}
                              name="buttonColor"
                              type="color"
                            />
                            <input
                              readOnly
                              value={couponsDetailsFormik.values.buttonColor}
                              name={COUPONS.FORMIK.BUTTON_COLOR}
                              className="colortext"
                              type="text"
                            />
                          </span>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            {Number(couponsDetailsFormik.values.couponType) === 6 && (
              <>
                <Form.Group className="from-group " controlId="formFirstName">
                  <Form.Label>Discount</Form.Label>
                  <Form.Control
                    disabled={loading}
                    type="number"
                    value={couponsDetailsFormik.values.discount_amount}
                    onChange={couponsDetailsFormik.handleChange}
                    placeholder="Discount"
                    name={COUPONS.FORMIK.DISCOUNT_AMOUNT}
                  />
                </Form.Group>
                {couponsDetailsFormik.errors.discount_amount &&
                couponsDetailsFormik.touched.discount_amount ? (
                  <span className={commonstyles.error}>
                    {couponsDetailsFormik.errors.discount_amount}
                  </span>
                ) : null}

                <Form.Group className="from-group" controlId="formUniversity">
                  <Form.Label
                    onClick={() => {
                      console.log(
                        couponsDetailsFormik.errors.selectedUniversities
                      );
                    }}
                  >
                    Unit
                  </Form.Label>
                  <Form.Control
                    disabled={loading}
                    as="select"
                    value={couponsDetailsFormik.values.discount_type}
                    onChange={couponsDetailsFormik.handleChange}
                    name={COUPONS.FORMIK.DISCOUNT_TYPE}
                  >
                    <option value={2}>{"%"}</option>
                    <option value={1}>{"KD"}</option>
                  </Form.Control>
                </Form.Group>
              </>
            )}

            <div className={styles.fieldStyle}>
              {Number(couponsDetailsFormik.values.couponType) === 6 && (
                <>
                  <Form.Group className="from-group" controlId="formFirstName">
                    <Form.Label>Coupon Code</Form.Label>
                    <Form.Control
                      disabled={loading}
                      onChange={couponsDetailsFormik.handleChange}
                      value={couponsDetailsFormik.values.code}
                      type="text"
                      placeholder="code"
                      name={COUPONS.FORMIK.CODE}
                    />
                  </Form.Group>
                  {couponsDetailsFormik.errors.code &&
                  couponsDetailsFormik.touched.code ? (
                    <span className={commonstyles.error}>
                      {couponsDetailsFormik.errors.code}
                    </span>
                  ) : null}
                </>
              )}
              {Number(couponsDetailsFormik.values.couponType) !== 1 && (
                <Form.Group className="from-group" controlId="formFirstName">
                  <Form.Label>Validity</Form.Label>
                  <Form.Control
                    disabled={loading}
                    onChange={couponsDetailsFormik.handleChange}
                    value={couponsDetailsFormik.values.date}
                    type="date"
                    placeholder="date"
                    name={COUPONS.FORMIK.DATE}
                    min={new Date().toJSON().slice(0, 10)}
                  />
                </Form.Group>
              )}
              {couponsDetailsFormik.errors.date &&
              couponsDetailsFormik.touched.date ? (
                <span className={commonstyles.error}>
                  {couponsDetailsFormik.errors.date}
                </span>
              ) : null}
              {Number(couponsDetailsFormik.values.couponType) === 6 && (
                <Form.Group className="from-group" controlId="formFirstName">
                  <Form.Label>limit</Form.Label>
                  <Form.Control
                    disabled={loading}
                    type="number"
                    value={couponsDetailsFormik.values.slimit}
                    onChange={couponsDetailsFormik.handleChange}
                    placeholder="limit"
                    name={"slimit"}
                  />
                </Form.Group>
              )}
              {couponsDetailsFormik.errors.slimit &&
              couponsDetailsFormik.touched.slimit ? (
                <span className={commonstyles.error}>
                  {couponsDetailsFormik.errors.slimit}
                </span>
              ) : null}
            </div>
          </Form>

          {Number(couponsDetailsFormik.values.couponType) === 1 && (
            <div
              className="coupon_cour "
              style={
                couponsDetailsFormik.values.gradient_type === "horizontal"
                  ? {
                      backgroundImage: `linear-gradient(to right, ${couponsDetailsFormik.values.gradient_from}, ${couponsDetailsFormik.values.gradient_to})`,
                    }
                  : {
                      backgroundImage: `linear-gradient(to bottom, ${couponsDetailsFormik.values.gradient_top}, ${couponsDetailsFormik.values.gradient_bottom})`,
                    }
              }
            >
              <div className="d-flex justify-content-between">
                <div className={`${styles.inner_coupon} `}>
                  <img src={users} height="40" width="40" alt="avatar" />
                  {couponsDetailsFormik.values.text_size === null && (
                    <h5
                      style={{ color: couponsDetailsFormik.values.textColor }}
                      className="mb-0 text-center"
                    >
                      X %/KD
                    </h5>
                  )}

                  {couponsDetailsFormik.values.text_size === "s" && (
                    <p
                      style={{ color: couponsDetailsFormik.values.textColor }}
                      className="mb-0 text-center"
                    >
                      X %/KD
                    </p>
                  )}
                  {couponsDetailsFormik.values.text_size === "m" && (
                    <h5
                      style={{ color: couponsDetailsFormik.values.textColor }}
                      className="mb-0 text-center"
                    >
                      X %/KD
                    </h5>
                  )}
                  {couponsDetailsFormik.values.text_size === "l" && (
                    <h6
                      style={{ color: couponsDetailsFormik.values.textColor }}
                      className="mb-0 text-center"
                    >
                      X %/KD
                    </h6>
                  )}
                  {couponsDetailsFormik.values.text_size === null && (
                    <h5
                      style={{ color: couponsDetailsFormik.values.textColor }}
                    >
                      OFF
                    </h5>
                  )}

                  {couponsDetailsFormik.values.text_size === "s" && (
                    <p style={{ color: couponsDetailsFormik.values.textColor }}>
                      OFF
                    </p>
                  )}
                  {couponsDetailsFormik.values.text_size === "m" && (
                    <h5
                      style={{ color: couponsDetailsFormik.values.textColor }}
                    >
                      OFF
                    </h5>
                  )}
                  {couponsDetailsFormik.values.text_size === "l" && (
                    <h4
                      style={{ color: couponsDetailsFormik.values.textColor }}
                    >
                      OFF
                    </h4>
                  )}
                </div>
                <div className={styles.days_Lefts}>
                  {couponsDetailsFormik.values.text_size === null && (
                    <h5
                      style={{ color: couponsDetailsFormik.values.textColor }}
                      className="mb-0"
                    >
                      X Members
                    </h5>
                  )}

                  {couponsDetailsFormik.values.text_size === "s" && (
                    <p
                      style={{ color: couponsDetailsFormik.values.textColor }}
                      className="mb-0"
                    >
                      X Members
                    </p>
                  )}
                  {couponsDetailsFormik.values.text_size === "m" && (
                    <h5
                      style={{ color: couponsDetailsFormik.values.textColor }}
                      className="mb-0"
                    >
                      X Members
                    </h5>
                  )}
                  {couponsDetailsFormik.values.text_size === "l" && (
                    <h4
                      style={{ color: couponsDetailsFormik.values.textColor }}
                      className="mb-0"
                    >
                      X Members
                    </h4>
                  )}
                  {couponsDetailsFormik.values.text_size === null && (
                    <h6>Use this coupon to enjoy special discount</h6>
                  )}

                  {couponsDetailsFormik.values.text_size === "s" && (
                    <p>Use this coupon to enjoy special discount</p>
                  )}
                  {couponsDetailsFormik.values.text_size === "m" && (
                    <h5>Use this coupon to enjoy special discount</h5>
                  )}

                  {couponsDetailsFormik.values.text_size === "l" && (
                    <h6>Use this coupon to enjoy special discount</h6>
                  )}
                </div>
              </div>
              <div className="d-flex justify-content-between create_join">
                <button className="w-50 create_group">
                  <img src={CreateVector} />
                  <>
                    {couponsDetailsFormik.values.text_size === null && (
                      <h6>Create a Group</h6>
                    )}

                    {couponsDetailsFormik.values.text_size === "s" && (
                      <p className="mb-0">Create a Group</p>
                    )}
                    {couponsDetailsFormik.values.text_size === "m" && (
                      <h6 className="mb-0">Create a Group</h6>
                    )}
                    {couponsDetailsFormik.values.text_size === "l" && (
                      <h5 className="mb-0">Create a Group</h5>
                    )}
                  </>
                </button>
                <button className="w-50 join_group">
                  <img src={JoinVector} />
                  {couponsDetailsFormik.values.text_size === null && (
                    <h6>Join a Group</h6>
                  )}

                  {couponsDetailsFormik.values.text_size === "s" && (
                    <p className="mb-0">Join a Group</p>
                  )}
                  {couponsDetailsFormik.values.text_size === "m" && (
                    <h6 className="mb-0">Join a Group</h6>
                  )}
                  {couponsDetailsFormik.values.text_size === "l" && (
                    <h5 className="mb-0">Join a Group</h5>
                  )}
                </button>
              </div>
            </div>
          )}
          {Number(couponsDetailsFormik.values.couponType) === 2 && (
            <div
              style={
                couponsDetailsFormik.values.gradient_type === "horizontal"
                  ? {
                      backgroundImage: `linear-gradient(to right, ${couponsDetailsFormik.values.gradient_from}, ${couponsDetailsFormik.values.gradient_to})`,
                    }
                  : {
                      backgroundImage: `linear-gradient(to bottom, ${couponsDetailsFormik.values.gradient_top}, ${couponsDetailsFormik.values.gradient_bottom})`,
                    }
              }
              className="coupon_cour d-flex justify-content-between "
            >
              <div className={`${styles.inner_coupon} `}>
                <img src={BookVector} height="40" width="40" alt="avatar" />
                <h5
                  style={{ color: couponsDetailsFormik.values.textColor }}
                  className="mb-0 text-center"
                >
                  X KD/%
                </h5>
                <h4 style={{ color: couponsDetailsFormik.values.textColor }}>
                  OFF
                </h4>
              </div>
              <div className={styles.days_Lefts}>
                <h5
                  style={{ color: couponsDetailsFormik.values.textColor }}
                  className="mb-0"
                >
                  X Courses
                </h5>
                <h3 style={{ color: couponsDetailsFormik.values.textColor }}>
                  X KD/% OFF
                </h3>
                <span>
                  <label
                    style={{ color: couponsDetailsFormik.values.textColor }}
                  >
                    X/XX
                  </label>
                  <progress id="file" value="32" max="100">
                    32%
                  </progress>
                </span>
                <p className="red_alert">
                  Valid Till {couponsDetailsFormik.values.date}
                </p>
                <button
                  style={{
                    backgroundColor: couponsDetailsFormik.values.buttonColor,
                  }}
                >
                  View Available courses under this Offer
                </button>
              </div>
            </div>
          )}
          {Number(couponsDetailsFormik.values.couponType) === 3 && (
            <div
              style={
                couponsDetailsFormik.values.gradient_type === "horizontal"
                  ? {
                      backgroundImage: `linear-gradient(to right, ${couponsDetailsFormik.values.gradient_from}, ${couponsDetailsFormik.values.gradient_to})`,
                    }
                  : {
                      backgroundImage: `linear-gradient(to bottom, ${couponsDetailsFormik.values.gradient_top}, ${couponsDetailsFormik.values.gradient_bottom})`,
                    }
              }
              className={styles.included_courses}
            >
              <label
                className={`${styles.included_cart} d-flex justify-content-between`}
              >
                <p>Included Courses:</p>
                <p className="text-white">X courses</p>
              </label>

              <div className={styles.timeline_primins}>
                <div className={styles.container}>
                  <div className={styles.content}>
                    <h5>
                      Course Name <span>X KD</span>
                    </h5>
                    <p>Department</p>
                  </div>
                </div>
              </div>
              <div className={styles.timeline_primins}>
                <div className={styles.container}>
                  <div className={styles.content}>
                    <h5>
                      Course Name <span>X KD</span>
                    </h5>
                    <p>Department</p>
                  </div>
                </div>
              </div>

              <label
                className={`${styles.price_total_cart} d-flex align-items-center`}
              >
                <h6>
                  <p className="mb-0">Total Price</p>
                  <h5 className="d-flex justify-content-between">
                    <h6>x KD </h6>
                    <span>x KD</span>
                  </h5>
                </h6>
                <button>Add to Cart</button>
                <img src={offer_book} alt="" />
              </label>
            </div>
          )}

          {Number(couponsDetailsFormik.values.couponType) === 3 && (
            <div
              style={
                couponsDetailsFormik.values.gradient_type === "horizontal"
                  ? {
                      backgroundImage: `linear-gradient(to right, ${couponsDetailsFormik.values.gradient_from}, ${couponsDetailsFormik.values.gradient_to})`,
                    }
                  : {
                      backgroundImage: `linear-gradient(to bottom, ${couponsDetailsFormik.values.gradient_top}, ${couponsDetailsFormik.values.gradient_bottom})`,
                    }
              }
              className="courses_package"
            >
              <div className="d-flex justify-content-between">
                <span>
                  <h6 style={{ color: couponsDetailsFormik.values.textColor }}>
                    {couponsDetailsFormik.values.text}
                  </h6>
                  <p
                    style={{
                      color: couponsDetailsFormik.values.description_color,
                    }}
                  >
                    {couponsDetailsFormik.values.description}
                  </p>
                  <button
                    style={{
                      backgroundColor: couponsDetailsFormik.values.buttonColor,
                    }}
                  >
                    {" "}
                    View Courses
                  </button>
                </span>
                <span className="time_hour d-flex">
                  <p>
                    <label>38 </label> days
                  </p>
                  <p>
                    <label>8 </label>8 Hours
                  </p>
                  <p>
                    <label>42 </label> Mins
                  </p>
                </span>
                {uploadedImage && (
                  <img src={URL.createObjectURL(uploadedImage)} alt="Banner" />
                )}
                {(couponsDetailsFormik.values.bannerImage && (
                  <img
                    src={couponsDetailsFormik.values.bannerImage}
                    alt="Banner"
                  />
                )) ||
                  (uploadedImage && (
                    <img
                      src={URL.createObjectURL(uploadedImage)}
                      alt="Banner"
                    />
                  ))}
              </div>
            </div>
          )}
          <Button
            onClick={() => {
              couponsDetailsFormik.submitForm();
            }}
            className={`${styles.buttonss} mt-3`}
            type="submit"
          >
            {selectedCoupon.editOrAdd === INSTRUCTOR.REDUX.ADD ? "Add" : "Edit"}
          </Button>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default AddorEdit;
