import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Dropdown, Spinner } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEllipsisH,
  faEye,
  faGrip,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import commonStyles from "../../../common/styles/common.module.css";
import { INSTRUCTOR } from "../../../../utils/instructor";
import { departmentModal } from "../../../../redux/reducers/department";
import { Avatar } from "../../../../assets/images";
import { useDispatch } from "react-redux";
import { IMAGE_BASE_URL } from "../../../../config";

interface ListItem {
  id: number;
}
interface ItemProps {
  item: ListItem;
  itemSelected?: boolean;
  dragHandleProps: {
    onMouseDown: (e: React.MouseEvent) => void;
    onTouchStart: (e: React.TouchEvent) => void;
  };
}
const Item: React.FC<any> = ({
  item,
  itemSelected,
  dragHandleProps,
  setShowModal,
  handleRemoveDepartment,
}: any) => {
  const { onMouseDown, onTouchStart } = dragHandleProps;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  return (
    <tr
      key={item.id}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        padding: "10px 0",
        borderBottom: "1px solid #e0e0e0",
      }}
    >
      <td
        style={{
          width: "250px",
          textAlign: "left",
          display: "flex",
          alignItems: "center",
          padding: "0 10px",
          gap: "10px",
        }}
      >
        <img
          src={item.icon ? `${IMAGE_BASE_URL}${item.icon}` : Avatar}
          height={40}
          width={40}
          alt="avatar"
          style={{ borderRadius: "50%", objectFit: "cover" }}
        />
        <span style={{ fontWeight: "normal", fontSize: "14px", color: "#333" }}>
          {item.name}
        </span>
      </td>
      <td
        style={{
          width: "250px",
          textAlign: "center",
        }}
      >
        <Dropdown drop="down-centered" className={commonStyles.tddropdown}>
          <Dropdown.Toggle
            style={{
              backgroundColor: "transparent",
              border: "none",
              padding: "5px 10px",
              boxShadow: "none",
              color: "#555",
            }}
          >
            <FontAwesomeIcon icon={faEllipsisH} />
          </Dropdown.Toggle>

          <Dropdown.Menu className="dm">
            <Dropdown.Item
              onClick={() => {
                dispatch(
                  departmentModal({
                    id: item.id,
                    editOrAdd: INSTRUCTOR.REDUX.EDIT,
                  })
                );
                setShowModal(true);
              }}
            >
              <FontAwesomeIcon
                className={commonStyles.iconmargin}
                icon={faEdit}
                size="xs"
              />
              Edit
            </Dropdown.Item>
            <Dropdown.Item onClick={() => handleRemoveDepartment(item.id)}>
              <FontAwesomeIcon
                className={commonStyles.iconmargin}
                size="xs"
                icon={faTrash}
              />
              Remove
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() =>
                navigate(
                  `/universities/commondepartments/courses/${item.id}/${id}`
                )
              }
            >
              <FontAwesomeIcon
                className={commonStyles.iconmargin}
                size="xs"
                icon={faEye}
              />
              View Courses
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </td>

      {/* Drag Handle */}
      <td
        style={{
          width: "250px",
          textAlign: "center",
        }}
      >
        <div
          className="disable-select dragHandle"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px",
            cursor: "grab",
            color: "#888",
          }}
          onTouchStart={(e) => {
            e.preventDefault();
            document.body.style.overflow = "hidden";
            onTouchStart(e);
          }}
          onMouseDown={(e) => {
            document.body.style.overflow = "hidden";
            onMouseDown(e);
          }}
          onTouchEnd={() => {
            document.body.style.overflow = "visible";
          }}
          onMouseUp={() => {
            document.body.style.overflow = "visible";
          }}
        >
          <FontAwesomeIcon icon={faGrip} />
        </div>
      </td>
    </tr>
  );
};
export { Item };
