import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Modal, Form, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { useAppSelector } from "../../../redux/hooks";
import { INSTRUCTOR } from "../../../utils/instructor";
import {  addDepartment, commonDepartmentList, editDepartment, getDepartment } from "../api";
import {DepartmentDetailsSchema} from "../validations";
import styles from "../../instructor/styles/instructor.module.css";
import commonstyles from "../../instructor/styles/common.module.css";
import { UNIVERSITY } from "../../../utils/university";
// import { MESSAGES } from "../../../utils/messages";
import { AddUniversityProp, Department } from "./interface";
import { MESSAGES } from "../../../utils/messages";

const AddOrEditDepartment = ({ showModal, handleClose }: AddUniversityProp) => {
	const selectedDepartment = useAppSelector((state) => state.departmentReducer);
	 const { id } = useParams();
	 const [allCategory,setAllCategory]=useState<any>([])

	useEffect(() => {
		commonDepartmentList({university_id:id}).then((res)=>{
			setAllCategory(res.data)
		})
		
		if (selectedDepartment.editOrAdd === INSTRUCTOR.REDUX.EDIT) {
			getDepartment(Number(selectedDepartment.id)).then((res) => {
				const {
					data: { name, department_type,category_id },
				} = res;

				departmentDetailsFormik.setFieldValue(UNIVERSITY.FORMIK.NAME, name);
				departmentDetailsFormik.setFieldValue("department_type", department_type);
				departmentDetailsFormik.setFieldValue("category", String(category_id));


			});
		} else {
			departmentDetailsFormik.resetForm();
		}
	}, [showModal]);

	// const handleAddBatch=()=>{
	// 	const array=departmentDetailsFormik.values.batches
	// 	departmentDetailsFormik.setFieldValue(UNIVERSITY.FORMIK.BATCHES, [...array, { startYear: "", endYear: "" }])
	// }


	const departmentDetailsFormik = useFormik({
		initialValues: {
			name: "",
			department_type:1,
			category:"0"
			// batches:[ { startYear: "", endYear: "" }]
		},
		enableReinitialize: true,
		onSubmit: (values) => {
			console.log(values)
			const payload={
				name:values.name,
				department_type:Number(values.department_type),
				// category_id:values.category
			}
			toast.promise(
				selectedDepartment.editOrAdd === INSTRUCTOR.REDUX.ADD
					? addDepartment(Number(id), payload)
					: editDepartment(Number(id), selectedDepartment.id, payload),
				{
					pending: {
						render() {
							return selectedDepartment.editOrAdd === INSTRUCTOR.REDUX.ADD
								? MESSAGES.DEPARTMENT.ADD
								: MESSAGES.DEPARTMENT.EDIT;
						},
					},
					success: {
						render() {
							departmentDetailsFormik.resetForm();
							handleClose();

							return selectedDepartment.editOrAdd === INSTRUCTOR.REDUX.ADD
								? MESSAGES.DEPARTMENT.SUCCESS
								: MESSAGES.DEPARTMENT.EDITED;
						},
					},
					error: {
						render() {
							return selectedDepartment.editOrAdd === INSTRUCTOR.REDUX.ADD
								? MESSAGES.DEPARTMENT.INVALID
								: MESSAGES.DEPARTMENT.NOTEDITED;
						},
					},
				}
			);
		},

		validationSchema: DepartmentDetailsSchema,
	});
	const handleAddDepartment = (e: React.SyntheticEvent<HTMLFormElement>) => {
		e.preventDefault();

		departmentDetailsFormik.submitForm();
	};
	// const removeBatch=(index:any)=>{
	// 	if(index!==0){
	// 		const na=departmentDetailsFormik.values.batches.filter((batch, inn)=>{
	// 			return index!==inn;
	// 		})
	// 		departmentDetailsFormik.setFieldValue(UNIVERSITY.FORMIK.BATCHES, na)
	// 	}

	// }
	return (
		<Modal
			show={showModal}
			onHide={() => {
				handleClose();
			}}
		>
			<div>
				<Modal.Header closeButton>
					<Modal.Title>
						{selectedDepartment.editOrAdd === INSTRUCTOR.REDUX.EDIT
							? "Edit Department"
							: "Add New Department"}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={handleAddDepartment}>
						
						<div className={styles.fieldStyle}>
							<Form.Group className="from-group" controlId="formFirstName">
								<Form.Label>Name</Form.Label>
								<Form.Control
									type="text"
									placeholder="Name"
									value={departmentDetailsFormik.values.name}
									name={UNIVERSITY.FORMIK.NAME}
									onChange={departmentDetailsFormik.handleChange}
								/>
							</Form.Group>
							{departmentDetailsFormik.errors.name &&
              departmentDetailsFormik.touched.name ? (
									<span className={commonstyles.error}>
										{departmentDetailsFormik.errors.name}
									</span>
								) : null}
							{/* <Button className={` ${styles.marginTop}`}  onClick={handleAddBatch}>Add Batch</Button> */}
						</div>
						<div className={styles.fieldStyle}>
							<Form.Group className="from-group" controlId="formUniversity">
								<Form.Label>Type</Form.Label>
								<Form.Control
									name={"department_type"}
									value={departmentDetailsFormik.values.department_type}
									as="select"
									onChange={departmentDetailsFormik.handleChange}
								>
									<option value={1} key={1}>
                    Degree
									</option>
									<option value={2} key={1}>
                    Diploma
									</option>
									
								</Form.Control>
							</Form.Group>
						</div>
						
						{/* {departmentDetailsFormik.values.batches.map((batch, index)=>{

							return <Form.Group key={index} className="from-group" controlId="formFirstName">
								<Form.Label>Start Year</Form.Label>
								<Button className={`${styles.marginBottom} ${styles.marginLeft} ${styles.marginTop}`} onClick={()=>{removeBatch(index)}}>Remove</Button>

								<Form.Control
									type="number"  onChange={departmentDetailsFormik.handleChange} name={`batches.${index}.startYear`} value={batch.startYear} min="1900" max="2099" step="1" 
								/>
								<Form.Label>End Year</Form.Label>
								<Form.Control
									type="number"  onChange={departmentDetailsFormik.handleChange} name={`batches.${index}.endYear`} value={batch.endYear} min="1900" max="2099" step="1"
								/>
							</Form.Group>
						})
						} */}
							
						<Button className={`${styles.buttonss} mt-3`} type="submit">
							{selectedDepartment.editOrAdd === INSTRUCTOR.REDUX.ADD
								? INSTRUCTOR.ADD
								: INSTRUCTOR.EDIT}
						</Button>
					</Form>
				</Modal.Body>
			</div>
		</Modal>
	);
};

export default AddOrEditDepartment;
