import * as Yup from "yup";
import { YUP } from "../../../utils/validations";

const DepartmentDetailsSchema = Yup.object().shape({
	name: Yup
		.string()
		.required(YUP.REQUIRED)
});
const StorySchema = Yup.object().shape({
	Title:Yup.string().required(),
	// image: Yup.mixed().required("Required"),
	// thumbnail: Yup.mixed().required("Required"),
});

const BannerSchema = Yup.object().shape({
		title:Yup.string().required(),
	 	file: Yup.mixed().required("Required"),

});
const SemesterSchema = Yup.object().shape({
	start_date:Yup.string().required("Required"),
	end_date:Yup.string().required("Required"),
	name:Yup.string().required("Required")

});
const StoryEditSchema = Yup.object().shape({
	Title:Yup.string().required(),
		// image: Yup.mixed().required("Required"),
	// thumbnail: Yup.mixed().required("Required"),
});

const UniversityDetailsSchema = Yup.object().shape({
	name: Yup
		.string()
		.required(YUP.REQUIRED),
		device_login_limit:Yup
		.number()
		.required(YUP.REQUIRED).min(1,"must be greater than or equal to 1")
});


export  {DepartmentDetailsSchema,StorySchema,UniversityDetailsSchema,StoryEditSchema,BannerSchema,SemesterSchema};