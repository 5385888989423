import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Modal, Form, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { addStory } from "../api";
import { StorySchema } from "../validations";
import AddImage from "./UploadImageModal";

const AddOrEditStory = ({
  showModal,
  handleClose,
}: {
  showModal: boolean;
  handleClose: () => void;
}) => {
  const { id } = useParams();

  const [uploadedImages, setUploadedImages] = useState<File[]>([]);
  const [croppedImage, setCroppedImage] = useState<File | null>(null);
  const [croppedImageThumbnail, setCroppedImageThumbnail] =
    useState<File | null>(null);
  const [showModalImage, setShowModalImage] = useState<boolean>(false);
  const [showModalImageThumbnail, setShowModalImageThumbnail] =
    useState<boolean>(false);

  useEffect(() => {
    departmentDetailsFormik.resetForm();
    setUploadedImages([]);
    setCroppedImage(null);
    setCroppedImageThumbnail(null);
  }, [showModal]);

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files) {
      const files = Array.from(event.currentTarget.files);
      const validImages = files.filter((file) =>
        file.type.startsWith("image/")
      );
      if (validImages.length > 0) {
        setUploadedImages((prev) => [...prev, ...validImages]);
      } else {
        toast.error("Please upload valid image files.");
      }
    }
  };

  const handleCloseImage = () => setShowModalImage(false);
  const handleCloseImageThumbnail = () => setShowModalImageThumbnail(false);

  const departmentDetailsFormik = useFormik({
    initialValues: {
      Title: "",
      image: null,
      thumbnail: null,
    },
    validationSchema: StorySchema,
    onSubmit: (values) => {
      const formData = new FormData();
      const fields = {
        title: values.Title,
        university_id: Number(id),
        source: uploadedImages,
        thumbnail: croppedImageThumbnail,
        media_type: 1,
      };
      Object.entries(fields).forEach(([key, value]) => {
        if (value !== null) {
          if (Array.isArray(value)) {
            value.forEach((file) => formData.append(key, file));
          } else {
            formData.append(
              key,
              value instanceof File ? value : value.toString()
            );
          }
        }
      });

      toast.promise(addStory(formData), {
        pending: {
          render() {
            return "Adding Story";
          },
        },
        success: {
          render() {
            departmentDetailsFormik.resetForm();
            handleClose();
            return "Successfully Added Story";
          },
        },
        error: {
          render() {
            return "Failed to add story";
          },
        },
      });
    },
  });

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      centered
      className="custom-modal"
    >
      <div>
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold">Add Story</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={departmentDetailsFormik.handleSubmit}>
            <div className="mb-4">
              <Form.Group controlId="formStoryTitle">
                <Form.Label className="fw-semibold">Title</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Story Title"
                  value={departmentDetailsFormik.values.Title}
                  name="Title"
                  onChange={departmentDetailsFormik.handleChange}
                  onBlur={departmentDetailsFormik.handleBlur}
                  className="form-control shadow-sm"
                />
                {departmentDetailsFormik.errors.Title &&
                  departmentDetailsFormik.touched.Title && (
                    <small className="text-danger">
                      {departmentDetailsFormik.errors.Title}
                    </small>
                  )}
              </Form.Group>
            </div>
            <div className="mb-4">
              <Form.Group controlId="formStoryImages">
                <Form.Label className="fw-semibold">
                  Upload Story Images
                </Form.Label>
                <Form.Control
                  type="file"
                  multiple
                  accept="image/*"
                  onChange={handleImageUpload}
                  className="form-control shadow-sm"
                />
                {uploadedImages.length > 0 && (
                  <div className="mt-3 d-flex flex-wrap">
                    {uploadedImages.map((image, index) => (
                      <img
                        key={index}
                        src={URL.createObjectURL(image)}
                        alt={`story-${index}`}
                        className="rounded me-2 mb-2 shadow-sm"
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                        }}
                      />
                    ))}
                  </div>
                )}
              </Form.Group>
            </div>
            <div className="mb-4">
              <Form.Group controlId="formStoryThumbnail">
                <Form.Label className="fw-semibold">Story Thumbnail</Form.Label>
                <Button
                  onClick={() => setShowModalImageThumbnail(true)}
                  className="btn btn-secondary w-100 mt-2 shadow-sm"
                >
                  {croppedImageThumbnail
                    ? "Update Story Thumbnail"
                    : "Upload Story Thumbnail"}
                </Button>
                {croppedImageThumbnail && (
                  <div className="mt-3 d-flex justify-content-center">
                    <img
                      src={URL.createObjectURL(croppedImageThumbnail)}
                      alt="Thumbnail"
                      className="rounded shadow"
                      style={{
                        width: "150px",
                        height: "150px",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                )}
              </Form.Group>
            </div>
            <div className="text-center">
              <Button
                type="submit"
                className="btn btn-primary w-50 shadow-sm fw-bold"
              >
                Add Story
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </div>
      <AddImage
        croppedImage={croppedImage}
        setCroppedImage={setCroppedImage}
        showModal={showModalImage}
        handleClose={handleCloseImage}
      />
      <AddImage
        croppedImage={croppedImageThumbnail}
        setCroppedImage={setCroppedImageThumbnail}
        showModal={showModalImageThumbnail}
        handleClose={handleCloseImageThumbnail}
      />
    </Modal>
  );
};

export default AddOrEditStory;
