import React, { useEffect, useState } from "react";
import { Button, Form, Modal, Spinner, Toast } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { AddStudentProp } from "./interface";
import detailstyle from "../styles/detail.module.css";
import { addCourse, checkInstallments, courseListWithoutPagination, getChapter, getPlans } from "../api";
import { ChapterInfo, CourseDeatails } from "../../course/api/interface";
import { AddCourseSchema } from "../validations";
import { useFormik } from "formik";
import commonstyles from "../../instructor/styles/common.module.css";
import { AddCustomCoursePayload } from "../api/interface";
import { MESSAGES } from "../../../utils/messages";
import { STUDENT } from "../../../utils/student";
import { courseList,course as specificCourse } from "../../course/api";
import moment from "moment"


const AddCourse = ({ showModal, handleClose }: any) => {
	const { id } = useParams();
	const [courses, setCourses] = useState<CourseDeatails[]>([]);
	const [chapters, setChapters] = useState<ChapterInfo[]>([]);
	const [installments,setInstallments]=useState<any>([])
	const [plans,setPlans]=useState<any>([])
	const [installmentLoader,setInstallmentLoader]=useState<boolean>(true)
	const [installmentLoaderVisible,setInstallmentLoaderVisible]=useState<boolean>(false)

	const [course,setCourse]=useState<number>(0)
	const [downpayment,setDownpayment]=useState<number>(0)
	const [subscriptionTypes,setSubscriptionType]=useState<number>(0)


	useEffect(() => {
	if(course===0)
	{
		setSubscriptionType(0)
		setPlans([])
	}
	else{
		getPlans(course).then((res)=>{
			setPlans(res.data);
		})
specificCourse(`${course}`).then((res)=>{
	setSubscriptionType(Number(res.data.course_type))
})
	}
	}, [course])
	


	const CourseDetailsFormik = useFormik({
		initialValues: {
			amount: 0,
			paymentLink: "",
			course: 0,
			chapter: 0,
			payment_type:"full",
			installments:0,
			down_payment:0,
			subscription_type:0,
			plan_type:0,
			// data:installments
			// dataa:installments
			data:[]
		},
		enableReinitialize: true,
		onSubmit: (values) => {
			const payload: AddCustomCoursePayload = {
				amount: values.amount,
				student_id: Number(id),
				payment_type:values.payment_type,
				data:installments,
				subcription_type:values.subscription_type
			};
			if (Number(values.chapter) === 0) {
				payload.course_id = Number(values.course);
			} else {
				payload.course_id = Number(values.course);
				payload.chapter_id = Number(values.chapter);
			}
			if (values.paymentLink !== "") payload.payment_link = values.paymentLink;
			toast.promise(addCourse(payload), {
				pending: {
					render() {
						return MESSAGES.STUDENT.ADDCOURSE;
					},
				},
				success: {
					render() {
						CourseDetailsFormik.resetForm();
						handleClose();
						return MESSAGES.STUDENT.ADDEDCOURSE;
					},
				},
				error: {
					render({data}:any) {
						return data.data.message || "Failed"
						// return selectedUniversity.editOrAdd === INSTRUCTOR.REDUX.ADD
						// 	? MESSAGES.UNIVERSITY.INVALID
						// 	: MESSAGES.UNIVERSITY.NOTEDITED;
					},
				},
			});
		},

		validationSchema: AddCourseSchema,
	});

	const handleCourseChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		getChapters(Number(e.target.value));
	};
	useEffect(() => {
	

	CourseDetailsFormik.setFieldValue("data",installments)
	}, [installments])
	
	const getChapters = (courseId: number) => {
		if (courseId === 0) {
			setChapters([]);
		} else {
			getChapter(courseId).then((res) => {
				setChapters(res.data);
			});
		}
	};

	const refreshAll=()=>{
		setInstallmentLoaderVisible(true)
		setInstallmentLoader(true)
		console.log(Number(CourseDetailsFormik.values.installments),Number(CourseDetailsFormik.values.down_payment),"print")
		if(Number(CourseDetailsFormik.values.amount)&&Number(CourseDetailsFormik.values.course)&&Number(CourseDetailsFormik.values.down_payment<=Number(CourseDetailsFormik.values.amount)))
		{
			checkInstallments(Number(CourseDetailsFormik.values.course),Number(CourseDetailsFormik.values.amount),Number(CourseDetailsFormik.values.installments),Number(CourseDetailsFormik.values.down_payment),Number(id),Number(CourseDetailsFormik.values.plan_type)).then((res)=>{
				setInstallments(res.data)
				setDownpayment(res.down_payment)
				setInstallmentLoader(false)
				setInstallmentLoaderVisible(false)

			}).catch((err)=>{
				console.log(err)
				setInstallments([])
				toast.error(err.data.message)
				setInstallmentLoader(false)
				setInstallmentLoaderVisible(false)


			})


			// toast.promise(checkInstallments(Number(CourseDetailsFormik.values.course),Number(CourseDetailsFormik.values.amount),Number(CourseDetailsFormik.values.installments),Number(CourseDetailsFormik.values.down_payment)), {
			// 	pending: {
			// 		render() {
			// 			return "Calculating Installments";
			// 		},
			// 	},
			// 	success: {
			// 		render({data}) {
			// 			console.log(data,"ins")
			// 			setInstallments(data.data)
			// 			 return data.Message || "Installment List Created"
			// 		},
			// 	},
			// 	error: {
			// 		render({data}:any) {
			// 			setInstallments([])
			// 			 return data.data.message || "Installments Not Available" ;
			// 		},
			// 	},
			// });
			// checkInstallments(Number(CourseDetailsFormik.values.course),Number(CourseDetailsFormik.values.amount),Number(CourseDetailsFormik.values.installments),Number(CourseDetailsFormik.values.down_payment)).then((res)=>{
			// 	console.log(res,"resss")
			// })
		}
		else if(Number(CourseDetailsFormik.values.down_payment>Number(CourseDetailsFormik.values.amount))){
			setInstallments([])
			setInstallmentLoader(false)

		}
	 
	}


	useEffect(() => {
	CourseDetailsFormik.setFieldValue("down_payment",0)
	CourseDetailsFormik.setFieldValue("installments",'')
	setDownpayment(0)
	setInstallments([])
	}, [CourseDetailsFormik.values.amount])
	
	useEffect(() => {
		courseList(undefined,undefined,undefined,false,Number(id)).then((res) => {
			setCourses(res.data);
		});
	}, []);
	useEffect(() => {
		setInstallmentLoader(true)
		CourseDetailsFormik.setFieldValue("down_payment",0)
		CourseDetailsFormik.setFieldValue("installments",'')
		setDownpayment(0)
		setInstallments([])
	}, [CourseDetailsFormik.values.plan_type]);
	useEffect(() => {
		setInstallmentLoader(true);
		CourseDetailsFormik.setFieldValue("down_payment",0)
		CourseDetailsFormik.setFieldValue("installments",'')
		setDownpayment(0)
		setInstallments([])
	}, [CourseDetailsFormik.values.payment_type]);
	const closeModal = () => {
		handleClose();
		CourseDetailsFormik.resetForm();
	};

	return (
		<Modal
			show={showModal}
			onHide={closeModal}
			id="exampleModal"
			aria-labelledby="exampleModalLabel"
			aria-hidden="true"
		>
			<Modal.Header closeButton>
				<Modal.Title onClick={()=>console.log(CourseDetailsFormik.values,CourseDetailsFormik.errors)}>Add Course</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className={detailstyle["add-course"]}>
					<Form.Group>
						<Form.Label>Course</Form.Label>
						<Form.Select
							value={CourseDetailsFormik.values.course}
							name={STUDENT.FORMIK.COURSE}
							onChange={(e) => {
								CourseDetailsFormik.handleChange(e);
								handleCourseChange(e);
								setCourse(Number(e.target.value));
							}}
						>
							<option value={0} key={0}>
                Choose Course
							</option>
							{courses.map((course) => (
								<option value={course.id} key={course.id}>
									{course.name}
								</option>
							))}
						</Form.Select>
						{CourseDetailsFormik.errors.course &&
            CourseDetailsFormik.touched.course ? (
								<p className={commonstyles.error}>
									{CourseDetailsFormik.errors.course}
								</p>
							) : (
								<span></span>
							)}
						<Form.Label>Chapter</Form.Label>

						<Form.Select
							name={STUDENT.FORMIK.CHAPTER}
							onChange={CourseDetailsFormik.handleChange}
						>
							<option value={0} key={0}>
                Choose Chapter
							</option>
							{chapters.map((chapter) => (
								<option value={chapter.id} key={chapter.id}>
									{chapter.name}
								</option>
							))}
						</Form.Select>
					</Form.Group>
					<Form.Group>
						<Form.Label>Subscription Type</Form.Label>
						<Form.Select
							value={CourseDetailsFormik.values.subscription_type}
							name={"subscription_type"}
							onChange={(e) => {
								CourseDetailsFormik.handleChange(e);
							}}
						>
							<option value={0} key={0}>
                Choose Subscription Type
							</option>
							{(subscriptionTypes===3||subscriptionTypes===1)&&<option value={1} key={1}>
									{"Online"}
								</option>}
								{(subscriptionTypes===3||subscriptionTypes===2)&&<option value={2} key={2}>
								{"Offline"}
							</option>}
						</Form.Select>
						{CourseDetailsFormik.errors.subscription_type &&
            CourseDetailsFormik.touched.subscription_type ? (
								<p className={commonstyles.error}>
									{CourseDetailsFormik.errors.subscription_type}
								</p>
							) : (
								<span></span>
							)}
					
					</Form.Group>
					<Form.Label>Amount</Form.Label>

					<div className="form-group">
						<Form.Control
							onKeyDown={(e) => {
								if (e.key === "e" || e.key === "E") {
									e.preventDefault();
								}
							}}
							name={STUDENT.FORMIK.AMOUNT}
							type="number"
							placeholder="Amount"
							onChange={CourseDetailsFormik.handleChange}
						/>
						{CourseDetailsFormik.errors.amount &&
            CourseDetailsFormik.touched.amount ? (
								<span className={commonstyles.error}>
									{CourseDetailsFormik.errors.amount}
								</span>
							) : (
								<span></span>
							)}
					</div>
					<div className="form-group">
						<Form.Label>Payment Link</Form.Label>

						<Form.Control
							name={STUDENT.FORMIK.PAYMENTLINK}
							type="text"
							placeholder="Payment Link"
							onChange={CourseDetailsFormik.handleChange}
						/>
						{CourseDetailsFormik.errors.paymentLink &&
            CourseDetailsFormik.touched.paymentLink ? (
								<span className={commonstyles.error}>
									{CourseDetailsFormik.errors.paymentLink}
								</span>
							) : (
								<span></span>
							)}
					</div>
					{Number(CourseDetailsFormik.values.course)!==0&&<><Form.Group>
					<Form.Label>Payment Type</Form.Label>

<Form.Select
	name={"payment_type"}
	onChange={CourseDetailsFormik.handleChange}
	value={CourseDetailsFormik.values.payment_type}
>
	
	
		<option selected value={"full"} >
			{"Full"}
		</option>
		<option value={"installment"}>
			{"Installment"}
		</option>
	
</Form.Select>
</Form.Group>
{/* {CourseDetailsFormik.values.payment_type==="installment"&&Number(CourseDetailsFormik.values.amount)>0&&<Form.Group>
					<Form.Label>No. of Installments</Form.Label>

<Form.Control
type="number"

	name={"installments"}
	onChange={CourseDetailsFormik.handleChange}
	value={Number(CourseDetailsFormik.values.installments)}
>
	
	
		
	
</Form.Control>
</Form.Group>} */}
{/* {CourseDetailsFormik.values.payment_type==="installment"&&Number(CourseDetailsFormik.values.amount)>0&&<Form.Group>
					<Form.Label>Down payment</Form.Label>

<Form.Control
type="number"
max={Number(CourseDetailsFormik.values.amount)}
	name={"down_payment"}
	onChange={CourseDetailsFormik.handleChange}
	value={CourseDetailsFormik.values.down_payment}
>
	
	
		
	
</Form.Control>
</Form.Group>} */}
{CourseDetailsFormik.values.payment_type==="installment"&&Number(CourseDetailsFormik.values.amount)>0&&<>
	<Form.Label>Instalment Plan</Form.Label>
<Form.Select
	name={"plan_type"}
	onChange={CourseDetailsFormik.handleChange}
	value={CourseDetailsFormik.values.plan_type}
>
	
	
		<option selected value={0} >
			{"Select Plan"}
		</option>
		{
			plans.map((plan:any)=>{
				return <option value={plan.id}>{plan.title}</option>
			})
		}
	
</Form.Select></>}
{CourseDetailsFormik.values.payment_type==="installment"&&Number(CourseDetailsFormik.values.amount)>0&&Number(CourseDetailsFormik.values.plan_type)>0&&<Button onClick={refreshAll}>Calculate Installment</Button>}
{CourseDetailsFormik.values.payment_type==="installment"&&Number(CourseDetailsFormik.values.amount)>0&&(!installmentLoader?<>
Installment Plan
<p>Downpayment- {downpayment} KD</p>
<p>Amount - Due Date</p> 
{installments.map((ins:any)=>{
	return <p>{ins.amount}KD - {moment(ins.due_date).format("MMM DD, YYYY")}</p>
})}
</>: installmentLoaderVisible&&<div className="d-flex justify-content-center">
            <Spinner animation="border" role="status"></Spinner>
          </div>)}</>}
{CourseDetailsFormik.errors.data &&
            CourseDetailsFormik.touched.data &&typeof CourseDetailsFormik.errors.data === 'string' ? (
								<span className={commonstyles.error}>
									{CourseDetailsFormik.errors.data}
								</span>
							) : (
								<span></span>
							)}

					<div className="form-group">
						<Button onClick={CourseDetailsFormik.submitForm}>Add</Button>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default AddCourse;
