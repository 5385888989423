
import { Form, Button, Modal } from "react-bootstrap";
import styles from "../../styles/instructor.module.css";
import { AddImageProp } from "./interface";
import ImageUploader from "../../../../components/ImageUpload/ImageUploader";

const AddImage = ({ showModal, handleClose,croppedImage,setCroppedImage }: AddImageProp) => {



	return (
		<Modal
			show={showModal}
			onHide={() => {
				handleClose();
			}}
		>
			<div className={`${styles["modalHeight"]} ${styles["add-lead-from"]}`}>
				<Modal.Header closeButton>
					<Modal.Title>
						{"Upload Image"}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body >
					<Form onSubmit={()=>{}}>
                    <div className={styles.fieldStyle}>
					<ImageUploader handleClose={handleClose} setCroppedImage={setCroppedImage}/>
					</div>
						</Form>
				</Modal.Body>
			</div>

		</Modal>
	);
};

export default AddImage;
