import { axios } from "../../../lib/axios";
import { API_ROUTES } from "../../../utils/api_routes";
import { GraphResponse, Transactions, TransactionsPayload } from "./interfaces";


export const transactionsList = (query: TransactionsPayload,uni?:Number,payment_method?:Number): Promise<Transactions> => {
   
            
	const param:TransactionsPayload={per_page:query.per_page, page:query.page};
	if(query.month!==0)
	{
		param.month=query.month

	}
	if(query.sort_by_date!=="")
	{
		param.sort_by_date=query.sort_by_date
	}
	if(query.search!=="")
	{
		param.search=query.search
	}
	return axios.get(API_ROUTES.TRANSACTIONS.GET_TRANSACTIONS, { params: {...param,...(query.transaction_status!=="Select"&&{transaction_status:query.transaction_status}),...(uni!==0&&{university_id:uni}),...(payment_method!==0&&{payment_method:payment_method})} });

};

export const transactionGraph=(id:number):Promise<GraphResponse>=>{
	const par:any={}
	if(id!==0)
	{
		par.user_id=id
	}
	return axios.get(API_ROUTES.DASHBOARD.GET_TRANSACTION_GRAPH, {params:par});

}