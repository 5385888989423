import * as Yup from "yup";
import { isValidPhoneNumber, isPossiblePhoneNumber } from "react-phone-number-input";

import { YUP } from "../../../utils/validations";

const commonSchema = Yup.object().shape({
	firstName: Yup.string().required(YUP.REQUIRED),
	lastName: Yup.string().required(YUP.REQUIRED),
	phoneNumber: Yup
		.string()
		.required(YUP.REQUIRED)
		.test("validate-number", function (value) {
			if (!isValidPhoneNumber(value) || !isPossiblePhoneNumber(value))
			 return this.createError({ message: YUP.PHONENUMBER, path: this.path });
			return true;
		}),
	password:  Yup.string().required(YUP.REQUIRED).test(YUP.PASSWORD_VALIDATION, YUP.PASSWORD, function(password){
		if (password.length < 6) {
			return false;
		  }
	
		  const lowercaseRegex = /[a-z]/;
		  const digitRegex = /\d/;
		  const symbolRegex = /[!@#$%^&*(),.?":{}|<>]/;
		
		  if (!lowercaseRegex.test(password)) {
			return false; 
		  }
		
		  if (!digitRegex.test(password)) {
			return false; 
		  }
		
		  if (!symbolRegex.test(password)) {
			return false; 
		  }
		
		  // All checks passed, the password is valid
		  return true;

	}),
});

export default commonSchema;