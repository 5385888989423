import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { Dropdown, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEllipsisH,
  faEye,
  faPen,
  faPencil,
  faPlus,
  faRemove,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

import Layout from "../../../../components/Layout/index";
import commonStyles from "../../../common/styles/common.module.css";
import Pagination from "../../../../components/Pagination";
import { PaginationInterface } from "../../../../components/Pagination/interfaces";
import { INSTRUCTOR } from "../../../../utils/instructor";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { departmentModal } from "../../../../redux/reducers/department";
import { MESSAGES } from "../../../../utils/messages";
import { getDevices, studentList } from "../../../student/api";
import { Student } from "../../../student/api/interface";
// import DeleteSegment from "../../modals/DeleteSegment";
import { segmentationModal } from "../../../../redux/reducers/segmentation";
import { useDispatch } from "react-redux";
import RemoveDevice from "../../modals/RemoveDevice";

const Devices = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const [story, setStory] = useState<any>([]);
  const [image, setImage] = useState<string>("");
  const [pagination, setPagination] = useState<PaginationInterface>({
    page: 1,
    limit: 10,
    count: 0,
    totalPages: 0,
  });
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showModalDisplay, setShowModalDisplay] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(true);

  const selectedDepartment = useAppSelector((state) => state.departmentReducer);

  const refresh = () => {
    setLoading(true);
    const query = {
      per_page: pagination.limit,
      page: pagination.page,
    };

    getDevices(query, Number(id)).then((res) => {
      const {
        data,
      } = res;
      setStory(data);
      console.log(data);
      setLoading(false);
    });
  };
  useEffect(() => {
    refresh();
  }, []);
  useEffect(() => {
    refresh();
  }, [pagination.page]);

  const handleClose = () => {
    setShowModal(false);
    if (selectedDepartment.editOrAdd === INSTRUCTOR.REDUX.ADD) {
      if (pagination.page === 1) {
        refresh();
      } else {
        setPagination({ page: 1, limit: 10, count: 0, totalPages: 0 });
      }
    } else {
      refresh();
    }
  };
  const handleEditClose = () => {
    setShowEditModal(false);
    if (selectedDepartment.editOrAdd === INSTRUCTOR.REDUX.ADD) {
      if (pagination.page === 1) {
        refresh();
      } else {
        setPagination({ page: 1, limit: 10, count: 0, totalPages: 0 });
      }
    } else {
      refresh();
    }
  };
  const handleCloseDisplay = () => {
    setShowModalDisplay(false);

    refresh();
  };


  const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  const dispatch=useDispatch()

  const handleRemoveSegment = (id: Number) => {
      dispatch(
        segmentationModal({
          id: id,
          editOrAdd: INSTRUCTOR.REDUX.EDIT,
        })
      );
      setShowRemoveModal(true);
    };
    const handleEditStory = (id: Number) => {
      dispatch(
        segmentationModal({
          id: id,
          editOrAdd: INSTRUCTOR.REDUX.EDIT,
        })
      );
      setShowEditModal(true);
    };
    const handleRemoveClose = () => {
      setShowRemoveModal(false);
      if (pagination.page === 1) {
        refresh();
      } else {
        setPagination({ page: 1, limit: 10, count: 0, totalPages: 0 });
      }
    };

  return (
    <Layout>
      <div className={commonStyles["outtre-pad"]}>
        <>
          <span className="instr_manager d-flex justify-content-between align-items-center">
            <h3>Student Management / Devices</h3>

            <div className={commonStyles["heaing-filter"]}>
              {/* <div className={commonStyles["filter-left"]}>
                  <span>
                    Filter:
                    <select>
                      <option>Location</option>
                      <option>This Week</option>
                    </select>
                  </span>
                  <span>
                    Filter:
                    <select>
                      <option>Price</option>
                      <option>This Week</option>
                    </select>
                  </span>
                  <span>
                    Filter:
                    <select>
                      <option>Department</option>
                      <option>This Week</option>
                    </select>
                  </span>
                  <span>
                    Filter:
                    <select>
                      <option>This Week</option>
                      <option>This Week</option>
                    </select>
                  </span>
                </div> */}
            </div>
          </span>
        </>
        <div className={commonStyles["heaing-filter"]}>
          <h3></h3>
          {/* <div className={commonStyles["filter-left"]}>
						<span>Filter: <select>
							<option>Location</option>
							<option>This Week</option>
						</select></span>
						<span>Filter: <select>
							<option>Price</option>
							<option>This Week</option>
						</select></span>
						<span>Filter: <select>
							<option>Department</option>
							<option>This Week</option>
						</select></span>
						<span>Filter: <select>
							<option>This Week</option>
							<option>This Week</option>
						</select></span>
					</div> */}
        </div>
        {!loading ? (
          <div className={commonStyles["table-data"]}>
            <div className={`${commonStyles["property-head"]} pb-1`}>
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  {/* <button
									className={`nav-link ${commonStyles.activeTab}`}
									id="pills-home-tab"
									data-bs-toggle="pill"
									data-bs-target="#pills-home"
									type="button"
									role="tab"
									aria-controls="pills-home"
									aria-selected="true"
								>
                  All
								</button> */}
                </li>
              </ul>
              <div className={commonStyles["filter-pro"]}>
                {/* <button
                  onClick={() => {
                    setShowModal(true);
                  }}
                  className={commonStyles["add-lead"]}
                >
                  <FontAwesomeIcon icon={faPlus} /> Add New Story
                </button> */}
              </div>
            </div>
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <div style={{ overflowX: "auto" }}>
                  <table>
                    <thead>
                      <tr>
                        <th>MAC Address</th>
                        <th>Operating System</th>
                        {/* <th>Last Login</th> */}
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {story?.map((item:any) => (
                        <tr key={item.id}>
                          <td>{item?.mac_address}</td>
                          <td>{item?.operating_system}</td>
                          {/* <td>{item?.last_login}</td> */}
                          <td>
                          <button style={{padding:"8px"}}
                  onClick={() => {
                    handleRemoveSegment(item.id)
                    
                  }}
                  className={commonStyles["add-lead"]}
                >
                  <FontAwesomeIcon icon={faRemove} /> Remove
                </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" role="status"></Spinner>
          </div>
        )}
      </div>

      <Pagination
        paginationState={pagination}
        setPaginationState={setPagination}
      />
      

     
         <RemoveDevice
        showModal={showRemoveModal}
        handleClose={handleRemoveClose}
      />
    </Layout>
  );
};

export default Devices;
