import { axios } from "../../../lib/axios";
import { API_ROUTES } from "../../../utils/api_routes";
import { Pagination } from "../../common/interface";
import { AddInvoice, INVOICE_RESPONSE } from "./interface";


export const getSetting = (): Promise<any> => {
	return axios.get("/admin/settings");
};

export const addSetting=(details:any,id:any)=>{
	return axios.put(`/admin/settings/${id}`, {...details});

}