import React from "react";
// import { useFormik } from "formik";
// import { toast } from "react-toastify";
import { Modal, Form, Button } from "react-bootstrap";
import { toast } from "react-toastify";
 
// import { useParams } from "react-router-dom";
// import { useAppSelector } from "../../../redux/hooks";
// import { INSTRUCTOR } from "../../../utils/instructor";
// import { addDepartment, editDepartment, getDepartment } from "../api";
// import DepartmentDetailsSchema from "../validations";
import styles from "../../instructor/styles/instructor.module.css";
// import commonstyles from "../../instructor/styles/common.module.css";
// import { UNIVERSITY } from "../../../utils/university";
// import { MESSAGES } from "../../../utils/messages";
import { useAppSelector } from "../../../redux/hooks"; 
import { MESSAGES } from "../../../utils/messages";
import {  removeDevice } from "../api";

const RemoveDevice = ({ showModal, handleClose }: any) => {
	const selectedSegment = useAppSelector((state) => state.segmentationReducer);
	const handleRemoveSegment=()=>{
		toast.promise(removeDevice(selectedSegment.id as number), {
			pending: {
				render() {
					return "Deleting";
				},
			},
			success: {
				render({}) {
					handleClose()
					return "Deleted";
				},
			},
			error: {
				render() {
					return "Failed";
				},
			},
		});


	}
	// const selectedDepartment = useAppSelector((state) => state.departmentReducer);
	// const { id } = useParams();

	// useEffect(() => {
	// 	if (selectedDepartment.editOrAdd === INSTRUCTOR.REDUX.EDIT) {
	// 		getDepartment(Number(selectedDepartment.id)).then((res) => {
	// 			const {
	// 				data: { name },
	// 			} = res;

	// 			departmentDetailsFormik.setFieldValue(UNIVERSITY.FORMIK.NAME, name);
	// 		});
	// 	} else {
	// 		departmentDetailsFormik.resetForm();
	// 	}
	// }, [showModal]);

	// const departmentDetailsFormik = useFormik({
	// 	initialValues: {
	// 		name: "",
	// 	},
	// 	enableReinitialize: true,
	// 	onSubmit: (values) => {
	// 		toast.promise(
	// 			selectedDepartment.editOrAdd === INSTRUCTOR.REDUX.ADD
	// 				? addDepartment(Number(id), values.name)
	// 				: editDepartment(Number(id), selectedDepartment.id, values.name),
	// 			{
	// 				pending: {
	// 					render() {
	// 						return selectedDepartment.editOrAdd === INSTRUCTOR.REDUX.ADD
	// 							? MESSAGES.DEPARTMENT.ADD
	// 							: MESSAGES.DEPARTMENT.EDIT;
	// 					},
	// 				},
	// 				success: {
	// 					render() {
	// 						departmentDetailsFormik.resetForm();
	// 						handleClose();

	// 						return selectedDepartment.editOrAdd === INSTRUCTOR.REDUX.ADD
	// 							? MESSAGES.DEPARTMENT.SUCCESS
	// 							: MESSAGES.DEPARTMENT.EDITED;
	// 					},
	// 				},
	// 				error: {
	// 					render() {
	// 						return selectedDepartment.editOrAdd === INSTRUCTOR.REDUX.ADD
	// 							? MESSAGES.DEPARTMENT.INVALID
	// 							: MESSAGES.DEPARTMENT.NOTEDITED;
	// 					},
	// 				},
	// 			}
	// 		);
	// 	},

	// 	validationSchema: DepartmentDetailsSchema,
	// });
	// const handleAddDepartment = (e: React.SyntheticEvent<HTMLFormElement>) => {
	// 	e.preventDefault();

	// 	departmentDetailsFormik.submitForm();
	// };
	
	return (
		<Modal
			show={showModal}
			onHide={() => {
				handleClose();
			}}
		>
			<div>
				<Modal.Header closeButton>
					<Modal.Title>
						Delete Device
						{/* {selectedDepartment.editOrAdd === INSTRUCTOR.REDUX.EDIT
							? "Edit Department"
							: "Add N	ew Department"} */}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form >
						<div className={styles.deleteButtons}>
							<Button onClick={handleRemoveSegment} className={`${styles.buttonss} mt-3`} >
							Yes
							</Button>
							<Button onClick={handleClose} className={`${styles.buttonss} mt-3`} >
							No
							</Button>
						</div>
					
					</Form>
				</Modal.Body>
			</div>
		</Modal>
	)
	
};

export default RemoveDevice;
