import React, { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { Dropdown, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEllipsisH,
  faEye,
  faPen,
  faPencil,
  faPlus,
  faRemove,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

import Layout from "../../../../components/Layout/index";
import commonStyles from "../../../common/styles/common.module.css";
import { departmentList, getBanner, getInstallment, getSemesters, getStory, removeDepartment, toggleSemester } from "../../api";
import Pagination from "../../../../components/Pagination";
import { PaginationInterface } from "../../../../components/Pagination/interfaces";
import { AddOrEditDepartment } from "../../modals";
import { INSTRUCTOR } from "../../../../utils/instructor";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { departmentModal } from "../../../../redux/reducers/department";
import { MESSAGES } from "../../../../utils/messages";
import { Department as DepartmentInterface, Story } from "../../api/interface";
import { studentList } from "../../../student/api";
import { Student } from "../../../student/api/interface";
import AddOrEditStory from "../../modals/AddOrEditStory";
import DisplayStory from "../../modals/DisplayStory";
import DeleteSegment from "../../modals/DeleteSegment";
import { segmentationModal } from "../../../../redux/reducers/segmentation";
import { useDispatch } from "react-redux";
import EditStory from "../../modals/EditStory";
import DeleteBanner from "../../modals/DeleteBanner";
import DisplayBanner from "../../modals/DisplayBanner";
import AddOrEditBanner from "../../modals/AddOrEditBanner";
import EditBanner from "../../modals/EditBanner";
import AddOrEditSemester from "../../modals/AddOrEditSemester";
import DeleteSemester from "../../modals/DeleteSemester";
import EditSemester from "../../modals/EditSemester";
import AddOrEditInstalment from "../../modals/AddOrEditInstalment";
import DeleteInstallment from "../../modals/DeleteInstallment";

const Instalment = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const [story, setStory] = useState<any>([]);
  const [image, setImage] = useState<string>("");
  const [state,setState]=useState<number[]>([])
  const [pagination, setPagination] = useState<PaginationInterface>({
    page: 1,
    limit: 10,
    count: 0,
    totalPages: 0,
  });
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showInstalmentModal, setShowInstalmentModal] = useState<boolean>(false);

  const [showModalDisplay, setShowModalDisplay] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(true);

  const selectedDepartment = useAppSelector((state) => state.departmentReducer);

  const refresh = () => {
    setLoading(true);
    const query = {
      per_page: pagination.limit,
      page: pagination.page,
      university_id:id
    };

   getInstallment(query).then((res) => {
      const {
        paginate: { total, last_page },
        data,
      } = res;
      setPagination({ ...pagination, count: total, totalPages: last_page });
      setStory(data);
      setLoading(false);
    });
  };
  useEffect(() => {
    refresh();
  }, []);
  useEffect(() => {
    refresh();
  }, [pagination.page]);

  const handleClose = () => {
    setShowModal(false);
    if (selectedDepartment.editOrAdd === INSTRUCTOR.REDUX.ADD) {
      if (pagination.page === 1) {
        refresh();
      } else {
        setPagination({ page: 1, limit: 10, count: 0, totalPages: 0 });
      }
    } else {
      refresh();
    }
  };
  const handleInstalmentClose = () => {
    setShowInstalmentModal(false);
    if (selectedDepartment.editOrAdd === INSTRUCTOR.REDUX.ADD) {
      if (pagination.page === 1) {
        refresh();
      } else {
        setPagination({ page: 1, limit: 10, count: 0, totalPages: 0 });
      }
    } else {
      refresh();
    }
  };
  const handleEditClose = () => {
    setShowEditModal(false);
    if (selectedDepartment.editOrAdd === INSTRUCTOR.REDUX.ADD) {
      if (pagination.page === 1) {
        refresh();
      } else {
        setPagination({ page: 1, limit: 10, count: 0, totalPages: 0 });
      }
    } else {
      refresh();
    }
  };
  const handleCloseDisplay = () => {
    setShowModalDisplay(false);

    refresh();
  };


  const [showRemoveModal, setShowRemoveModal] = useState<boolean>(false);
  const [showEditModal, setShowEditModal] = useState<boolean>(false);

  const dispatch=useDispatch()

  const handleRemoveSegment = (id: Number) => {
      dispatch(
        segmentationModal({
          id: id,
          editOrAdd: INSTRUCTOR.REDUX.EDIT,
        })
      );
      setShowRemoveModal(true);
    };
    const handleEditStory = (id: Number) => {
      dispatch(
        segmentationModal({
          id: id,
          editOrAdd: INSTRUCTOR.REDUX.EDIT,
        })
      );
      setShowEditModal(true);
    };
    const handleRemoveClose = () => {
      setShowRemoveModal(false);
      if (pagination.page === 1) {
        refresh();
      } else {
        setPagination({ page: 1, limit: 10, count: 0, totalPages: 0 });
      }
    };
    const changeToggleStatus = (
      e: React.ChangeEvent<HTMLInputElement>,
      id: number,
      index:number
    ) => {
      const currentStatus = !e.target.checked;
      console.log(currentStatus);
      e.preventDefault();
  
      toast.promise(
        toggleSemester(!currentStatus === true ? 1 : 0, id as number),
        {
          pending: {
            render() {
              e.target.checked = currentStatus;
  
              return "Editing";
            },
          },
          success: {
            render() {
              e.target.checked = !currentStatus;
              const na=[...state];
              na[index]=na[index]===0?1:0;
              setState(na);
              return "Edited";
            },
          },
          error: {
            render() {
              e.target.checked = currentStatus;
              return "Failed";
            },
          },
        }
      );
    };

  return (
    <Layout>
      <div className={commonStyles["outtre-pad"]}>
        <>
          <span className="instr_manager d-flex justify-content-between align-items-center">
            <h3>University Management / Instalment Plans</h3>

            <div className={commonStyles["heaing-filter"]}>
              {/* <div className={commonStyles["filter-left"]}>
                  <span>
                    Filter:
                    <select>
                      <option>Location</option>
                      <option>This Week</option>
                    </select>
                  </span>
                  <span>
                    Filter:
                    <select>
                      <option>Price</option>
                      <option>This Week</option>
                    </select>
                  </span>
                  <span>
                    Filter:
                    <select>
                      <option>Department</option>
                      <option>This Week</option>
                    </select>
                  </span>
                  <span>
                    Filter:
                    <select>
                      <option>This Week</option>
                      <option>This Week</option>
                    </select>
                  </span>
                </div> */}
            </div>
          </span>
        </>
        <div className={commonStyles["heaing-filter"]}>
          <h3></h3>
          {/* <div className={commonStyles["filter-left"]}>
						<span>Filter: <select>
							<option>Location</option>
							<option>This Week</option>
						</select></span>
						<span>Filter: <select>
							<option>Price</option>
							<option>This Week</option>
						</select></span>
						<span>Filter: <select>
							<option>Department</option>
							<option>This Week</option>
						</select></span>
						<span>Filter: <select>
							<option>This Week</option>
							<option>This Week</option>
						</select></span>
					</div> */}
        </div>
        {!loading ? (
          <div className={commonStyles["table-data"]}>
            <div className={`${commonStyles["property-head"]} pb-1`}>
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  {/* <button
									className={`nav-link ${commonStyles.activeTab}`}
									id="pills-home-tab"
									data-bs-toggle="pill"
									data-bs-target="#pills-home"
									type="button"
									role="tab"
									aria-controls="pills-home"
									aria-selected="true"
								>
                  All
								</button> */}
                </li>
              </ul>
              <div className={commonStyles["filter-pro"]}>
                <button
                  onClick={() => {
                    dispatch(
                        segmentationModal({
                          id: Number(id) ,
                          editOrAdd: INSTRUCTOR.REDUX.ADD,
                        })
                      );
                    setShowInstalmentModal(true);
                  }}
                  className={commonStyles["add-lead"]}
                >
                  <FontAwesomeIcon icon={faPlus} /> Add New Instalment Plan
                </button>
              </div>
            </div>
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <div style={{ overflowX: "auto" }}>
                  <table>
                    <thead>
                      <tr>
                      <th>Title</th>
                      <th>Instalment Count</th>
                      <th>Downpayment</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {story?.map((item:any,index:any) => (
                        <tr key={item.id}>
      <td>{item?.title}</td>

                     <td>{item?.installment}</td>
                     <td>{item?.down_payment} %</td>

                          <td>
                               
                            <Dropdown
                            onClick={() => setImage(item.file)}
                              drop={"down-centered"}
                              className={commonStyles.tddropdown}
                            >
                              <Dropdown.Toggle>
                                <FontAwesomeIcon
                                  
                                  icon={faEllipsisH}
                                />
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() => {
                                    dispatch(
                                        segmentationModal({
                                          id: Number(item.id) ,
                                          editOrAdd: INSTRUCTOR.REDUX.EDIT,
                                        })
                                      );
                                    setShowInstalmentModal(true)}}
                                >
                                  <FontAwesomeIcon
                                    className={commonStyles.iconmargin}
                                    size="xs"
                                    icon={faEye}
                                  />
                                  View & Edit
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => {
                                    dispatch(
                                        segmentationModal({
                                          id: Number(item.id) ,
                                          editOrAdd: INSTRUCTOR.REDUX.EDIT,
                                        })
                                      );
                                    setShowRemoveModal(true)}}
                                >
                                  <FontAwesomeIcon
                                    className={commonStyles.iconmargin}
                                    size="xs"
                                    icon={faTrash}
                                  />
                                 Delete
                                </Dropdown.Item>
                            
                              </Dropdown.Menu>
                            </Dropdown>
                            
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" role="status"></Spinner>
          </div>
        )}
      </div>

      <Pagination
        paginationState={pagination}
        setPaginationState={setPagination}
      />
      <AddOrEditInstalment showModal={showInstalmentModal} handleClose={handleInstalmentClose} />

      <EditSemester showModal={showEditModal} handleClose={handleEditClose}/>
 
         <DeleteInstallment
        showModal={showRemoveModal}
        handleClose={handleRemoveClose}
      />
    </Layout>
  );
};

export default Instalment;
