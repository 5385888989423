import React, { useState, useEffect } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import PhoneInput, { parsePhoneNumber } from "react-phone-number-input";
import { useNavigate, useParams } from "react-router-dom";
import Cropper from 'react-easy-crop'
import Slider from '@mui/material/Slider'
import Typography from '@mui/material/Typography'
import styless from '../../../../components/ImageUpload/styles.module.css'

import styles from "../../instructor/styles/instructor.module.css";
import { AddImageProp } from "../../instructor/routes/AddOrEdit/interface";
import ImageUploader from "../../../components/ImageUpload/ImageUploader";


//import commonstyles from "../../instructor/styles/common.module.css";
// import { CourseDetailsSchema } from "../validations";
//import { INSTRUCTOR } from "../../../utils/instructor";
//import { useAppSelector } from "../../../redux/hooks";
// import {
// 	DepartmentInterface,
// 	Instructor,
// 	UniversityInterface,
// } from "../../instructor/api/interface";
// import {
// 	getAllInstructorUniversity,
// 	getAllUniversities,
// } from "../../instructor/api";
// // import { AddCourseParam } from "../api/interface";
// import { PaginationInterface } from "../../../components/Pagination/interfaces";
// import { COURSES } from "../../../utils/courses";
// import { MESSAGES } from "../../../utils/messages";
// import { YUP } from "../../../utils/validations";


const AddImage = ({ showModal, handleClose,croppedImage,setCroppedImage }: AddImageProp) => {



	return (
		<Modal 
			show={showModal}
			onHide={() => {
				handleClose();
			}}
		>
			<div className={`${styles["modalHeight"]} ${styles["add-lead-from"]}`}>
				<Modal.Header closeButton>
					<Modal.Title>
						{"Upload Image"}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body >
					<Form onSubmit={()=>{}}>
                    <div className={styles.fieldStyle}>
					<ImageUploader handleClose={handleClose} setCroppedImage={setCroppedImage}/>
					</div>
						</Form>
				</Modal.Body>
			</div>

		</Modal>
	);
};

export default AddImage;
