import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faCopy, faSearch, faGear, faPencil } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { Button, Form, InputGroup, Spinner } from "react-bootstrap";
// import { toast } from "react-toastify";
import { CopyToClipboard } from "react-copy-to-clipboard";

import Layout from "../../../components/Layout";
import commonStyles from "../../common/styles/common.module.css";
// import { Instructor } from "../api/interface";
import Pagination from "../../../components/Pagination";
import { PaginationInterface } from "../../../components/Pagination/interfaces";
// import { MESSAGES } from "../../../utils/messages";
import { toast } from "react-toastify";
import { INVOICE_DATA } from "../api/interface";
import { useAppSelector } from "../../../redux/hooks";
import AddOrEditDeviceRestrictions from "../modals/AddOrEditDeviceRestrictions";

const InvoiceList = () => {
 
  const [showModal, setShowModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");


  const searchText = useAppSelector((state) => state.searchReducer.text);




  const handleClose = () => {
    setShowModal(false);

  };

  // const handleRemoveInstructor = (id: Number) => {
  // 	toast.promise(removeInstructor({ id }), {
  // 		pending: {
  // 			render() {
  // 				return MESSAGES.INSTRUCTOR.REMOVING;
  // 			},
  // 		},
  // 		success: {
  // 			render({}) {
  // 				refresh();
  // 				return MESSAGES.INSTRUCTOR.REMOVED;
  // 			},
  // 		},
  // 		error: {
  // 			render() {
  // 				return MESSAGES.INSTRUCTOR.NOTREMOVED;
  // 			},
  // 		},
  // 	});
  // };

  const addNewInvoice = () => {
    // dispatch(instructorModal({ id: 0, editOrAdd: INSTRUCTOR.REDUX.ADD }));
    // navigate(APP_ROUTES.INSTRUCTORS_ADD);
    setShowModal(true);
  };

  // const editInstructor = (id: Number) => {
  // 	navigate(`${APP_ROUTES.INSTRUCTORS_LIST}/${id}`);
  // };
  // const viewCourses=(id:Number)=>{
  // 	navigate(`${ROUTE_CONSTANTS.INSTRUCTORS_COURSES}/${id}`)
  // }



  return (
    <Layout>
      <div className={commonStyles["outtre-pad"]}>
        <div className={commonStyles["heaing-filter"]}>
          <h3>Configurations</h3>
          {/* <div className={commonStyles["filter-left"]}>
						<span>Filter: <select>
							<option>Location</option>
							<option>This Week</option>
						</select></span>
						<span>Filter: <select>
							<option>Price</option>
							<option>This Week</option>
						</select></span>
						<span>Filter: <select>
							<option>Department</option>
							<option>This Week</option>
						</select></span>
						<span>Filter: <select>
							<option>This Week</option>
							<option>This Week</option>
						</select></span>
					</div> */}
        </div>
        {!loading ? (
          <div className={`light-theme-table ${commonStyles["table-data"]}`}>
            <div className={`${commonStyles["property-head"]} pb-1`}>
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  {/* <button
									className={`nav-link ${commonStyles.activeTab}`}
									id="pills-home-tab"
									data-bs-toggle="pill"
									data-bs-target="#pills-home"
									type="button"
									role="tab"
									aria-controls="pills-home"
									aria-selected="true"
								>
                  All
								</button> */}
                </li>
              </ul>
              <div className={commonStyles["filter-pro"]}>
                {/* <button
                  onClick={addNewInvoice}
                  className={commonStyles["add-lead"]}
                >
                  <FontAwesomeIcon icon={faPlus} /> Add New Invoice
                </button> */}
              </div>
            </div>
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <div style={{ overflowX: "auto" }}>
                  <table>
                    <thead>
                      <tr>
                        <th>Type</th>
                        <th>Change</th>
                      
                        {/* <th className="text-center">Total Courses</th>
											<th className="text-center">Total Students</th>
											<th>Registered At</th>
											<th>Status</th>
											<th>Action</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Change Device Restriction</td>
                        <td><Button onClick={addNewInvoice}><FontAwesomeIcon icon={faPencil} /></Button></td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" role="status"></Spinner>
          </div>
        )}
      </div>

    
      <AddOrEditDeviceRestrictions showModal={showModal} handleClose={handleClose} />
    </Layout>
  );
};

export default InvoiceList;
