import React, { useEffect, useState } from "react"
import { Modal, Form, Button } from "react-bootstrap";
import { useFormik } from "formik";
import { toast } from "react-toastify";

import {PriceDetailsSchema} from "../validations";
import { useAppSelector } from "../../../redux/hooks";
import { AddInstructorProp } from "./interface"
import styles from "../../instructor/styles/instructor.module.css";
import { approveCourse, course } from "../api";
import { PaginationInterface } from "../../../components/Pagination/interfaces";
import { MESSAGES } from "../../../utils/messages";
import { COURSES } from "../../../utils/courses";
import { ApproveCoursePayload } from "../api/interface";

const PriceModal = ({ showModal, handleClose}:AddInstructorProp) => {
	const selectedCourse=useAppSelector(state=>state.courseReducer)
	const [pagination, setPagination] = useState<PaginationInterface>({ page: 1, limit: 1, count: 0, totalPages:0 });

	useEffect(() => {
		console.log(setPagination);
		if(selectedCourse.id!==0){
			const query = {
				...pagination
			};
			course(selectedCourse.id.toString(), query).then((res)=>{
				const {full_price}=res.data
				priceDetailsFormik.setFieldValue(COURSES.PRICE.OFFERPRICE, `${full_price}KD`)
			})
		}
	}, [selectedCourse.id, showModal])

    
	const priceDetailsFormik = useFormik({
		initialValues: {offerPrice:"", status:1, newPrice:0},
		enableReinitialize: true,
		validationSchema:PriceDetailsSchema,
		onSubmit: (values) => {
			// handleClose()
			const payload:ApproveCoursePayload={
				course_id:selectedCourse.id as number,
			}
			if(values.status===1)
			{
				payload.status=1;

			}
			else{
				payload.status=2;
				payload.price=Number(values.newPrice)

			}
          
			toast.promise(
				approveCourse(payload),
				{
					pending: {
						render() {
							return MESSAGES.PRICE.APPROVING;
						}
					},
					success: {
						render({ }) {
            
							priceDetailsFormik.resetForm();
							handleClose();
  
                
							return MESSAGES.PRICE.APPROVED
						}
					},
					error: {
						render() {
               
							return MESSAGES.PRICE.NOTAPPROVED
						}
					}
				}
			);
			
		},
	});

	const handleAddPrice=()=>{
		priceDetailsFormik.submitForm()

	}

	const handlecustomPrice=()=>{
		if(priceDetailsFormik.values.status===1){
			priceDetailsFormik.setFieldValue(COURSES.PRICE.STATUS, 2)
		}
		else{
			priceDetailsFormik.setFieldValue(COURSES.PRICE.STATUS, 1)

		}

	}
	return (
		<Modal show={showModal} onHide={()=>{handleClose(); priceDetailsFormik.resetForm()}}>
			<div className={styles["add-lead-from"]}>
				<Modal.Header closeButton>
					<Modal.Title>Approve Amount</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={handleAddPrice}>
						<div className={styles.fieldStyle}>
							{priceDetailsFormik.values.status===1&&<Form.Group className="from-group" controlId="formFirstName">
								<Form.Control disabled={true} contentEditable={false} type="text" placeholder="Price"
								 value={priceDetailsFormik.values.offerPrice}  />
							</Form.Group>}
							{/* {instructorDetailsFormik.errors.firstName&&instructorDetailsFormik.touched.firstName ? <span className={`${commonstyles["error"]}`}>{instructorDetailsFormik.errors.firstName}</span> : <span></span>} */}
						</div>
						
						<hr />
						<div className='d-flex justify-content-between'>
							<div className="form-check">
								<input value={priceDetailsFormik.values.status} onChange={handlecustomPrice}
								 checked={priceDetailsFormik.values.status === 2} className="form-check-input" type="checkbox"  />
								<label className="form-check-label" htmlFor="school">
                                            Add custom price
								</label>
							</div>
                                                
						</div>
						<div className={styles.rupeefield}>
							{priceDetailsFormik.values.status===2&&<Form.Group  className="from-group" controlId="formFirstName">
								<Form.Label column sm="1">
							KD
								</Form.Label>
								<Form.Control  onKeyDown={(e) => {
									 if (e.key === "e" || e.key === "E") {
										e.preventDefault();
									}
								}} name="newPrice"  type="number" placeholder="Price" value={(priceDetailsFormik.values.newPrice)}
	 onChange={priceDetailsFormik.handleChange } />
							</Form.Group>}
							{priceDetailsFormik.errors.newPrice&&priceDetailsFormik.touched.newPrice ?
							 <span className={styles.error}>{priceDetailsFormik.errors.newPrice}</span> : <span></span>}
						</div>
						
				
						<Button className={`${styles.buttonss} mt-3`}  onClick={()=>priceDetailsFormik.submitForm()} >
							Approve
						</Button>
					</Form>
				</Modal.Body>
			</div>
		</Modal>  )
}

export default PriceModal