import React, { useState } from "react";
// import moment from "moment";
// import { Navigate, useParams } from "react-router-dom";
import { Dropdown, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH, faPlus } from "@fortawesome/free-solid-svg-icons";
// import { toast } from "react-toastify";

import Layout from "../../../../../components/Layout/index";
import commonStyles from "../../../../common/styles/common.module.css";
// import { departmentList, removeDepartment } from "../../../api";
// import Pagination from "../../../../../components/Pagination";
// import { PaginationInterface } from "../../../../../components/Pagination/interfaces";
// import { AddOrEditDepartment } from "../../../modals";
// import { INSTRUCTOR } from "../../../../../utils/instructor";
// import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
// import { departmentModal } from "../../../../../redux/reducers/department";
// import { MESSAGES } from "../../../../../utils/messages";
// import { Department as DepartmentInterface } from "../../../api/interface";
import AddOrEditSemester from "../../../modals/AddOrEditSemester";

const Semesters = () => {
  // const { id } = useParams();

  // const [department, setDepartment] = useState<DepartmentInterface[]>([]);
  // const [pagination, setPagination] = useState<PaginationInterface>({
  // 	page: 1,
  // 	limit: 10,
  // 	count: 0,
  // 	totalPages: 0,
  // });
  const [showModal, setShowModal] = useState<boolean>(false);
  // const dispatch = useAppDispatch();
  // const [loading, setLoading] = useState<boolean>(true);

  // const selectedDepartment = useAppSelector((state) => state.departmentReducer);

  // const handleRemoveDepartment = (id: number) => {
  // 	toast.promise(removeDepartment({ id: id }), {
  // 		pending: {
  // 			render() {
  // 				return MESSAGES.DEPARTMENT.REMOVING;
  // 			},
  // 		},
  // 		success: {
  // 			render({}) {
  // 				refresh();
  // 				return MESSAGES.DEPARTMENT.REMOVED;
  // 			},
  // 		},
  // 		error: {
  // 			render() {
  // 				return MESSAGES.DEPARTMENT.NOTREMOVED;
  // 			},
  // 		},
  // 	});
  // };
  // const refresh = () => {
  // 	setLoading(true);
  // 	const query = {
  // 		per_page: pagination.limit,
  // 		page: pagination.page,
  // 	};

  // 	departmentList(query, { type: 1, university_id: Number(id) }).then(
  // 		(res) => {
  // 			const {
  // 				paginate: { total, last_page },
  // 				data,
  // 			} = res;
  // 			setPagination({ ...pagination, count: total, totalPages: last_page });
  // 			setDepartment(data);
  // 			setLoading(false);
  // 		}
  // 	);
  // };
  // useEffect(() => {
  // 	refresh();
  // }, []);
  // useEffect(() => {
  // 	refresh();
  // }, [pagination.page]);

  const handleClose = () => {
    setShowModal(false);
    // if (selectedDepartment.editOrAdd === INSTRUCTOR.REDUX.ADD) {
    // 	if (pagination.page === 1) {
    // 		refresh();
    // 	} else {
    // 		setPagination({ page: 1, limit: 10, count: 0, totalPages: 0 });
    // 	}
    // } else {
    // 	refresh();
    // }
  };

  return (
    <Layout>
      <div className={commonStyles["outtre-pad"]}>
        <div className={commonStyles["heaing-filter"]}>
          <h3></h3>
          {/* <div className={commonStyles["filter-left"]}>
						<span>Filter: <select>
							<option>Location</option>
							<option>This Week</option>
						</select></span>
						<span>Filter: <select>
							<option>Price</option>
							<option>This Week</option>
						</select></span>
						<span>Filter: <select>
							<option>Department</option>
							<option>This Week</option>
						</select></span>
						<span>Filter: <select>
							<option>This Week</option>
							<option>This Week</option>
						</select></span>
					</div> */}
        </div>
        {true ? (
          <div className={commonStyles["table-data"]}>
            <div className={`${commonStyles["property-head"]} pb-1`}>
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  {/* <button
									className={`nav-link ${commonStyles.activeTab}`}
									id="pills-home-tab"
									data-bs-toggle="pill"
									data-bs-target="#pills-home"
									type="button"
									role="tab"
									aria-controls="pills-home"
									aria-selected="true"
								>
                  All
								</button> */}
                </li>
              </ul>
              <div className={commonStyles["filter-pro"]}>
                <button
                  onClick={() => {
                    // dispatch(
                    // 	departmentModal({
                    // 		id: 0,
                    // 		editOrAdd: INSTRUCTOR.REDUX.ADD,
                    // 	})
                    // );
                    setShowModal(true);
                  }}
                  className={commonStyles["add-lead"]}
                >
                  <FontAwesomeIcon icon={faPlus} /> Add New Semester
                </button>
              </div>
            </div>
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <div style={{ overflowX: "auto" }}>
                  <table>
                    <thead>
                      <tr>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{"25 January 2024"}</td>
                        <td>{"25 January 2024"}</td>
                        <td>
                          <Dropdown
                            drop={"down-centered"}
                            className={commonStyles.tddropdown}
                          >
                            <Dropdown.Toggle>
                              <FontAwesomeIcon icon={faEllipsisH} />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              {/* <Dropdown.Item
																onClick={() => {
																	dispatch(
																		departmentModal({
																			id: item.id,
																			editOrAdd: INSTRUCTOR.REDUX.EDIT,
																		})
																	);
																	setShowModal(true);
																}}
															>
																<FontAwesomeIcon
																	className={commonStyles.iconmargin}
																	icon={faEdit}
																	size="xs"
																/>
                                Edit
															</Dropdown.Item>
															<Dropdown.Item
																onClick={() => handleRemoveDepartment(item.id)}
															>
																<FontAwesomeIcon
																	className={commonStyles.iconmargin}
																	size="xs"
																	icon={faTrash}
																/>
                                Remove
															</Dropdown.Item>
															<Dropdown.Item
																onClick={() => }
															>
																<FontAwesomeIcon
																	className={commonStyles.iconmargin}
																	size="xs"
																	icon={faEye}
																/>
                               View Semesters
															</Dropdown.Item> */}
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                      {/* {department.map((item) => (
											<tr key={item.id}>
												<td>{item.name}</td>

												<td className="text-center">
													{moment(item.created_at).format("MMM DD, YYYY")}
												</td>
												<td>
													<Dropdown
														drop={"down-centered"}
														className={commonStyles.tddropdown}
													>
														<Dropdown.Toggle>
															<FontAwesomeIcon icon={faEllipsisH} />
														</Dropdown.Toggle>

														<Dropdown.Menu>
															<Dropdown.Item
																onClick={() => {
																	dispatch(
																		departmentModal({
																			id: item.id,
																			editOrAdd: INSTRUCTOR.REDUX.EDIT,
																		})
																	);
																	setShowModal(true);
																}}
															>
																<FontAwesomeIcon
																	className={commonStyles.iconmargin}
																	icon={faEdit}
																	size="xs"
																/>
                                Edit
															</Dropdown.Item>
															<Dropdown.Item
																onClick={() => handleRemoveDepartment(item.id)}
															>
																<FontAwesomeIcon
																	className={commonStyles.iconmargin}
																	size="xs"
																	icon={faTrash}
																/>
                                Remove
															</Dropdown.Item>
															<Dropdown.Item
																onClick={() => }
															>
																<FontAwesomeIcon
																	className={commonStyles.iconmargin}
																	size="xs"
																	icon={faEye}
																/>
                               View Semesters
															</Dropdown.Item>
														</Dropdown.Menu>
													</Dropdown>
												</td>
											</tr>
										))} */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" role="status"></Spinner>
          </div>
        )}
      </div>

      {/* <Pagination
				paginationState={pagination}
				setPaginationState={setPagination}
			/> */}
      <AddOrEditSemester showModal={showModal} handleClose={handleClose} />
    </Layout>
  );
};

export default Semesters;
