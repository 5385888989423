import * as Yup from "yup";
import { YUP } from "../../../utils/validations";
import { COURSES } from "../../../utils/courses";

const PriceDetailsSchema = Yup.object().shape({
  newPrice: Yup.number().when(YUP.STATUS, {
    is: 2,
    then: () =>
      Yup.number()
        .required(YUP.REQUIRED)
        .positive(YUP.POSITIVE)
        .integer(YUP.INTEGER)
        .notOneOf([0], YUP.ZERO),
    otherwise: () => Yup.number().notRequired(),
  }),
});

const CourseDetailsSchema = Yup.object().shape({
  course: Yup.string().required(YUP.REQUIRED),
  description: Yup.string()
    .required(YUP.REQUIRED)
    .max(123, "Description must be at most 123 characters long"),
  // image:Yup.string().required(YUP.REQUIRED),
  instructor: Yup.number().positive(YUP.REQUIRED),
  university: Yup.number().positive(YUP.REQUIRED),
  shareUnit: Yup.number().required(),
  course_type: Yup.number().required(),
  category: Yup.number().notOneOf([0], "Required"),
  price: Yup.number().when("course_type", {
    is: (value: any) => Number(value) === 1 || Number(value) === 2,
    then: () =>
      Yup.number()
        .required(YUP.REQUIRED)
        .min(0, YUP.POSITIVE)
        .integer(YUP.INTEGER),
    otherwise: () => Yup.number().notRequired(),
  }),
  discount: Yup.number().min(0).max(100).required(),
  onlinePrice: Yup.number().when("course_type", {
    is: 3,
    then: () =>
      Yup.number()
        .required(YUP.REQUIRED)
        .min(0, YUP.POSITIVE)
        .integer(YUP.INTEGER),
    otherwise: () => Yup.number().notRequired(),
  }),
  inPersonPrice: Yup.number().when("course_type", {
    is: 3,
    then: () =>
      Yup.number()
        .required(YUP.REQUIRED)
        .min(0, YUP.POSITIVE)
        .integer(YUP.INTEGER),
    otherwise: () => Yup.number().notRequired(),
  }),
  code: Yup.string().required(YUP.REQUIRED),
  share: Yup.number()
    .required()
    .when(COURSES.FORMIK.SHARE_UNIT, {
      is: 1,
      then: () =>
        Yup.number().min(0, YUP.PERCENTAGE_MORE).max(100, YUP.PERCENTAGE_LESS),
      otherwise: () =>
        Yup.number()
          .required()
          .min(0, YUP.PERCENTAGE_MORE)
          .when(COURSES.FORMIK.PRICE, (price, schema) => {
            return schema.max(
              price[0] as number,
              `Instructor Share must be at most ${price}`
            );
          }),
    }),
  instructor_share_type: Yup.number().notRequired(),
  link: Yup.string()
    .url()
    .nullable()
    .notRequired()
    .test(
      "is-valid-or-empty",
      "Invalid URL format",
      (value) => !value || Yup.string().url().isValidSync(value)
    ),
  departments: Yup.array()
    .of(Yup.number())
    .min(1, YUP.ARRAYEMPTY)
    .required("Required"),
});
const CourseAddDetailsSchema = Yup.object().shape({
  course: Yup.string().required(YUP.REQUIRED),
  description: Yup.string()
    .required(YUP.REQUIRED)
    .max(123, "Description must be at most 123 characters long"), // image:Yup.string().required(YUP.REQUIRED),
  instructor: Yup.number().positive(YUP.REQUIRED),
  //university:Yup.number().positive(YUP.REQUIRED),
  shareUnit: Yup.number().required(),
  course_type: Yup.number().required(),
  category: Yup.number().notOneOf([0], "Required"),
  // semesters:Yup.array()
  // .of(Yup.number())
  // .min(1, YUP.ARRAYEMPTY),

  price: Yup.number().when("course_type", {
    is: (value: any) => Number(value) === 1 || Number(value) === 2,
    then: () =>
      Yup.number()
        .required(YUP.REQUIRED)
        .min(0, YUP.POSITIVE)
        .integer(YUP.INTEGER),
    otherwise: () => Yup.number().notRequired(),
  }),
  discount: Yup.number().min(0).max(100).required(),
  onlinePrice: Yup.number().when("course_type", {
    is: 3,
    then: () =>
      Yup.number()
        .required(YUP.REQUIRED)
        .min(0, YUP.POSITIVE)
        .integer(YUP.INTEGER),
    otherwise: () => Yup.number().notRequired(),
  }),
  inPersonPrice: Yup.number().when("course_type", {
    is: 3,
    then: () =>
      Yup.number()
        .required(YUP.REQUIRED)
        .min(0, YUP.POSITIVE)
        .integer(YUP.INTEGER),
    otherwise: () => Yup.number().notRequired(),
  }),
  code: Yup.string().required(YUP.REQUIRED),
  share: Yup.number()
    .required()
    .when(COURSES.FORMIK.SHARE_UNIT, {
      is: 1,
      then: () =>
        Yup.number().min(0, YUP.PERCENTAGE_MORE).max(100, YUP.PERCENTAGE_LESS),
      otherwise: () =>
        Yup.number()
          .required()
          .min(0, YUP.PERCENTAGE_MORE)
          .when(COURSES.FORMIK.PRICE, (price, schema) => {
            return schema.max(
              price[0] as number,
              `Instructor Share must be at most ${price}`
            );
          }),
    }),
  link: Yup.string()
    .url()
    .nullable()
    .notRequired()
    .test(
      "is-valid-or-empty",
      "Invalid URL format",
      (value) => !value || Yup.string().url().isValidSync(value)
    ),
  departments: Yup.array()
    .of(Yup.number())
    .min(1, YUP.ARRAYEMPTY)
    .required("Required"),
});

const ChaptersDetailSchema = Yup.object().shape({
  shareUnit: Yup.number().required(),
  price: Yup.number().positive(YUP.REQUIRED).required(),
  instructor_share_type: Yup.number(),
  share: Yup.number()
    .required()
    .when(COURSES.FORMIK.SHARE_UNIT, {
      is: 1,
      then: () =>
        Yup.number().min(0, YUP.PERCENTAGE_MORE).max(100, YUP.PERCENTAGE_LESS),
      otherwise: () =>
        Yup.number()
          .required()
          .min(0, YUP.PERCENTAGE_MORE)
          .when(COURSES.FORMIK.PRICE, (price, schema) => {
            return schema.max(
              price[0] as number,
              `Instructor Share must be at most ${price}`
            );
          }),
    }),
});

const AddonDetailSchema = Yup.object().shape({
  price: Yup.number().positive(YUP.REQUIRED).required(),
  name: Yup.string().required(YUP.REQUIRED),
});

export {
  PriceDetailsSchema,
  CourseDetailsSchema,
  CourseAddDetailsSchema,
  ChaptersDetailSchema,
  AddonDetailSchema,
};
