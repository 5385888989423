import React, { useEffect, useState } from "react";
import moment from "moment";
import { useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faEdit, faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { Avatar } from "../../../../../../assets/images";

import Layout from "../../../../../../components/Layout/index";
import commonStyles from "../../../../../common/styles/common.module.css";
import { getStudentByCourse } from "../../api";
// import { ChapterInfo } from "../../api/interface";
import Pagination from "../../../../../../components/Pagination";
import { PaginationInterface } from "../../../../../../components/Pagination/interfaces";
// import Edit from "../../modals/chapters/Edit";
// import { ROUTE_CONSTANTS } from "../../../../../../routes/constants";
import { Student } from "../../../../../student/api/interface";
import { studentList } from "../../../../../student/api";
import { universityList } from "../../../../../university/api";
// import { STUDENT } from "../../../../../../utils/student";

const StudentList = () => {
  const { id } = useParams();
  // const navigate=useNavigate();
  // const [showModal, setShowModal] = useState<boolean>(false);
  const [students, setStudents] = useState<Student[]>([]);
  // const [description, setDescription] = useState<String>();
  const [pagination, setPagination] = useState<PaginationInterface>({
    page: 1,
    limit: 10,
    count: 0,
    totalPages: 0,
  });
  const [loading, setLoading] = useState<boolean>(true);

  // const handleClose = () => {
  // 	setShowModal(false);
  // };

  // const checkInstitute = (item: Student) => {
  // 	if (item.student.student_type === STUDENT.UNIVERSITY) {
  // 		return item.student.universityDepartment?.name;
  // 	} else if (item.student.student_type === STUDENT.SCHOOL) {
  // 		return item.student.school?.name;
  // 	} else {
  // 		return item.student.trainingField?.name;
  // 	}
  // };
  const refresh = () => {
    setLoading(true);
    const query = {
      per_page: pagination.limit,
      page: pagination.page,
    };

    studentList( query,Number(uid),Number(did),Number(id)).then((res) => {
      const {  data,paginate } = res;
      setStudents(data)
     
      setPagination({ ...pagination, count: paginate.total, totalPages: paginate.last_page });
      setLoading(false);
    });
  };
  useEffect(() => {
    refresh();
  }, []);
  const [uni, setUni] = useState<any>([]);
  const [dep, setdep] = useState<any>([]);
  const [uid, setUid] = useState<any>(0);
  const [did, setDid] = useState<any>(0);

  useEffect(() => {
    universityList({}).then((res)=>{
      setUni(res.data)

    })
  }, []);
  const handleUniChange=(e:any)=>{
    setUid(Number(e.target.value.split(",")[0]))
   if(Number(e.target.value[0])!==0)
    {setdep(uni[Number(e.target.value.split(",")[1])].universityDepartments)}
   else{
    setdep([])
    setDid(0)
   }


  }
  useEffect(() => {
  refresh();
  }, [uid, did]);

  return (
    <Layout>
      <div className={commonStyles["outtre-pad"]}>
        <div className={commonStyles["heaing-filter"]}>
          <h3>Instructor Management/ Courses/ Students</h3>
          <div className={commonStyles["filter-left"]}>
          <span>
              University:{" "}
              <select onChange={handleUniChange}>
                <option value={0}>Select</option>
                {
                  uni.map((u:any,ind:any)=>{
                    return                 <option  value={[u.id,ind]}>{u.name}</option>

                  })
                }
                
              </select>
            </span>
            <span>
             Departments:{" "}
              <select onChange={(e)=>{
                setDid(e.target.value)
              }}>
                <option value={0}>Select</option>
                {
                 dep.map((u:any,ind:any)=>{
                    return                 <option  value={u.id}>{u.name}</option>

                  })
                }
                
              </select>
            </span>
					</div>
        </div>

        {!loading ? (
          <div className={commonStyles["table-data"]}>
            <div className={`${commonStyles["property-head"]} pb-1`}>
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                  {/* <button
									className={`nav-link ${commonStyles.activeTab}`}
									id="pills-home-tab"
									data-bs-toggle="pill"
									data-bs-target="#pills-home"
									type="button"
									role="tab"
									aria-controls="pills-home"
									aria-selected="true"
								>
                  All
								</button> */}
                </li>
              </ul>
              {/* <div className={commonStyles["filter-pro"]}>
								<button
									onClick={() => navigate(APP_ROUTES.STUDENTS_ADD)}
									className={commonStyles["add-lead"]}
								>
									<FontAwesomeIcon icon={faPlus} /> Add New Student
								</button>
							</div> */}
            </div>
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <div style={{ overflowX: "auto" }}>
                  <table>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th className="text-center">Mobile No</th>
                        <th className="text-center">University</th>
                        <th className="text-center">Department</th>
                        <th className="text-center">Course Mode</th>
                        <th className="text-center">Add ons</th>
                        {/* <th className="text-center">Amount</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {students.map((item) => (
                        <tr key={item.id}>
                          <td>
                            <img
                              src={item.avatar ? item.avatar : Avatar}
                              height={40}
                              width={40}
                              alt="avatar"
                            />
                            {`${item.first_name} ${item.last_name}`}
                          </td>
                          {/* <td>{checkInstitute(item)}</td> */}
                      
                          <td className="text-center">
                            +{item.country_code} {item.mobile}
                          </td>
                          <td className="text-center">
                            {item.student.university.name}
                          </td>
                          <td className="text-center">
                            {item.student.universityDepartment.name}
                          </td>
                          <td className="text-center">
                            {item.subscription_type}
                          </td>
                         
                          <td className="text-center">
                            {item?.adson?.map((obj:any) => obj?.name).join(', ')}
                          </td>
                       
                          {/* <td>
												<span className={commonStyles["bl-shd"]}>
													{"View Courses"}
												</span>
											</td> */}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" role="status"></Spinner>
          </div>
        )}
      </div>
      <Pagination
        paginationState={pagination}
        setPaginationState={setPagination}
      />
    </Layout>
  );
};

export default StudentList;
