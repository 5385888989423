import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Button, Form, Modal } from "react-bootstrap";

import { AddUniversityProp } from "./interface";
import { useAppSelector } from "../../../redux/hooks";
import { INSTRUCTOR } from "../../../utils/instructor";
import styles from "../../instructor/styles/instructor.module.css";
import commonstyles from "../../instructor/styles/common.module.css";
import { addSetting, getSetting } from "../api";
import { UNIVERSITY } from "../../../utils/university";
import { MESSAGES } from "../../../utils/messages";
import { uploadImage } from "../../common/utils";
import { InvoiceDetailsSchema } from "../validations";

const AddOrEditDeviceRestrictions = ({ showModal, handleClose }: AddUniversityProp) => {
  const selectedUniversity = useAppSelector((state) => state.universityReducer);

  const [croppedImage, setCroppedImage] = useState<File | null>(null);

  const [showModalImage, setShowModalImage] = useState<boolean>(false);
  const [setting, setSetting] = useState<any>();

  const handleCloseImage = () => {
    setShowModalImage(false);
  };

  useEffect(() => {
	getSetting().then((res)=>{
		setSetting(res.data)
		console.log(res.data)
		universityDetailsFormik.setFieldValue("restriction",res.data[0].value||2)
	})
  
  }, [showModal]);

  const universityDetailsFormik = useFormik({
    initialValues: {
      restriction: '',
      
    },
    enableReinitialize: true,
    onSubmit: (values) => {
     
   
        toast.promise(
         addSetting({key:"device_login_limit",value:values.restriction},setting[0].id),
          {
            pending: {
              render() {
                return "Editing";
              },
            },
            success: {
              render() {
                universityDetailsFormik.resetForm();
                handleClose();

                return "Edited"
              },
            },
            error: {
              render() {
                return "Failed"
              },
            },
          }
        );
     
    },

     validationSchema: InvoiceDetailsSchema,
  });
  const handleAddDepartment = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    universityDetailsFormik.submitForm();
  };

  return (
    <Modal
      show={showModal}
      onHide={() => {
        handleClose();
      }}
    >
      <div>
        <Modal.Header closeButton>
          <Modal.Title>
            {"Change Device Restrictions"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleAddDepartment}>
            <div className={styles.fieldStyle}>
              <Form.Group className="from-group" controlId="formFirstName">
                <Form.Label>Device Count </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Name"
                  value={universityDetailsFormik.values.restriction}
                  name="restriction"
                  onChange={universityDetailsFormik.handleChange}
                />
              </Form.Group>
              {universityDetailsFormik.errors.restriction &&
              universityDetailsFormik.touched.restriction ? (
                <span className={commonstyles.error}>
                  {universityDetailsFormik.errors.restriction}
                </span>
              ) : null}
            
              {/* {universityDetailsFormik.errors.name &&
              universityDetailsFormik.touched.name ? (
									<span className={commonstyles.error}>
										{universityDetailsFormik.errors.name}
									</span>
								) : null} */}
            </div>
          
            <Button className={`${styles.buttonss} mt-3`} type="submit">
              {"Edit"}
            </Button>
          </Form>
        </Modal.Body>
       
      </div>
    </Modal>
  );
};

export default AddOrEditDeviceRestrictions;


