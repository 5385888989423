import * as Yup from "yup";
import { YUP } from "../../../utils/validations";

const loginSchema = Yup.object().shape({
	email: Yup
		.string()
		.required("Email field is mandatory"),
	password: Yup.string().required("Password field is mandatory")
});

export default loginSchema;