import { PaginationInterface } from "../../../components/Pagination/interfaces";
import { axios } from "../../../lib/axios";
import { API_ROUTES } from "../../../utils/api_routes";
import { Pagination } from "../../common/interface";
import { ChaptersResponse, Course } from "../../course/api/interface";
import { RemoveInstructorPayload, RemoveInstructorRespose } from "../../instructor/api/interface";
import { AddCustomCoursePayload, AddStudentPayload, GetStudentResponse, PurchasedCourseResponse, SchoolListResponse, StudentListResponse, TrainingListResponse } from "./interface";

export const studentList = (query?: Pagination,university_id?:number,department_id?:number,course_id?:number): Promise<StudentListResponse> => {
	return axios.get(API_ROUTES.STUDENT.GETLIST, { params: {...query,...(university_id&&university_id!==0&&{university_id}),...(department_id&&department_id!==0&&{department_id}),...(course_id&&course_id!==0&&{course_id})} });
};
export const removeStudent=(query:RemoveInstructorPayload): Promise<RemoveInstructorRespose>=>{
	return axios.delete(`${API_ROUTES.STUDENT.REMOVE}${query.id}`)
}
export const addStudent=(formData:FormData): Promise<RemoveInstructorRespose>=>{
	return axios.post(API_ROUTES.STUDENT.ADD, formData, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
}

export const editStudent=(id:Number, formData:FormData): Promise<RemoveInstructorRespose>=>{
	return axios.post(`${API_ROUTES.STUDENT.EDIT}${id}`, formData, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
}

export const editStudentStatus=(id:number,status:number)=>{
	return axios.get(`${API_ROUTES.STUDENT.CHANGE_STATUS}/${id}/${status}`)
}
export const getStudent=(query:RemoveInstructorPayload): Promise<GetStudentResponse>=>{
	return axios.get(`${API_ROUTES.STUDENT.GETSTUDENT}${query.id}`)
}

export const courseList = (query: Pagination): Promise<RemoveInstructorRespose> => {
	return axios.get(API_ROUTES.COMMON.GETALLCOURSES, { params: query });
};
export const getAllSchools = (): Promise<SchoolListResponse> => {
	return axios.get(API_ROUTES.COMMON.GETALLSCHOOLS);
};
export const getAllTrainingFields = (): Promise<TrainingListResponse> => {
	return axios.get(API_ROUTES.COMMON.GETTRAININGFIELD);
};

export const getPurchasedCourses= (query:PaginationInterface, userId:number): Promise<PurchasedCourseResponse>=>{
	return axios.get(API_ROUTES.COURSES.PURCHASEDCOURSES, {params:{...query, user_id:userId}})
}

export const courseStatus=(id:number, status:1|2): Promise<RemoveInstructorRespose>=>{
	return axios.get(`${API_ROUTES.COURSES.CHANGESTATUS}/${id}/${status}`);
}
export const profileStatus=(id:number, status:1|2): Promise<RemoveInstructorRespose>=>{
	return axios.get(`${API_ROUTES.COURSES.CHANGEPROFILESTATUS}/${id}/${status}`);
}
export const courseListWithoutPagination = (): Promise<Course> => {
	return axios.get(API_ROUTES.COURSES.GETCOURSELIST);
};
export const getChapter=(id:number): Promise<ChaptersResponse>=>{
	return axios.get(API_ROUTES.COURSES.GETCHAPTERS, {params:{ course_id:id}});
}
export const addCourse=(query:AddCustomCoursePayload):Promise<RemoveInstructorPayload>=>{
	return axios.post(API_ROUTES.COURSES.BUYCOURSE, {...query})
}
export const checkInstallments=(course_id:number,amount:number,installments:number,down_payment?:number,student_id?:number,plan_type?:number):Promise<any>=>{
	return axios.post("get-installment-preview", {course_id,amount,installments,...(down_payment&&{down_payment: down_payment}),...(student_id&&{user_id: student_id}),...(plan_type&&{installment_plan_id: plan_type})})
}
export const getDevices=(query: Pagination,student_id:number):Promise<any>=>{
	return axios.get(`${"/admin/get-student-device-login-list"}/${student_id}`)
}
export const removeDevice=(id:number)=>{
	return axios.get(`/admin/logout-device/${id}`)

}
export const getPlans=(course_id:number)=>{
	return axios.get(`/admin/installment-plan`,{params:{course_id}})

}
