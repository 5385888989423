import * as Yup from "yup";
// import { YUP } from "../../../utils/validations";
// import { COURSES } from "../../../utils/courses";

const InvoiceDetailsSchema = Yup.object().shape({
	restriction:Yup.number().min(1).required("Required")

});


export { InvoiceDetailsSchema};