import React, { Fragment, useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
import { Button, Form } from "react-bootstrap";
import { useFormik } from "formik";
import { parsePhoneNumber } from "react-phone-number-input";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import CryptoJS from "crypto-js";
import { IMAGE_BASE_URL } from "../../../../config";

import {
  DepartmentInterface,
  UniversityInterface,
} from "../../../instructor/api/interface";
import { getAllUniversities } from "../../../instructor/api";
import { INSTRUCTOR } from "../../../../utils/instructor";
import { MESSAGES } from "../../../../utils/messages";
import {
  AddStudentPayload,
  School,
  SchoolListResponse,
  Training,
  TrainingListResponse,
} from "../../api/interface";
import {
  addStudent,
  editStudent,
  getAllSchools,
  getAllTrainingFields,
  getStudent,
} from "../../api";
import { StudentDetailsSchema } from "../../validations";
import styles from "../../../instructor/styles/instructor.module.css";
import commonstyles from "../../../instructor/styles/common.module.css";
import { STUDENT } from "../../../../utils/student";
import { STUDENT_TYPE } from "../../../../utils/constants";
import { APP_ROUTES } from "../../../../utils/routes";
import Layout from "../../../../components/Layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import AddImage from "./UploadImageModal";

const AddOrEdit = () => {
  const [universities, setUniversities] = useState<UniversityInterface[]>([]);
  const [department, setDepartment] = useState<DepartmentInterface[]>();
  const [school, setSchool] = useState<School[]>([]);
  const [trainingFields, setTrainingField] = useState<Training[]>([]);
  const [showPassword, setShowPassword] = useState(false);
  const [imageFile, setImageFile] = useState<string | null>(null);
  const [croppedImage, setCroppedImage] = useState<File | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);

  const { id } = useParams();
  const navigate = useNavigate();

  const handleClose = () => {
    setShowModal(false);
  };

  const createFormData = (
    values: any,
    mobileNumber: any,
    croppedImage: any
  ): FormData => {
    const formData = new FormData();
    formData.append("first_name", values.firstName);
    formData.append("last_name", values.lastName);
    formData.append("country_code", mobileNumber?.countryCallingCode || "");
    formData.append("mobile", mobileNumber?.nationalNumber || "");
    formData.append("email", values.email);
    formData.append("instructor_role", values.role);
    formData.append("password", values.password);
    formData.append("no_of_logins", values.deviceRestriction);
    if (values.userType === STUDENT.STUDENTTYPE.SCHOOL) {
      formData.append("school_id", Number(values.school)?.toString());
      formData.append("student_type", STUDENT_TYPE.STUDENT?.toString());
    } else if (values.userType === STUDENT.STUDENTTYPE.UNIVERSITY) {
      formData.append("university_id", values.university?.toString());
      formData.append("university_dep_id", values.department?.toString());
      formData.append("student_type", STUDENT_TYPE.UNIVERSITY?.toString());
    } else {
      formData.append("status", "1");
      formData.append("training_field", values.trainingField?.toString());
      formData.append("student_type", STUDENT_TYPE.GENERAL?.toString());
    }
    formData.append(
      "encrypted_password",
      CryptoJS.AES.encrypt(
        values.password,
        process.env.REACT_APP_PASSWORD_KEY as string
      )?.toString()
    );

    if (croppedImage) {
      formData.append("avatar", croppedImage);
    }

    return formData;
  };

  useEffect(() => {
    getAllSchools().then((res: SchoolListResponse) => {
      setSchool(res.data);
    });
    getAllTrainingFields().then((res: TrainingListResponse) => {
      setTrainingField(res.data);
    });
    getAllUniversities().then((res) => {
      setUniversities(res?.data);
    });
  }, []);

  useEffect(() => {
    if (croppedImage) {
      setImageFile(URL.createObjectURL(croppedImage));
    }
  }, [croppedImage]);

  useEffect(() => {
    if (id) {
      getStudent({ id: Number(id) }).then((res) => {
        setImageFile(null);
        const {
          user: {
            student: {
              student_type,
              school,
              university,
              universityDepartment,
              training_field,
            },
            first_name,
            last_name,
            country_code,
            mobile,
            email,
            no_of_logins,
            encrypted_password,
            avatar,
          },
        } = res;

        studentDetailsFormik.setFieldValue(
          STUDENT.FORMIK.USERTYPE,
          student_type.toLowerCase()
        );

        if (avatar) {
          const fileURL = `${IMAGE_BASE_URL}${avatar}`;
          setImageFile(fileURL);
        }

        if (
          res.user.student.student_type.toLowerCase() ===
          STUDENT.STUDENTTYPE.SCHOOL
        ) {
          studentDetailsFormik.setFieldValue(
            STUDENT.STUDENTTYPE.SCHOOL,
            school.id
          );
        } else if (
          res.user.student.student_type.toLowerCase() ===
          STUDENT.STUDENTTYPE.UNIVERSITY
        ) {
          addDepartments(res.user.student.university.id);
          studentDetailsFormik.setFieldValue(
            STUDENT.STUDENTTYPE.UNIVERSITY,
            university.id
          );
          studentDetailsFormik.setFieldValue(
            INSTRUCTOR.FORMIK.DEPARTMENT,
            universityDepartment.id
          );
        } else {
          studentDetailsFormik.setFieldValue(
            STUDENT.FORMIK.TRAININGFIELD,
            training_field
          );
        }

        studentDetailsFormik.setFieldValue(
          INSTRUCTOR.FORMIK.FIRSTNAME,
          first_name
        );
        studentDetailsFormik.setFieldValue(
          INSTRUCTOR.FORMIK.LASTNAME,
          last_name
        );
        studentDetailsFormik.setFieldValue(
          INSTRUCTOR.FORMIK.PHONENUMBER,
          `${country_code && "+"}${country_code}${mobile}`
        );
        studentDetailsFormik.setFieldValue(INSTRUCTOR.FORMIK.EMAIL, email);
        studentDetailsFormik.setFieldValue(
          STUDENT.FORMIK.DEVICERESTRICTION,
          no_of_logins
        );
        if (encrypted_password) {
          const bytes = CryptoJS.AES.decrypt(
            encrypted_password as string,
            process.env.REACT_APP_PASSWORD_KEY as string
          );
          const originalPassword = bytes.toString(CryptoJS.enc.Utf8);
          studentDetailsFormik.setFieldValue("password", originalPassword);
        }
      });
    } else {
      studentDetailsFormik.resetForm();
    }
  }, [universities]);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const studentDetailsFormik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      university: 0,
      department: 0,
      email: "",
      phoneNumber: "",
      password: "",
      userType: "university",
      trainingField: 0,
      school: 0,
      deviceRestriction: "",
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      const mobileNumber = parsePhoneNumber(values.phoneNumber);
      const formData = createFormData(values, mobileNumber, croppedImage);
      console.log("formData", values);

      toast.promise(
        !id ? addStudent(formData) : editStudent(Number(id), formData),
        {
          pending: {
            render() {
              return !id ? MESSAGES.STUDENT.ADD : MESSAGES.STUDENT.EDIT;
            },
          },
          success: {
            render() {
              studentDetailsFormik.resetForm();
              navigate(APP_ROUTES.STUDENTS_LIST);

              return !id ? MESSAGES.STUDENT.SUCCESS : MESSAGES.STUDENT.EDITED;
            },
          },
          error: {
            render() {
              return !id
                ? MESSAGES.STUDENT.INVALID
                : MESSAGES.STUDENT.NOTEDITED;
            },
          },
        }
      );
    },

    validationSchema: StudentDetailsSchema,
  });

  const handleUniversityChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    studentDetailsFormik.setFieldValue(
      INSTRUCTOR.FORMIK.UNIVERSITY,
      Number(e.target.value)
    );

    studentDetailsFormik.setFieldValue(INSTRUCTOR.FORMIK.DEPARTMENT, 0);
    addDepartments(Number(e.target.value));
  };

  const handleDepartmentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    studentDetailsFormik.setFieldValue(
      INSTRUCTOR.FORMIK.DEPARTMENT,
      Number(e.target.value)
    );
  };

  const handleAddStudent = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    studentDetailsFormik.submitForm();
  };

  const addDepartments = (id: Number) => {
    const selectedUniversity = universities.filter(
      (uni: UniversityInterface) => uni.id === id
    );
    setDepartment(selectedUniversity[0]?.universityDepartments);
  };

  const handleStudentTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    resetCourse();

    studentDetailsFormik.setFieldValue(
      STUDENT.FORMIK.USERTYPE,
      event.target.value
    );
  };

  const resetCourse = () => {
    studentDetailsFormik.setFieldValue(INSTRUCTOR.FORMIK.DEPARTMENT, 0);
    studentDetailsFormik.setFieldValue(STUDENT.STUDENTTYPE.UNIVERSITY, 0);
    studentDetailsFormik.setFieldValue(STUDENT.STUDENTTYPE.SCHOOL, 0);
    studentDetailsFormik.setFieldValue(STUDENT.FORMIK.TRAININGFIELD, 0);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    studentDetailsFormik.setFieldValue(
      STUDENT.STUDENTTYPE.SCHOOL,
      Number(e.target.value)
    );
  };
  const handleTrainingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    studentDetailsFormik.setFieldValue(
      STUDENT.FORMIK.TRAININGFIELD,
      Number(e.target.value)
    );
  };

  return (
    <Layout>
      <div
        className={`${styles["add-lead-from-page"]} ${styles["add-lead-from"]}`}
      >
        <h1>{id ? "Edit Student" : "Add New Student"}</h1>

        <Form onSubmit={handleAddStudent}>
          <div className={styles.fieldStyle}>
            <Form.Group className="from-group" controlId="formFirstName">
              <Form.Control
                type="text"
                name="firstName"
                placeholder="First Name"
                value={studentDetailsFormik.values.firstName}
                onChange={studentDetailsFormik.handleChange}
              />
            </Form.Group>
            {studentDetailsFormik.errors.firstName &&
            studentDetailsFormik.touched.firstName ? (
              <span className={commonstyles.error}>
                {studentDetailsFormik.errors.firstName}
              </span>
            ) : (
              <span></span>
            )}
          </div>
          <div className={styles.fieldStyle}>
            <Form.Group className="from-group" controlId="formLastName">
              <Form.Control
                type="text"
                name="lastName"
                placeholder="Last Name"
                value={studentDetailsFormik.values.lastName}
                onChange={studentDetailsFormik.handleChange}
              />
            </Form.Group>
            {studentDetailsFormik.errors.lastName &&
            studentDetailsFormik.touched.lastName ? (
              <span className={commonstyles.error}>
                {studentDetailsFormik.errors.lastName}
              </span>
            ) : (
              <span></span>
            )}
          </div>
          <div className={styles.fieldStyle}>
            <Form.Group className="from-group" controlId="formEmail">
              <Form.Control
                type="text"
                name="email"
                placeholder="xyz@example.com"
                value={studentDetailsFormik.values.email}
                onChange={studentDetailsFormik.handleChange}
              />
            </Form.Group>
            {studentDetailsFormik.errors.email &&
            studentDetailsFormik.touched.email ? (
              <span className={commonstyles.error}>
                {studentDetailsFormik.errors.email}
              </span>
            ) : (
              <span></span>
            )}
          </div>
          <div className={styles.fieldStyle}>
            <Form.Group controlId="formPhoneNumber">
              <PhoneInput
                international
                placeholder="Enter phone number"
                value={studentDetailsFormik.values.phoneNumber}
                onChange={(value) => {
                  studentDetailsFormik.setFieldValue(
                    INSTRUCTOR.FORMIK.PHONENUMBER,
                    value
                  );
                }}
              />
            </Form.Group>
            {studentDetailsFormik.errors.phoneNumber &&
            studentDetailsFormik.touched.phoneNumber ? (
              <span className={commonstyles.error}>
                {studentDetailsFormik.errors.phoneNumber}
              </span>
            ) : (
              <span></span>
            )}
          </div>{" "}
          <div style={{ position: "relative" }} className={styles.fieldStyle}>
            <Form.Group className="from-group" controlId="formPassword">
              <Form.Label>Password</Form.Label>
              <Form.Control
                type={showPassword ? "text" : "password"}
                placeholder="Password"
                value={studentDetailsFormik.values.password}
                name="password"
                onChange={studentDetailsFormik.handleChange}
              />
              <span
                onClick={togglePasswordVisibility}
                style={{
                  position: "absolute",
                  top: "70%",
                  right: "10px",
                  cursor: "pointer",
                  transform: "translateY(-50%)",
                }}
              >
                <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
              </span>
            </Form.Group>
            {studentDetailsFormik.errors.password &&
            studentDetailsFormik.touched.password ? (
              <span className={commonstyles.error}>
                {studentDetailsFormik.errors.password}
              </span>
            ) : null}
          </div>
          <hr />
          {studentDetailsFormik.values.userType ===
          STUDENT.STUDENTTYPE.SCHOOL ? (
            <Fragment>
              <div className={styles.fieldStyle}>
                <Form.Group className="from-group" controlId="formUniversity">
                  <Form.Control
                    value={studentDetailsFormik.values.school}
                    as="select"
                    onChange={handleChange}
                  >
                    <option value={0} key={0}>
                      {"Select School"}
                    </option>
                    {school?.map((item, id) => {
                      return (
                        <option value={item.id as number} key={id}>
                          {item.name}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Form.Group>
                {studentDetailsFormik.touched.school &&
                studentDetailsFormik.errors.school ? (
                  <span className={commonstyles.error}>
                    {studentDetailsFormik.errors.school}
                  </span>
                ) : null}
              </div>
            </Fragment>
          ) : null}
          {studentDetailsFormik.values.userType ===
          STUDENT.STUDENTTYPE.UNIVERSITY ? (
            <Fragment>
              <div className={styles.fieldStyle}>
                <Form.Group className="from-group" controlId="formUniversity">
                  <Form.Control
                    value={studentDetailsFormik.values.university}
                    as="select"
                    onChange={handleUniversityChange}
                  >
                    <option value={0} key={0}>
                      {"Select University"}
                    </option>
                    {universities?.map((university, id) => {
                      return (
                        <option value={university.id as number} key={id}>
                          {university.name}
                        </option>
                      );
                    })}
                  </Form.Control>
                </Form.Group>
                {studentDetailsFormik.errors.university &&
                studentDetailsFormik.touched.university ? (
                  <span className={commonstyles.error}>
                    {studentDetailsFormik.errors.university}
                  </span>
                ) : (
                  <span></span>
                )}
              </div>
              {studentDetailsFormik.values.university !== 0 && (
                <div className={styles.fieldStyle}>
                  <Form.Group className="from-group" controlId="formDepartment">
                    <Form.Control
                      value={studentDetailsFormik.values.department}
                      as="select"
                      onChange={handleDepartmentChange}
                    >
                      <option value={0} key={0}>
                        Select Department
                      </option>
                      {department?.map((dep, id) => {
                        return (
                          <option value={dep.id as number} key={id}>
                            {dep.name}
                          </option>
                        );
                      })}
                    </Form.Control>
                  </Form.Group>
                  {studentDetailsFormik.errors.department &&
                  studentDetailsFormik.touched.department ? (
                    <span className={commonstyles.error}>
                      {studentDetailsFormik.errors.department}
                    </span>
                  ) : (
                    <span></span>
                  )}
                </div>
              )}
            </Fragment>
          ) : null}
          {studentDetailsFormik.values.userType ===
            STUDENT.STUDENTTYPE.GENERAL && (
            <div className={styles.fieldStyle}>
              <Form.Group className="from-group" controlId="formUniversity">
                <Form.Control
                  value={studentDetailsFormik.values.trainingField}
                  as="select"
                  onChange={handleTrainingChange}
                >
                  <option value={0} key={0}>
                    Select Training Field
                  </option>
                  {trainingFields?.map((item, id) => {
                    return (
                      <option value={item.id as number} key={id}>
                        {item.name}
                      </option>
                    );
                  })}
                </Form.Control>
              </Form.Group>
              <AddImage
                croppedImage={croppedImage}
                setCroppedImage={setCroppedImage}
                showModal={showModal}
                handleClose={handleClose}
              />
              {studentDetailsFormik.touched.trainingField &&
              studentDetailsFormik.errors.trainingField ? (
                <span className={commonstyles.error}>
                  {studentDetailsFormik.errors.trainingField}
                </span>
              ) : null}
            </div>
          )}
          <div className={styles.fieldStyle}>
            <Form.Group className="from-group" controlId="formFirstName">
              <Form.Control
                type="number"
                name={STUDENT.FORMIK.DEVICERESTRICTION}
                placeholder="Device Restriction"
                value={studentDetailsFormik.values.deviceRestriction}
                onChange={studentDetailsFormik.handleChange}
              />
            </Form.Group>
            {studentDetailsFormik.errors.deviceRestriction &&
            studentDetailsFormik.touched.deviceRestriction ? (
              <span className={commonstyles.error}>
                {studentDetailsFormik.errors.deviceRestriction}
              </span>
            ) : (
              <span></span>
            )}
          </div>
          <div className="row">
            <div className="col-md-12 d-flex align-items-center mb-3">
              <div className="col-md-4 text-end">
                <Form.Label className="fw-bold">Profile Image</Form.Label>
              </div>
              <div className="col-md-8">
                <Button
                  onClick={() => setShowModal(true)}
                  className="ms-3 app-primary-btn"
                >
                  {croppedImage
                    ? "Update Profile Image"
                    : "Upload Profile Image"}
                </Button>
              </div>
            </div>
            {imageFile && (
              <div className="col-md-12 text-center">
                <img
                  src={imageFile}
                  alt="Profile Preview"
                  className="rounded-circle shadow mt-2"
                  style={{ width: "100px", height: "100px" }}
                />
              </div>
            )}
          </div>
            <div className="d-flex justify-content-center">
              <Button className={`app-primary-btn mt-3`} type="submit" style={{ width: '80%' }}>
                {!id ? INSTRUCTOR.ADD : INSTRUCTOR.EDIT}
              </Button>
            </div>
        </Form>
        <AddImage
          croppedImage={croppedImage}
          setCroppedImage={setCroppedImage}
          showModal={showModal}
          handleClose={handleClose}
        />
      </div>
    </Layout>
  );
};

export default AddOrEdit;
