import { Form, Modal } from "react-bootstrap";
import { IMAGE_BASE_URL } from "../../../config";

const DisplayBanner = ({ showModal, handleClose, link, title }: any) => {
  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      centered
      className="custom-modal"
    >
      <div>
        <Modal.Header closeButton>
          <Modal.Title className="fw-bold">Banner Details</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <div className="mb-4">
            <Form.Group>
              <Form.Label className="fw-semibold">Title</Form.Label>
              <p className="border p-2 rounded bg-light fw-normal">{title}</p>
            </Form.Group>
          </div>
          <div className="d-flex justify-content-center">
            <img
              src={`${IMAGE_BASE_URL}${link}`}
              alt="Banner"
              className="img-fluid shadow rounded"
              style={{
                maxHeight: "400px",
                maxWidth: "100%",
                objectFit: "cover",
              }}
            />
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
};

export default DisplayBanner;
