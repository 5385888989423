import React from 'react'

const index = () => {
  return (
    <div style={{padding:"20px"}}>
        <h2 style={{textAlign:"center"}}>Privacy Policy</h2>
        Last Updated: [25/9/2024]
<p>At <b>Bright Future Institute</b>, your privacy is of utmost importance to us. This Privacy Policy
describes how we collect, use, and protect your personal information when you use our mobile
application, website, and any services provided through the platform. By using the App, you agree
to the collection and use of information as outlined in this Privacy Policy.</p>
<h4 >1. Information We Collect</h4>
<p>When you use the Bright Future Institute App, we may collect the following types of information:</p>
<h5>1.1 Personal Information</h5>
<p><b>• Account Registration:</b> Upon registration, we collect information such as your phone number and
specific details based on the type of user:</p>
<p><b>o University Students:</b> We ask for your department.</p>
<p><b>o School Students:</b> We ask for your school level.</p>
<p><b>o General Users:</b> We ask for your training field.</p>
<p><b>• Payment Information:</b> Payments for the physical enrollment are processed externally through
MyFatoorah. We do not store payment information, but we may retain details about transactions,
including payment confirmation and the services purchased.</p>
<h5>1.2 Usage Data</h5>
<p>We may collect non-personally identifiable information about your interaction with the App, such as:</p>
<p><b>• Device Information:</b> Details about your mobile device, including the type of device, operating
system, and unique device identifiers.</p>
<p><b>• Log Data:</b> Information such as IP addresses, browser types, the pages of our App you visit, and the
time spent on those pages.</p>
<h5>1.3 Cookies</h5>
<p>We use cookies to collect data to help us improve your experience on our App. Cookies are small files
stored on your device that allow us to track certain information about your usage. You can disable cookies
in your browser settings, though this may affect the functionality of the App.</p>
<h4>2. How We Use Your Information</h4>
<p>Bright Future Institute uses your personal information to:</p>
<p><b>• Provide and maintain services:</b> We use your information to facilitate access to physical class

enrollment.</p>
<p><b>• Communicate with you:</b> We may contact you through  phone, or within the App to inform
you of updates, respond to inquiries, or provide support.</p>
<p><b>• Process payments:</b> Payments are managed through our third-party payment provider,


MyFatoorah, for physical services.
</p>
<p><b>• Improve the App:</b> We use non-personal data, like usage information, to enhance our services,
troubleshoot issues, and develop new features.</p>
<h4>3. Sharing and Disclosure of Information</h4>
<br/>
We respect your privacy, and your personal information will not be sold or rented to third parties. However,
we may share information in the following cases:
<br/>
<b>• Service Providers:</b> We may share information with third-party service providers, such as

MyFatoorah, for payment processing and other essential services related to physical class

registration.
<br/>
<b>• Legal Requirements:</b> We may disclose your personal information if required by law or to protect

the rights and safety of Bright Future Institute, its users, or others.
<br/>
<h4>4. Data Security</h4>
We are committed to protecting your personal information. While we take reasonable steps to safeguard
your data, no method of transmission over the internet or electronic storage is completely secure. We use

commercially acceptable means to protect your personal information but cannot guarantee absolute

security.

<h4>5. Contact Us</h4>
If you have any questions about this Privacy Policy or the practices of the Bright Future Institute, please
contact us at:
<br/>
<b>• Email:</b> info@brightfuturekw.net
<br/>
<b>• Phone:</b> (+965 96771010), (+965 96777047)
    </div>
  )
}

export default index