import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Button, Form, Modal } from "react-bootstrap";

import { AddUniversityProp } from "./interface";
import { useAppSelector } from "../../../redux/hooks";
import { INSTRUCTOR } from "../../../utils/instructor";
import styles from "../../instructor/styles/instructor.module.css";
import commonstyles from "../../instructor/styles/common.module.css";
import { addUniversity, editUniversity, getUniversity } from "../api";
import { UniversityDetailsSchema } from "../validations";
import { UNIVERSITY } from "../../../utils/university";
import { MESSAGES } from "../../../utils/messages";
import AddImage from "./UploadImageModal";
import { IMAGE_BASE_URL } from "../../../config";

const AddOrEdit = ({ showModal, handleClose }: AddUniversityProp) => {
  const selectedUniversity = useAppSelector((state) => state.universityReducer);

  const [croppedImage, setCroppedImage] = useState<File | null>(null);
  const [imageFile, setImageFile] = useState<string | null>(null);

  const [showModalImage, setShowModalImage] = useState<boolean>(false);

  const handleCloseImage = () => {
    setShowModalImage(false);
  };

  useEffect(() => {
    if (croppedImage) {
      setImageFile(URL.createObjectURL(croppedImage));
    }
  }, [croppedImage]);

  useEffect(() => {
    setCroppedImage(null);

    if (selectedUniversity.editOrAdd === INSTRUCTOR.REDUX.EDIT) {
      getUniversity(Number(selectedUniversity.id)).then((res) => {
        const {
          data: { name, max_installment, device_login_limit, installment_percentage, file },
        } = res;
        universityDetailsFormik.setFieldValue(UNIVERSITY.FORMIK.NAME, name);
        universityDetailsFormik.setFieldValue("max_installment", max_installment);
        universityDetailsFormik.setFieldValue("device_login_limit", device_login_limit);
        universityDetailsFormik.setFieldValue("installment_percentage", installment_percentage);

        if (file) {
          const fileURL = `${IMAGE_BASE_URL}${file}`;
          setImageFile(fileURL);
        }
      });
    } else {
      universityDetailsFormik.resetForm();
      setImageFile(null);
    }
  }, [showModal]);

  const universityDetailsFormik = useFormik({
    initialValues: {
      name: "",
      max_installment: "",
      device_login_limit: "",
      installment_percentage: "",
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      const formData = new FormData();
      const fields = {
        name: values.name,
        max_installment: values.max_installment,
        device_login_limit: values.device_login_limit,
        installment_percentage: values.installment_percentage,
      };

      Object.entries(fields).forEach(([key, value]) => formData.append(key, value));

      if (selectedUniversity.editOrAdd !== INSTRUCTOR.REDUX.ADD) {
        formData.append("_method", "Put");
      }

      if (croppedImage) {
        formData.append("file", croppedImage);
      }

      const isAddOperation = selectedUniversity.editOrAdd === INSTRUCTOR.REDUX.ADD;
      const apiCall = isAddOperation
        ? addUniversity(formData)
        : editUniversity(selectedUniversity.id, formData);

      const messages = isAddOperation
        ? {
            pending: MESSAGES.UNIVERSITY.ADD,
            success: MESSAGES.UNIVERSITY.SUCCESS,
            error: MESSAGES.UNIVERSITY.INVALID,
          }
        : {
            pending: MESSAGES.UNIVERSITY.EDIT,
            success: MESSAGES.UNIVERSITY.EDITED,
            error: MESSAGES.UNIVERSITY.NOTEDITED,
          };

      toast.promise(apiCall, {
        pending: {
          render: () => messages.pending,
        },
        success: {
          render: () => {
            universityDetailsFormik.resetForm();
            handleClose();
            return messages.success;
          },
        },
        error: {
          render: () => messages.error,
        },
      });
    },

    validationSchema: UniversityDetailsSchema,
  });

  const handleAddEditUniversity = async (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    await universityDetailsFormik.submitForm();

    if (Object.keys(universityDetailsFormik.errors).length === 0) {
      handleClose();
    }
  };

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      centered
      size="lg"
      className="custom-modal"
    >
      <div>
        <Modal.Header closeButton>
          <Modal.Title className="text-center w-100">
            {selectedUniversity.editOrAdd === INSTRUCTOR.REDUX.EDIT
              ? "Edit University"
              : "Add New University"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleAddEditUniversity}>
            <div className="mb-4">
              <Form.Group controlId="formName" className="mb-4">
                <Form.Label className="fw-bold">University Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter University Name"
                  value={universityDetailsFormik.values.name}
                  name="name"
                  onChange={universityDetailsFormik.handleChange}
                  className="p-3"
                />
              </Form.Group>

              <Form.Group controlId="formDeviceLimit" className="mb-4">
                <Form.Label className="fw-bold">Device Login Limit</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Enter Device Limit"
                  value={universityDetailsFormik.values.device_login_limit}
                  name="device_login_limit"
                  onChange={universityDetailsFormik.handleChange}
                  className="p-3"
                />
              </Form.Group>

              <Form.Group controlId="formLogo" className="mb-4 text-center">
                <Form.Label className="fw-bold d-block">University Logo</Form.Label>
                <Button
                  onClick={() => setShowModalImage(true)}
                  className="btn-primary mt-2 px-4 py-2"
                >
                  {croppedImage || imageFile ? "Update Logo" : "Upload Logo"}
                </Button>
                {imageFile && (
                  <div className="mt-3 text-center">
                    <img
                      src={imageFile}
                      alt="Selected Logo"
                      style={{
                        width: "120px",
                        height: "120px",
                        objectFit: "cover",
                        borderRadius: "50%",
                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                      }}
                    />
                  </div>
                )}
              </Form.Group>
            </div>

            <div className="text-center">
              <Button
                className="btn-primary px-5 py-3"
                type="submit"
                style={{
                  borderRadius: "25px",
                  fontWeight: "bold",
                  fontSize: "16px",
                  letterSpacing: "1px",
                }}
              >
                {selectedUniversity.editOrAdd === INSTRUCTOR.REDUX.ADD
                  ? INSTRUCTOR.ADD
                  : INSTRUCTOR.EDIT}
              </Button>
            </div>
          </Form>
        </Modal.Body>
        <AddImage
          croppedImage={croppedImage}
          setCroppedImage={setCroppedImage}
          showModal={showModalImage}
          handleClose={handleCloseImage}
        />
      </div>
    </Modal>
  );
};

export default AddOrEdit;
