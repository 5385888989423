import React, { useEffect } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Button, Form, Modal } from "react-bootstrap";

import { AddUniversityProp } from "./interface";
import { useAppSelector } from "../../../../../redux/hooks";
import { INSTRUCTOR } from "../../../../../utils/instructor";
import { addSegmentation, editSegmentation, getSpecificSegment } from "../api";
import SegmentationDetailsSchema from "../validations";
// import { INSTRUCTOR } from "../../../utils/instructor";
import styles from "../../../../instructor/styles/instructor.module.css";
import commonstyles from "../../../../instructor/styles/common.module.css";
// import { addUniversity, editUniversity, getUniversity } from "../api";
// import DepartmentDetailsSchema from "../validations";
// import { UNIVERSITY } from "../../../utils/university";
import { MESSAGES } from "../../../../../utils/messages";
import { COUPONS } from "../../../../../utils/coupons";
import { useParams } from "react-router-dom";

const AddOrEdit = ({ showModal, handleClose }: AddUniversityProp) => {
	const {id}=useParams()
	 const selectedSegment = useAppSelector((state) => state.segmentationReducer);

	useEffect(() => {
		if (selectedSegment.editOrAdd === INSTRUCTOR.REDUX.EDIT) {
			getSpecificSegment(Number(selectedSegment.id)).then((res) => {
				const {
					data
				} = res;
				segmentDetailsFormik.setValues(data)
			});
		} else {
			segmentDetailsFormik.resetForm();
		}
	}, [showModal]);

	const segmentDetailsFormik = useFormik({
		initialValues: {
			min: 0,
			max:0,
			discount:0,
			discount_type:1
		},
		enableReinitialize: true,
		onSubmit: (values) => {
			toast.promise(
				selectedSegment.editOrAdd === INSTRUCTOR.REDUX.ADD
					? addSegmentation(values,Number(id))
					: editSegmentation(selectedSegment.id as number, values,Number(id)),
				{
					pending: {
						render() {
							return	 selectedSegment.editOrAdd === INSTRUCTOR.REDUX.ADD
								? MESSAGES.COUPON.SEGEMENTATION.ADD
								: MESSAGES.COUPON.SEGEMENTATION.EDIT;
						},
					},
					success: {
						render() {
							segmentDetailsFormik.resetForm();
							handleClose();

							return selectedSegment.editOrAdd === INSTRUCTOR.REDUX.ADD
								? MESSAGES.COUPON.SEGEMENTATION.ADD_SUCCESS
								: MESSAGES.COUPON.SEGEMENTATION.EDIT_SUCCESS;
						},
					},
					error: {
						render() {
							return selectedSegment.editOrAdd === INSTRUCTOR.REDUX.ADD
								? MESSAGES.COUPON.SEGEMENTATION.ADD_INVALID
								: MESSAGES.COUPON.SEGEMENTATION.EDIT_INVALID
						},
					},
				}
			);
		},

		validationSchema: SegmentationDetailsSchema,
	});
	const handleAddDepartment = (e: React.SyntheticEvent<HTMLFormElement>) => {
		e.preventDefault();

		segmentDetailsFormik.submitForm();
	};

	return (
		<Modal
			show={showModal}
			onHide={() => {
				handleClose();
			}}
		>
			<div>
				<Modal.Header closeButton>
					<Modal.Title>
						{selectedSegment.editOrAdd === INSTRUCTOR.REDUX.EDIT
							? "Edit Segment"
							: "Add New Segment"}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={handleAddDepartment}>
						<div className="form-group">
							<div className={styles.fieldStyle}>
								<Form.Group className="from-group" controlId="formFirstName">
									<Form.Label>Minimum Members</Form.Label>
									<Form.Control
										className={styles.hideArrow}
										type="number"
										placeholder="Minumum Members"
										value={segmentDetailsFormik.values.min}
										name={COUPONS.FORMIK.SEGMENTS.MIN}
										onChange={segmentDetailsFormik.handleChange}
									/>
								</Form.Group>
								{segmentDetailsFormik.errors.min &&
              segmentDetailsFormik.touched.min ? (
										<span className={commonstyles.error}>
											{segmentDetailsFormik.errors.min}
										</span>
									) : null}
							</div>
						</div>
						
						<div className={styles.fieldStyle}>
							<Form.Group className="from-group" controlId="formFirstName">
								<Form.Label>Maximum Members</Form.Label>
								<Form.Control
									className={styles.hideArrow}
									type="number"
									placeholder="Maximum Members"
									value={segmentDetailsFormik.values.max}
									name={COUPONS.FORMIK.SEGMENTS.MAX}
									onChange={segmentDetailsFormik.handleChange}
								/>
							</Form.Group>
							{segmentDetailsFormik.errors.max &&
              segmentDetailsFormik.touched.max ? (
									<span className={commonstyles.error}>
										{segmentDetailsFormik.errors.max}
									</span>
								) : null}
						</div>
						<div className={styles.fieldStyle}>
							<Form.Group className="from-group" controlId="formFirstName">
								<Form.Label>Discount</Form.Label>
								<Form.Control
									className={styles.hideArrow}
									type="number"
									placeholder="Discount"
									value={segmentDetailsFormik.values.discount}
									name={COUPONS.FORMIK.SEGMENTS.DISCOUNT}
									onChange={segmentDetailsFormik.handleChange}
								/>
							</Form.Group>
							{segmentDetailsFormik.errors.discount &&
              segmentDetailsFormik.touched.discount ? (
									<span className={commonstyles.error}>
										{segmentDetailsFormik.errors.discount}
									</span>
								) : null}
						</div>
						
						<Button className={`${styles.buttonss} mt-3`} type="submit">
							{selectedSegment.editOrAdd === INSTRUCTOR.REDUX.ADD
								? INSTRUCTOR.ADD
								: INSTRUCTOR.EDIT}
						</Button>
					</Form>
				</Modal.Body>
			</div>
		</Modal>
	);
};

export default AddOrEdit;
