export const APP_ROUTES = {
	LOGIN: "/",
	PROFILE: "/profile",
	INSTRUCTORS_LIST: "/instructors",
	INSTRUCTORS_ADD:"/instructors/add",
	INSTRUCTORS_EDIT:"/instructors/:id",
	INSTRUCTORS_COURSES:"/instructors/courses/:id",
	INSTRUCTORS_COURSES_STUDENTLIST:"/instructors/courses/studentlist/:id",
	INSTRUCTORS_CHAPTERS:"/instructors/courses/chapter/:id",
	INSTRUCTOR_CHAPTERINFO:"/instructors/courses/chapter/chapterinfo/:id",
	INVOICE_LIST:"/invoice",
	STUDENTS_LIST: "/students",
	SPECIFIC_STUDENT:"/students/:id",
	SPECIFIC_STUDENT_DEVICES:"/students/devices/:id",
	COURSE_INSTALLMENT:"/students/course/instalments/:userId/:orderId/:courseId",
	STUDENTS_EDIT:"/students/edit/:id",
	STUDENTS_ADD:"/students/add",
	COURSES_LIST: "/courses",
	ADDON_LIST:"/courses/addons",
	SPECIFIC_COURSE: "/courses/:id",
	UNIVERSITY_LIST:"/universities",
	SPECIFIC_UNIVERSITY:"/universities/:id",
	COUPONS_LIST:"/coupons",
	SEGMENTS:"/segments",
	SEGMENTS_SPECIFIC:"segments/:id",
	TRANSACTIONS:"/transactions",
	SEMESTERS:"/universities/semesters"
};