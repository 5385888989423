import * as Yup from "yup";
import commonSchema from "../../common/validations";
import { YUP } from "../../../utils/validations";
import { isValidPhoneNumber } from "react-phone-number-input";
import { isPossiblePhoneNumber } from "react-phone-number-input";

export const InstructorDetailsSchema = commonSchema.concat(Yup.object().shape({
	 universities: Yup.object().shape({
        // Validate each key in the universities object
        // Here we use `Yup.object()` to define a schema for the values of each key
    }).test('validate-universities', 'Each university must have a valid id and non-empty dep array', function (universities) {
        const keys = Object.keys(universities || {});
        for (const key of keys) {
			//@ts-ignore
            const { id, dep } = universities[key];
			console.log(id,"uni id")
            if (id === "0" || id===0) {
                return this.createError({ path: `universities.${key}.id`, message: "Required" });
            }
            if (!Array.isArray(dep) || dep.length === 0) {
                return this.createError({ path: `universities.${key}.dep`, message: "Required" });
            }
        }
        return true; // All validations passed
    }),
	// department: Yup.number().required(YUP.REQUIRED).min(1, YUP.REQUIRED),
	
	
}))
export const InstructorUpdateDetailsSchema = Yup.object().shape({
	firstName: Yup.string().required(YUP.REQUIRED),
	lastName: Yup.string().required(YUP.REQUIRED),
	phoneNumber: Yup
		.string()
		.required(YUP.REQUIRED)
		.test("validate-number", function (value) {
			if (!isValidPhoneNumber(value) || !isPossiblePhoneNumber(value))
			 return this.createError({ message: YUP.PHONENUMBER, path: this.path });
			return true;
		}),
	
});


