import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { IMAGE_BASE_URL } from "../../../config";

const DisplayStory = ({ showModal, handleClose, link, title, thumbnail }: any) => {
  const [images, setImages] = useState<string[]>([]);

  useEffect(() => {
    if (Array.isArray(link)) {
      setImages(link);
    } else if (link) {
      setImages([link]);
    }
  }, [link]);

  return (
    <Modal
      show={showModal}
      onHide={handleClose}
      centered
      className="custom-modal"
      backdrop="static"
    >
      <div className=" ">
        <Modal.Header closeButton className="border-0 py-3 px-4">
          <Modal.Title className="fw-bold text-center w-100">View Story</Modal.Title>
        </Modal.Header>


        <Modal.Body className="p-4">

          <div className="mb-4">
            <label className="form-label fw-semibold">Title</label>
            <input
              type="text"
              className="form-control shadow-sm rounded"
              value={title}
              readOnly
            />
          </div>
          <div className="mb-4">
            <h6 className="fw-semibold text-muted mb-3">Images</h6>
            <div className="d-flex flex-wrap justify-content-start gap-3">
              <div className="text-center">
                <img
                  src={`${IMAGE_BASE_URL}${images[0]}`}
                  alt="Current Image"
                  className="rounded shadow"
                  style={{
                    width: "150px",
                    height: "150px",
                    objectFit: "cover",
                    border: "2px solid #f0f0f0",
                  }}
                />
                <small className="d-block mt-2 text-muted">Current Image</small>
              </div>
              <div className="text-center">
                <img
                  src={`${IMAGE_BASE_URL}${thumbnail}`}
                  alt="Current Thumbnail"
                  className="rounded shadow"
                  style={{
                    width: "150px",
                    height: "150px",
                    objectFit: "cover",
                    border: "2px solid #f0f0f0",
                  }}
                />
                <small className="d-block mt-2 text-muted">Current Thumbnail</small>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="border-0 d-flex justify-content-center pb-4">
          <button
            className="btn btn-primary px-5 py-2 fw-bold shadow"
            style={{ borderRadius: "30px" }}
            onClick={handleClose}
          >
            Close
          </button>
        </Modal.Footer>
      </div>
    </Modal>
  );
};

export default DisplayStory;
