import React, { useEffect, useState } from "react";
import moment from "moment";
//import { useNavigate } from "react-router-dom";
import { Button, Dropdown, Form, InputGroup, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faEdit,
  faEllipsisH,
  faInfoCircle,
  faPlus,
  faRemove,
  faSearch,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

//import { toast } from "react-toastify";
// import { courseList, removeCourse } from "../api";
// import { CourseDeatails } from "../api/interface";
import Layout from "../../../components/Layout";
import commonStyles from "../../common/styles/common.module.css";
import Pagination from "../../../components/Pagination";
import { PaginationInterface } from "../../../components/Pagination/interfaces";
// import { PriceModal } from "../modals";
import { courseModal } from "../../../redux/reducers/course";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
// import { MESSAGES } from "../../../utils/messages";
// import { ROUTE_CONSTANTS } from "../../../routes/constants";
import { INSTRUCTOR } from "../../../utils/instructor";
import AddorEdit from "../modals/AddorEdit";
import { couponList } from "../api";
import { CouponResponse } from "../api/interface";
import { couponModal } from "../../../redux/reducers/coupons";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "../../../utils/routes";
import ChangeTimer from "../modals/ChangeTimer";
import { universityList } from "../../university/api";
import { deleteCoupon } from "../api";
import { toast } from "react-toastify";
import { MESSAGES } from "../../../utils/messages";
import ConfirmationModal from "../../../components/common/ConfirmationModal";

const CouponsList = () => {
  const dispatch = useAppDispatch();

  const [coupons, setCoupons] = useState<CouponResponse[]>([]);
  //const [priceModal, setPriceModal] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  const [showTimerModal, setShowTimerModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();
  const [search, setSearch] = useState<string>("");
  const [searchValue, setSearchValue] = useState<string>("");
  const searchText = useAppSelector((state) => state.searchReducer.text);
  const [filterType, setFilterType] = useState<Number>(0);
  const [universityId, setUniversityId] = useState<Number>(0);

  const [universities,setUniversities]=useState<any[]>([])
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [couponToDelete, setCouponToDelete] = useState<number | null>(null);

  const openDeleteModal = (id: number) => {
    setCouponToDelete(id);
    setShowDeleteModal(true);
  };

  const confirmDelete = () => {
    if (couponToDelete !== null) {
      toast.promise(deleteCoupon(couponToDelete), {
        pending: MESSAGES.COUPON.REMOVING,
        success: {
          render() {
            refresh();
            return MESSAGES.COUPON.REMOVED;
          },
        },
        error: MESSAGES.COUPON.NOTREMOVED,
      });
    }
    setShowDeleteModal(false);
    setCouponToDelete(null);
  };

  useEffect(() => {
    setSearchValue(searchText as string);
  }, [searchText]);

  useEffect(() => {
    universityList({}).then((res)=>{
      setUniversities(res.data)
    })
  }, [])


  // const [showModal, setShowModal] = useState<boolean>(false)
  const [pagination, setPagination] = useState<PaginationInterface>({
    page: 1,
    limit: 10,
    count: 0,
    totalPages: 0,
  });
  const addNewCourse = () => {
    dispatch(couponModal({ id: 0, editOrAdd: INSTRUCTOR.REDUX.ADD }));
    setShowModal(true);
  };
  const addNewTimer = () => {
    setShowTimerModal(true);
  };

  const refresh = () => {
    setLoading(true);
    const query = {
      per_page: pagination.limit,
      page: pagination.page,
      code: searchValue,

    };

    couponList(query,filterType,universityId).then((res) => {
      const {
        paginate: { total, last_page },
        data,
      } = res;
      setPagination({ ...pagination, count: total, totalPages: last_page });
      setCoupons(data);
      setLoading(false);
    });
  };

  const handleAddClose = () => {
    setShowModal(false);
  };

  const handleTimerClose = () => {
    setShowTimerModal(false);
  };


  useEffect(() => {
    refresh();
  }, [pagination.page, searchValue,filterType,universityId]);

  useEffect(() => {
    if (showModal === false) refresh();
  }, [showModal]);
  useEffect(() => {
    if (showDeleteModal === false) refresh();
  }, [showDeleteModal]);

  return (
    <Layout>
      <div className={commonStyles["outtre-pad"]}>
        <div className={commonStyles["heaing-filter"]}>
          <h3>Coupons Management</h3>
          <div className={commonStyles["filter-left"]}>
            <span>
              Type:{" "}
              <select onChange={(e)=>{setFilterType(Number(e.target.value))}}>
              <option value={0}>
										{"Select"}
									</option>

              {/* <option value={1}>
										{"Group"}
									</option> */}
									<option value={2}>
										{"Course Discount"}
									</option>

									<option value={3}>
										{"Special Package"}
									</option>

									<option value={6}>
										{"Simple Coupon"}
									</option>
              </select>
            </span>
            <span>
              University:{" "}
              <select onChange={(e)=>{setUniversityId(Number(e.target.value))}}>
              <option value={0}>
										{"Select"}
									</option>
									{
                    universities.map((uni)=>{
                      return <option value={uni.id}>{uni.name}</option>
                    })
                  }
              </select>
            </span>
          </div>
        </div>
        {!loading ? (
          <div className={`light-theme-table ${commonStyles["table-data"]}`}>
            <div className={`${commonStyles["property-head"]} pb-1`}>
              <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                <li className="nav-item" role="presentation">
                </li>
              </ul>

              <div className={commonStyles["filter-pro"]}>
                <button
                  onClick={addNewCourse}
                  className={commonStyles["add-lead"]}
                >
                  <FontAwesomeIcon icon={faPlus} /> Add New Coupon
                </button>
              </div>
            </div>
            <div className="tab-content" id="pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
              >
                <div style={{ overflowX: "auto" }}>
                  <table>
                    <thead>
                      <tr>
                        <th>Code</th>
                        <th>Type</th>
                        <th>Expiry Date</th>

                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {coupons.map((item) => {
                        return (
                          <tr key={item.id}>
                            <td>{item.code}</td>
                            <td>{item.type}</td>
                            <td>
                              {moment(item.expiry_date).format("MMM DD, YYYY")}
                            </td>
                            <td>
                              <Dropdown
                                drop={"down-centered"}
                                className={commonStyles.tddropdown}
                              >
                                <Dropdown.Toggle>
                                  <a className="me-2"></a>
                                  <FontAwesomeIcon icon={faEllipsisH} />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>

                                  <Dropdown.Item
                                    onClick={() => {
                                      dispatch(
                                        couponModal({
                                          id: item.id,
                                          editOrAdd: INSTRUCTOR.REDUX.EDIT,
                                        })
                                      );
                                      setShowModal(true);
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      onClick={() => {
                                        dispatch(
                                          courseModal({
                                            id: item.id,
                                            editOrAdd: INSTRUCTOR.REDUX.EDIT,
                                          })
                                        );
                                        setShowModal(true);
                                      }}
                                      size="xs"
                                      className={commonStyles.iconmargin}
                                      icon={faEdit}
                                    />
                                    Edit
                                  </Dropdown.Item>
                                  <Dropdown.Item onClick={() => openDeleteModal(item.id)}>
                                    <FontAwesomeIcon
                                      size="xs"
                                      className={commonStyles.iconmargin}
                                      icon={faTrash}
                                    />
                                    Remove
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" role="status"></Spinner>
          </div>
        )}
      </div>

      <Pagination
        paginationState={pagination}
        setPaginationState={setPagination}
      />
      {/* <PriceModal handleClose={handleClose} showModal={priceModal} /> */}
      <AddorEdit showModal={showModal} handleClose={handleAddClose} />

      <ConfirmationModal
        show={showDeleteModal}
        title="Delete Coupon"
        message="Are you sure you want to delete this coupon? This action cannot be undone."
        onConfirm={confirmDelete}
        onCancel={() => setShowDeleteModal(false)}
        confirmButtonText="Delete"
        cancelButtonText="Cancel"
      />

      <ChangeTimer showModal={showTimerModal} handleClose={handleTimerClose} />
    </Layout>
  );
};
export default CouponsList;
