import { axios } from "../../../lib/axios";
import { API_ROUTES } from "../../../utils/api_routes";
import { Pagination } from "../../common/interface";
import { RemoveInstructorRespose } from "../../instructor/api/interface";
import {  CouponListResponse, CouponDetails, SpecificCouponResponse, TimerResponse } from "./interface";

// export const addCoupon=(params: CouponDetails):Promise<RemoveInstructorRespose>=>{
// 	return axios.post(API_ROUTES.COUPONS.GETLIST, params);
// }

export const addCoupon=( formData: FormData): Promise<RemoveInstructorRespose>=>{
	return axios.post(API_ROUTES.COUPONS.GETLIST,formData, {
		headers:{"Content-Type":"multipart/form-data",
		},
	});
};


export const couponList = (query: Pagination,type:Number,university_id:any): Promise< CouponListResponse> => {
	return axios.get(API_ROUTES.COUPONS.GETLIST, { params: {...query,...(type!==0&&{type}),...(university_id!==0&&{university_id: university_id})} });
};

export const coupon = (id:string,detail:any): Promise<SpecificCouponResponse> => {

	return axios.get(`${API_ROUTES.COUPONS.GETLIST}/${id}`,{params:{...detail}});
};
export const deleteCoupon = (id:Number): Promise<any> => {

	return axios.delete(`${API_ROUTES.COUPONS.GETLIST}/${id}`);
};
// export const editCoupon=(params:CouponDetails, id:number):Promise<RemoveInstructorRespose>=>{
// 	return axios.put(`${API_ROUTES.COUPONS.GETLIST}/${id}`, params);
// };

export const editCoupon=( formData: FormData, id:number): Promise<RemoveInstructorRespose>=>{
	return axios.post(`${API_ROUTES.COUPONS.GETLIST}/${id}`,formData, {
		headers:{"Content-Type":"multipart/form-data",
		},
	});
};

export const getTimer=():Promise<TimerResponse>=>{
	return axios.get(`${API_ROUTES.COUPONS.GETTIMER}`);

}
// export const setTimer=(duration:number,type:number):Promise<any>=>{
// 	axios.post(`${"/admin/set-timer"}`,{duration,type})
// }
export const setTimer=(duration:number, type:number)=>{
	return axios.post(`${API_ROUTES.COUPONS.SETTIMER}`, {duration, type});
};