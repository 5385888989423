import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Modal, Form, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useAppSelector } from "../../../redux/hooks";
import { editStory, getSpecificStory } from "../api";
import { StoryEditSchema } from "../validations";
import AddImage from "./UploadImageModal";
import { IMAGE_BASE_URL } from "../../../config";

const EditStory = ({
  showModal,
  handleClose,
}: {
  showModal: boolean;
  handleClose: () => void;
}) => {
  const selectedSegment = useAppSelector(
    (state) => state.segmentationReducer.id
  );
  const { id } = useParams();

  const [uploadedImages, setUploadedImages] = useState<File[]>([]);
  const [currentImageURL, setCurrentImageURL] = useState<string>("");
  const [currentThumbnailURL, setCurrentThumbnailURL] = useState<string>("");
	const [storyId, setStoryId] = useState<number>(0);
  const [croppedImageThumbnail, setCroppedImageThumbnail] =
    useState<File | null>(null);

  const [showModalImageThumbnail, setShowModalImageThumbnail] =
    useState<boolean>(false);
  const handleCloseImageThumbnail = () => {
    setShowModalImageThumbnail(false);
  };

  const departmentDetailsFormik = useFormik({
    initialValues: {
      Title: "",
      image: null,
      thumbnail: null,
    },
    validationSchema: StoryEditSchema,
    onSubmit: (values) => {
      const formData = new FormData();

      const fields = {
        title: values.Title,
        university_id: Number(id),
        source: uploadedImages,
        thumbnail: croppedImageThumbnail,
        media_type: 1,
      };

      Object.entries(fields).forEach(([key, value]) => {
        if (value !== null) {
          if (Array.isArray(value)) {
            value.forEach((file) => formData.append(key, file));
          } else {
            formData.append(
              key,
              value instanceof File ? value : value.toString()
            );
          }
        }
      });

      toast.promise(editStory(storyId, formData), {
        pending: {
          render() {
            return "Editing Story";
          },
        },
        success: {
          render() {
            departmentDetailsFormik.resetForm();
            handleClose();

            return "Successfully Edited Story";
          },
        },
        error: {
          render() {
            return "Failed to Edit story";
          },
        },
      });
    },
  });

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.files) {
      const files = Array.from(event.currentTarget.files);
      const validImages = files.filter((file) =>
        file.type.startsWith("image/")
      );
      if (validImages.length > 0) {
        setUploadedImages((prev) => [...prev, ...validImages]);
      } else {
        toast.error("Please upload valid image files.");
      }
    }
  };

  useEffect(() => {
    if (Number(selectedSegment) !== 0) {
      getSpecificStory(selectedSegment as number).then((res) => {
        const { data } = res;
        departmentDetailsFormik.setFieldValue("Title", data.title);
        setCurrentImageURL(data.source);
				setStoryId(data.id);
        setCurrentThumbnailURL(data.thumbnail);
      });
    }

    departmentDetailsFormik.resetForm();
    setUploadedImages([]);
    setCroppedImageThumbnail(null);
  }, [showModal, selectedSegment]);

  return (
<Modal
  show={showModal}
  onHide={handleClose}
  centered
  className="custom-modal"
>
  <Modal.Header closeButton>
    <Modal.Title className="fw-bold">Edit Story</Modal.Title>
  </Modal.Header>
  <Modal.Body>
    <Form onSubmit={departmentDetailsFormik.handleSubmit}>
      {/* Title Field */}
      <div className="mb-4">
        <Form.Group controlId="formStoryTitle">
          <Form.Label className="fw-semibold">Title</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter Story Title"
            value={departmentDetailsFormik.values.Title}
            name="Title"
            onChange={departmentDetailsFormik.handleChange}
            onBlur={departmentDetailsFormik.handleBlur}
            className="form-control shadow-sm"
          />
          {departmentDetailsFormik.errors.Title &&
          departmentDetailsFormik.touched.Title ? (
            <small className="text-danger">
              {departmentDetailsFormik.errors.Title}
            </small>
          ) : null}
        </Form.Group>
      </div>

      <div className="mb-4">
        <Form.Group controlId="formStoryImages">
          <Form.Label className="fw-semibold">Images</Form.Label>

          <div className="d-flex flex-wrap gap-3 mt-3">
            <div className="text-center">
              <img
                src={
                  uploadedImages.length > 0
                    ? URL.createObjectURL(uploadedImages[0])
                    : `${IMAGE_BASE_URL}${currentImageURL}`
                }
                alt="Story Image"
                className="rounded shadow"
                style={{
                  width: "150px",
                  height: "150px",
                  objectFit: "cover",
                }}
              />
              <small className="d-block text-muted">
                {uploadedImages.length > 0 ? "New Story Image" : "Current Image"}
              </small>
            </div>

            <div className="text-center">
              <img
                src={
                  croppedImageThumbnail
                    ? URL.createObjectURL(croppedImageThumbnail)
                    : `${IMAGE_BASE_URL}${currentThumbnailURL}`
                }
                alt="Thumbnail"
                className="rounded shadow"
                style={{
                  width: "150px",
                  height: "150px",
                  objectFit: "cover",
                }}
              />
              <small className="d-block text-muted">
                {croppedImageThumbnail
                  ? "New Thumbnail"
                  : "Current Thumbnail"}
              </small>
            </div>
          </div>
        </Form.Group>
      </div>

      <div className="mb-4">
        <Form.Group controlId="formNewStoryImages">
          <Form.Label className="fw-semibold">Upload New Images</Form.Label>
          <Form.Control
            type="file"
            accept="image/*"
            multiple
            onChange={handleImageUpload}
            className="form-control shadow-sm"
          />
        </Form.Group>
      </div>

      <div className="mb-4">
        <Form.Group controlId="formStoryThumbnail">
          <Form.Label className="fw-semibold">Upload New Thumbnail</Form.Label>
          <Button
            onClick={() => setShowModalImageThumbnail(true)}
            className="btn btn-secondary w-100 mt-2 shadow-sm"
          >
            {croppedImageThumbnail ? "Update Thumbnail" : "Upload Thumbnail"}
          </Button>
        </Form.Group>
      </div>

      <div className="text-center">
        <Button
          type="submit"
          className="btn btn-primary w-50 shadow-sm fw-bold"
        >
          Edit Story
        </Button>
      </div>
    </Form>
  </Modal.Body>
  <AddImage
    croppedImage={croppedImageThumbnail}
    setCroppedImage={setCroppedImageThumbnail}
    showModal={showModalImageThumbnail}
    handleClose={handleCloseImageThumbnail}
  />
</Modal>

  );
};

export default EditStory;
