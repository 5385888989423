import React from 'react'

const index = () => {
  return (
    <div style={{padding:"20px"}}>
        <h2 style={{textAlign:"center"}}>Terms & Conditions </h2>
        <br/>

    Last Updated: [25/9/2024]
    <br/>

    
   
    Welcome to the Bright Future Institute mobile application (the "App"). By downloading, accessing, or using

the App, you agree to comply with and be bound by these Terms & Conditions (the "Terms"). The Terms

govern your use of the App, our website, and any services provided through the platform (collectively, the

"Services"). The Services include class enrollment for physical attendance at our institute and classes are

managed by instructors though WhatsApp groups upon registration. If you do not agree to these Terms,

please refrain from using the App.
    <br/>
    ________________________________________
    <h4>1.	Acceptance of Terms</h4>
    By using the Bright Future Institute App, you confirm that you have read, understood, and agree to be bound

by these Terms. Bright Future Institute reserves the right to modify these Terms at any time, and such

changes will be effective upon posting within the App. Continued use of the App after such updates

constitutes your acceptance of the revised Terms.    <h4>2. Overview of Services </h4>
Bright Future Institute is an educational platform which facilitate enrollment to courses conducted within

our place. The app also allows for class management where students can see their class details within app

as scheduled and agreed on WhatsApp.
    <h4>3. Account Registration</h4>

    You agree to provide accurate, current, and complete information during registration such as and to update

this information as necessary whether you are a university student, school student or an employee seeking

to gain knowledge. You are responsible for keeping your login credentials confidential and for all activities

that occur under your account.
<br/>
    
    <h4>4. Payment and Fees</h4>
    <h5>4.1 Enrollment methods</h5>
    Payments for class enrollment in our institute are processed externally through MyFatoorah. We provide

two methods of payment either full payment or payment by installment in smaller amounts to help our

students enroll with ease.
    <h5>4.2 Payment Terms</h5>
    
We also offer an installment payment option for students, allowing payment in smaller amounts over

time. Students who fail to pay the enrollment fee at the due time will not be able to continue attending

classes at our institute until the payment is completed. All payments are non-refundable, except as

under certain circumstances.
    <br/>
    <h4>5. User Conduct and Responsibilities</h4>
    <b> By using the App, you agree:</b>
    <br/>
    •	To use the App for lawful purposes only.
    <br/>

    • Not to write any disrespectful reviews of instructors within app.
    <br/>
    •	Not to disrupt or interfere with the App's operations or servers.
    <br/>
    •	Not to impersonate any individual or entity or misrepresent your affiliation with any person or organization.
    <h4>6. User-Generated Content</h4>
    If you upload, post, or submit any content to the App (such as reviews or comments), you grant Bright

Future Institute a worldwide, non-exclusive, royalty-free license to use, reproduce, display, and distribute

that content in connection with the App and its services.
    <h4>7. Privacy Policy</h4>

    Your use of the App is also governed by our Privacy Policy, which describes how we collect, use, and

protect your personal data. Please read the Privacy Policy carefully to understand our practices.
    <h4>8. Termination</h4>

    We reserve the right to terminate or suspend your account or access to the App without notice if we believe

you have violated these Terms or if your use of the App harms other users or violates any applicable laws

or regulations.    <h4>9.  Refund Policy</h4>
Refunds for course enrollment are generally not allowed, except under specific circumstances. To request

a refund, you must submit a formal request to the App administration ("Admin") through the appropriate

contact channels like WhatsApp or support website URL (https://brightfuture-kw.com/support/). Each

request will be reviewed on a case-by-case basis, and refunds may be granted at the sole discretion of

Bright Future Institute if the request is deemed valid (e.g., technical issues, course cancellations).
    <h4>10. Modifications to the Services</h4>
    Bright Future Institute reserves the right to modify or discontinue any part of the App or Services at any

time without prior notice. We are not liable for any changes, suspension, or discontinuation of the App.
   
    <h4>11. Contact Information</h4>
    If you have any questions about these Terms, please contact us at:
    <br/>
    <b>•	Email:</b> info@brightfuturekw.net
    <br/>
    <b>•	Phone:</b> (+965 96771010), (+965 96777047)
    <br/>
    <b>•	Address:</b> Kuwait, Sabah Al-Salem, Block 2, Street 208, Building 199, 1st Floor
    </div>
  )
}

export default index