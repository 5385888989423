export const MESSAGES = {
	LOGIN: {
		VERIFY: "Verifying Admin",
		SUCCESS: "Login Successful",
		INVALID: "Invalid Credentials"
	},
	PASSWORD:{
		CHANGE:"Password Change in progress",
		CHANGED:"Password Changed Successfully",
		NOTCHANGED:"Password Change unsuccessful"
	},
	INSTRUCTOR: {
		ADD: "Adding Instructor",
		SUCCESS: "Instructor Added Successfully",
		INVALID: "Instructor Not Added",
		REMOVING: "Removing Instructor",
		REMOVED: "Instructor Removed Successfully",
		NOTREMOVED: "Instructor Not Removed",
		EDIT: "Editing Instructor",
		EDITED: "Instructor Edited Successfully",
		NOTEDITED: "Instructor Not Edited"
	},
	STUDENT: {
		ADD: "Adding Student",
		SUCCESS: "Student Added Successfully",
		INVALID: "Student Not Added",
		REMOVING: "Removing Student",
		REMOVED: "Student Removed Successfully",
		NOTREMOVED: "Student Not Removed",
		EDIT: "Editing Student",
		EDITED: "Student Edited Successfully",
		NOTEDITED: "Student Not Edited",
		ACTIVATE:"Activating Profile",
		ACTIVATED:"Profile Activated Successfully",
		NOTACTIVATED:"Profile Not Activated",
		DEACTIVATE:"Deactivating Profile",
		DEACTIVATED:"Profile Deactivated Successfully",
		NOTDEACTIVATED:"Profile Not Deactivated",
		ADDCOURSE:"Adding Course",
		ADDEDCOURSE:"Course Added Successfully",
		NOTADDEDCOURSE:"Course Not Added",
		INSTALLMENT:{
			DELETE:"Deleting Installment",
			DELETED:"Installment Deleted Successfully",
			FAILED:"Failed",
			UPDATE:"Updating Installment",
			UPDATED:"Installment Updated Successfully",
		}

	},
	PRICE: {
		APPROVING: "Approving Price",
		APPROVED: "Approved Price",
		NOTAPPROVED: "Price Not Approved"
	},

	STORY: {
		REMOVE: "Removing Story",
		REMOVING: "Removing Story",
		REMOVED: "Story Removed Successfully",
		NOTREMOVED: "Story Not Removed",
		COURSESTATUS:"Changing Story status",
		COURSESTATUSCHANGED:"Story Status Changed",
		NOTCOURSESTATUS:"Story Status Not Changed",
		ADD:"Adding Story",
		EDIT:"Editing Story",
		ADDED:"Story Added Successfully",
		EDITED:"Story Edited Successfully",
		NOTADDED:"Story Not Added",
		NOTEDITED:"Story Not Edited",
	},

	COURSE: {
		REMOVE: "Removing Course",
		REMOVING: "Removing Course",
		REMOVED: "Course Removed Successfully",
		NOTREMOVED: "Course Not Removed",
		COURSESTATUS:"Changing course status",
		COURSESTATUSCHANGED:"Course Status Changed",
		NOTCOURSESTATUS:"Course Status Not Changed",
		ADD:"Adding Course",
		EDIT:"Editing Course",
		ADDED:"Course Added Successfully",
		EDITED:"Course Edited Successfully",
		NOTADDED:"Course Not Added",
		NOTEDITED:"Course Not Edited",
		ADDON:{
			REMOVE: "Removing Add-On",
			REMOVED: "Add-On Removed Successfully",
			NOTREMOVE: "Add-On Not Removed",
			ADD:"Adding Add-On",
			EDIT:"Editing Add-On",
			ADDED:"Add-On Added Successfully",
			EDITED:"Add-On Edited Successfully",
			NOTADDED:"Add-On Not Added",
			NOTEDITED:"Add-On Not Edited",
		}
	},
	CHAPTER:{
		EDIT:"Editing Chapter",
		EDITED:"Chapter Edited Successfully",
		NOTEDITED:"Chapter Not Edited"
	},
	UNIVERSITY: {
		ADD: "Adding University",
		SUCCESS: "University Added Successfully",
		INVALID: "University Not Added",
		REMOVING: "Removing University",
		REMOVED: "University Removed Successfully",
		NOTREMOVED: "University Not Removed",
		EDIT: "Editing University",
		EDITED: "University Edited Successfully",
		NOTEDITED: "University Not Edited"
	},
	DEPARTMENT:{
		ADD: "Adding Department",
		SUCCESS: "Department Added Successfully",
		INVALID: "Department Not Added",
		REMOVING: "Removing Department",
		REMOVED: "Department Removed Successfully",
		NOTREMOVED: "Department Not Removed",
		EDIT: "Editing Department",
		EDITED: "Department Edited Successfully",
		NOTEDITED: "Department Not Edited"
	},
	CATEGORY:{
		ADD: "Adding Category",
		SUCCESS: "Category Added Successfully",
		INVALID: "Category Not Added",
		REMOVING: "Removing Category",
		REMOVED: "Category Removed Successfully",
		NOTREMOVED: "Category Not Removed",
		EDIT: "Editing Category",
		EDITED: "Category Edited Successfully",
		NOTEDITED: "Category Not Edited"
	},
	COUPON:{
		ADD: "Adding Coupon",
		SUCCESS: "Coupon Added Successfully",
		INVALID: "Coupon Not Added",
		REMOVING: "Removing Coupon",
		REMOVED: "Coupon Removed Successfully",
		NOTREMOVED: "Coupon Not Removed",
		EDIT: "Editing Coupon",
		EDITED: "Coupon Edited Successfully",
		NOTEDITED: "Coupon Not Edited",
		SEGEMENTATION:{
			ADD: "Adding Segmentation",
			ADD_SUCCESS: "Segmentation Added Successfully",
			ADD_INVALID: "Segmentation Not Added",
			EDIT: "Editing Segmentation",
			EDIT_SUCCESS: "Segmentation Edited Successfully",
			EDIT_INVALID: "Segmentation Not Edited",
			DELETE: "Deleting Segmentation",
			DELETE_SUCCESS: "Segmentation Deleted Successfully",
			DELETE_INVALID: "Segmentation Not Deleted",

		},
		TIMER:{
			EDIT: "Changing Timer Duration",
			EDIT_SUCCESS: "Timer Duration Changed Successfully",
			EDIT_INVALID: "Timer Duration Change Failed",
		}
	},
	INVOICE:{
		ADD: "Adding Invoice",
		SUCCESS: "Invoice Added Successfully",
		INVALID: "Invoice Not Added",
	}

};