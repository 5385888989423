import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Modal, Form, Button, Toast } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { useAppSelector } from "../../../redux/hooks";
import { INSTRUCTOR } from "../../../utils/instructor";
import {  addDepartment, commonDepartmentList, createInstallment, editDepartment, getDepartment, getSpecificInstallment, updateInstallment } from "../api";
import {DepartmentDetailsSchema} from "../validations";
import styles from "../../instructor/styles/instructor.module.css";
import commonstyles from "../../instructor/styles/common.module.css";
import { UNIVERSITY } from "../../../utils/university";
// import { MESSAGES } from "../../../utils/messages";
import { AddUniversityProp, Department } from "./interface";
import { MESSAGES } from "../../../utils/messages";

const AddOrEditInstalment = ({ showModal, handleClose }: AddUniversityProp) => {
	const selectedDepartment = useAppSelector((state) => state.segmentationReducer);
	 const { id } = useParams();
	 const [allCategory,setAllCategory]=useState<any>([])

	useEffect(() => {
		
		
		if (selectedDepartment.editOrAdd === INSTRUCTOR.REDUX.EDIT&&showModal===true) {
			getSpecificInstallment(Number(selectedDepartment.id)).then((res) => {
				const {
					data: { installment, data ,title,down_payment},
				} = res;

				departmentDetailsFormik.setFieldValue("instalments", data);
				departmentDetailsFormik.setFieldValue("count",installment);
                departmentDetailsFormik.setFieldValue("title",title);
                departmentDetailsFormik.setFieldValue("down_payment",down_payment);




			});
		} else {
			departmentDetailsFormik.resetForm();
		}
	}, [showModal]);

	// const handleAddBatch=()=>{
	// 	const array=departmentDetailsFormik.values.batches
	// 	departmentDetailsFormik.setFieldValue(UNIVERSITY.FORMIK.BATCHES, [...array, { startYear: "", endYear: "" }])
	// }


	const departmentDetailsFormik = useFormik({
		initialValues: {
			count: "",
			instalments:[],
            title:"",
            down_payment:""
			// batches:[ { startYear: "", endYear: "" }]
		},
		enableReinitialize: true,
		onSubmit: (values) => {
            console.log(values)
			// console.log(values)
			// const payload={
			// 	name:values.name,
			// 	department_type:Number(values.department_type),
			// 	// category_id:values.category
			// }
            let sum=0;
            values.instalments.map((value:{percentage:string})=>{
                sum+=Number(value.percentage)
            })
            if(sum+Number(values.down_payment)===100){
			toast.promise(
				selectedDepartment.editOrAdd === INSTRUCTOR.REDUX.ADD
					? createInstallment(Number(values.count),Number(id),values.instalments,values.title,values.down_payment)
					: updateInstallment(Number(values.count),Number(id),values.instalments,selectedDepartment.id,values.title,values.down_payment),
				{
					pending: {
						render() {
							return selectedDepartment.editOrAdd === INSTRUCTOR.REDUX.ADD
								? "Adding"
								: "Editing";
						},
					},
					success: {
						render() {
							departmentDetailsFormik.resetForm();
							handleClose();
							return selectedDepartment.editOrAdd === INSTRUCTOR.REDUX.ADD
								? "Added"
								: "Edited";
						},
					},
					error: {
						render() {
							return selectedDepartment.editOrAdd === INSTRUCTOR.REDUX.ADD
								? "Failed"
								: "Failed"
						},
					},
				}
			);}
            else{
                toast.error("Percentage not valid")
            }
       
		},

	});
	const handleAddDepartment = (e: React.SyntheticEvent<HTMLFormElement>) => {
		e.preventDefault();

		departmentDetailsFormik.submitForm();
	};
	// const removeBatch=(index:any)=>{
	// 	if(index!==0){
	// 		const na=departmentDetailsFormik.values.batches.filter((batch, inn)=>{
	// 			return index!==inn;
	// 		})
	// 		departmentDetailsFormik.setFieldValue(UNIVERSITY.FORMIK.BATCHES, na)
	// 	}

	// }
	return (
		<Modal
			show={showModal}
			onHide={() => {
				handleClose();
			}}
		>
			<div>
				<Modal.Header closeButton>
					<Modal.Title>
						{selectedDepartment.editOrAdd === INSTRUCTOR.REDUX.EDIT
							? "Edit Plan"
							: "Add New Plan"}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form onSubmit={handleAddDepartment}>
						
						<div className={styles.fieldStyle}>
                        <Form.Group className="from-group" controlId="formFirstName">
								<Form.Label>Title</Form.Label>
								<Form.Control
									type="text"
									placeholder="Title"
									value={departmentDetailsFormik.values.title}
									name={"title"}
									onChange={departmentDetailsFormik.handleChange}
								/>
							</Form.Group>
							{departmentDetailsFormik.errors.title &&
              departmentDetailsFormik.touched.title ? (
									<span className={commonstyles.error}>
										{departmentDetailsFormik.errors.title}
									</span>
								) : null}
							<Form.Group className="from-group" controlId="formFirstName">
								<Form.Label>Instalment Count</Form.Label>
								<Form.Control
									type="number"
									placeholder="Instalment Count"
									value={departmentDetailsFormik.values.count}
									name={"count"}
									onChange={departmentDetailsFormik.handleChange}
								/>
							</Form.Group>
							{departmentDetailsFormik.errors.count &&
              departmentDetailsFormik.touched.count ? (
									<span className={commonstyles.error}>
										{departmentDetailsFormik.errors.count}
									</span>
								) : null}
							{/* <Button className={` ${styles.marginTop}`}  onClick={handleAddBatch}>Add Batch</Button> */}
						</div>
                        <div className={styles.fieldStyle}>
                      
							<Form.Group className="from-group" controlId="formFirstName">
								<Form.Label>Down Payment(In Percentage)</Form.Label>
								<Form.Control
									type="number"
									placeholder="Down payment"
									value={departmentDetailsFormik.values.down_payment}
									name={"down_payment"}
									onChange={departmentDetailsFormik.handleChange}
								/>
							</Form.Group>
							{departmentDetailsFormik.errors.down_payment &&
              departmentDetailsFormik.touched.down_payment ? (
									<span className={commonstyles.error}>
										{departmentDetailsFormik.errors.down_payment}
									</span>
								) : null}
							{/* <Button className={` ${styles.marginTop}`}  onClick={handleAddBatch}>Add Batch</Button> */}
						</div>
                        {
              Array.from(Array(Number(departmentDetailsFormik.values.count))).map((value,index)=>{
                return  <><h5>Instalment {index+1}</h5><Form.Group className="from-group" controlId="formFirstName">
                <Form.Label>Amount(In Percentage)</Form.Label>
                <Form.Control
                  type="number"
                  placeholder="Amount"
                  name={`instalments[${index}].percentage`}
                  //@ts-ignore
                  value={departmentDetailsFormik.values.instalments[index]?.percentage}
                  onChange={departmentDetailsFormik.handleChange}
                 
                 
                />
              </Form.Group>
              <Form.Group className="from-group" controlId="formFirstName">
                <Form.Label>Date</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Date"
                  name={`instalments[${index}].date`}
                  //@ts-ignore
                  value={departmentDetailsFormik.values.instalments[index]?.date}
                  onChange={departmentDetailsFormik.handleChange}

   
                />
              </Form.Group>
<hr/>

              </>
              })
             
            }
						
						
						{/* {departmentDetailsFormik.values.batches.map((batch, index)=>{

							return <Form.Group key={index} className="from-group" controlId="formFirstName">
								<Form.Label>Start Year</Form.Label>
								<Button className={`${styles.marginBottom} ${styles.marginLeft} ${styles.marginTop}`} onClick={()=>{removeBatch(index)}}>Remove</Button>

								<Form.Control
									type="number"  onChange={departmentDetailsFormik.handleChange} name={`batches.${index}.startYear`} value={batch.startYear} min="1900" max="2099" step="1" 
								/>
								<Form.Label>End Year</Form.Label>
								<Form.Control
									type="number"  onChange={departmentDetailsFormik.handleChange} name={`batches.${index}.endYear`} value={batch.endYear} min="1900" max="2099" step="1"
								/>
							</Form.Group>
						})
						} */}
							
						<Button className={`${styles.buttonss} mt-3`} type="submit">
							{selectedDepartment.editOrAdd === INSTRUCTOR.REDUX.ADD
								? INSTRUCTOR.ADD
								: INSTRUCTOR.EDIT}
						</Button>
					</Form>
				</Modal.Body>
			</div>
		</Modal>
	);
};

export default AddOrEditInstalment;
