import * as Yup from "yup";
import { YUP } from "../../../../../utils/validations";
import { COUPONS } from "../../../../../utils/coupons";

const SegmentationDetailsSchema = Yup.object().shape({
	min: Yup.number().positive().required(YUP.REQUIRED),
	max: Yup.number().positive().required(YUP.REQUIRED),
	discount:Yup
		.number().min(1, YUP.GREATER0).required(YUP.REQUIRED)
});

export default SegmentationDetailsSchema;